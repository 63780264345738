@use "../utils/mixins";
@use "../utils/variables";

.sidebar,
.sidebars {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background-color: variables.$white;
    border-right: 1px solid transparent;
    margin-top: 0;
    z-index: 1001;
    @include mixins.position(fixed, 80px, null, 0, 0);
    @include mixins.transition(all 0.5s ease);
    border-right: 1px solid variables.$gray-400;
    width: 260px;

    @include mixins.respond-below(custom991) {
        margin-left: -575px;
        @include mixins.transition(all 0.4s ease);
        z-index: 1041;
        background: variables.$white;
    }

    @include mixins.respond-below(custom575) {
        width: 100%;
    }

    .slimScrollDiv {
        background: variables.$white;
        border-right: 1px solid transparent;

        @include mixins.respond-below(custom575) {
            width: 100% !important;
        }
    }

    .sidebar-menu {
        @include mixins.margin-padding(null, 24px);
    }

    .sidebar-menu {
        >ul {
            >li {
                margin-bottom: 24px;

                &.active {
                    a {
                        color: variables.$white;
                    }

                    svg {
                        color: variables.$primary
                    }
                }

                svg {
                    min-width: 16px;
                    width: 16px;
                    height: 16px;
                    margin-right: 10px;
                }

                >a {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    align-items: center;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    @include mixins.margin-padding(null, 10px 15px);
                    position: relative;
                    color: variables.$secondary;

                    svg {
                        color: #637381;
                    }

                    img {
                        width: 18px;
                        color: #637381;
                    }

                    &:hover {
                        background: rgba(1, 31, 93, 0.08);
                        color: variables.$primary;
                        border-radius: 5px;

                        img {
                            filter: invert(72%) sepia(76%) saturate(1430%) hue-rotate(327deg) brightness(103%) contrast(101%);
                        }

                        span {
                            color: variables.$primary;
                        }

                        svg {
                            color: variables.$primary;
                        }
                    }

                    &.active {
                        background: rgba(1, 31, 93, 0.08);
                        color: variables.$primary;
                        border-radius: 5px;

                        svg {
                            color: variables.$white;
                        }

                        img {
                            filter: invert(72%) sepia(76%) saturate(1430%) hue-rotate(327deg) brightness(103%) contrast(101%);
                        }

                        span {
                            color: variables.$primary;
                        }

                        .menu-arrow {
                            background: variables.$primary-100;

                            &::before {
                                border-color: variables.$primary;
                            }
                        }
                    }

                    span {
                        font-size: variables.$font-size-15;
                        font-weight: variables.$font-weight-medium;
                        color: variables.$gray-600;
                    }
                }

                &.active {
                    a {
                        background: rgba(1, 31, 93, 0.08);
                        border-radius: 5px;

                        img {
                            filter: invert(72%) sepia(76%) saturate(1430%) hue-rotate(327deg) brightness(103%) contrast(101%);
                        }

                        span {
                            color: variables.$primary;
                        }
                    }
                }

                &.submenu {
                    ul {
                        border-bottom: 1px solid rgba(222, 226, 230, 0.5);
                        @include mixins.margin-padding(null, 10px 0);
                        display: none;

                        li {
                            a {
                                font-weight: variables.$font-weight-medium;
                                font-size: variables.$font-size-base;
                                color: variables.$secondary;
                                position: relative;
                                display: block;
                                @include mixins.margin-padding(null, 8px 8px 8px 40px);

                                &.active {
                                    color: variables.$secondary;

                                    &:after {
                                        background: variables.$primary;
                                        border: 2px solid variables.$primary-100;
                                    }
                                }

                                &::after {
                                    content: "";
                                    background: rgba(50, 71, 92, 0.38);
                                    width: 8px;
                                    height: 8px;
                                    border: 2px solid variables.$white;
                                    @include mixins.rounded(50px);
                                    @include mixins.position(absolute, 16px, null, null, 16px);
                                }

                                &:hover {
                                    color: variables.$primary;

                                    &:after {
                                        background: variables.$primary;
                                        border: 2px solid variables.$primary-100;
                                    }
                                }
                            }
                        }
                    }
                }

                &.submenu-open {
                    ul {
                        border-bottom: 1px solid variables.$gray-300;
                        @include mixins.margin-padding(null, 0 0 14px 0);
                        display: block;

                        li {
                            a {
                                display: -webkit-box;
                                display: -ms-flexbox;
                                display: flex;
                                align-items: center;
                                -webkit-box-align: center;
                                -ms-flex-align: center;
                                font-weight: variables.$font-weight-medium;
                                font-size: variables.$font-size-15;
                                color: #67748E;
                                position: relative;
                                width: 100%;
                                @include mixins.margin-padding(null, 10px 10px 10px 18px);

                                span {
                                    line-height: 1;
                                    font-size: variables.$font-size-base;
                                    color: variables.$text-color;
                                }

                                &.active {
                                    color: variables.$primary;

                                    a {
                                        span {
                                            color: variables.$primary;
                                        }
                                    }

                                    &:after {
                                        background: variables.$primary;
                                        border: 2px solid variables.$primary-100;
                                    }
                                }

                                &:hover {
                                    color: variables.$primary;

                                    &:after {
                                        background: variables.$primary;
                                        border: 2px solid variables.$primary-100;
                                    }

                                    span {
                                        color: variables.$primary;
                                    }
                                }
                            }
                        }

                        >li {
                            &.active a {
                                background: rgba(1, 31, 93, 0.08);
                                border-radius: 5px;
                                color: variables.$primary;

                                span {
                                    color: variables.$primary;
                                }
                            }
                        }
                    }

                    .submenu-hdr {
                        font-weight: variables.$font-weight-semibold;
                        font-size: variables.$font-size-base;
                        color: #1B2950;
                        margin: 0 0 14px 0;
                    }

                    &:first-child {
                        h6 {
                            margin-top: 0;
                        }
                    }

                    .submenu {
                        ul {

                            border: 0;
                            padding-top: 0;
                            padding-bottom: 0;
                        }
                    }
                }
            }

            li {
                &.active {
                    a {
                        color: variables.$primary;
                    }

                    svg {
                        color: variables.$primary
                    }
                }

                .submenu> {
                    a {
                        &.active {
                            background: rgba(1, 31, 93, 0.08);
                            color: variables.$primary;
                            border-radius: 5px;

                            span {
                                color: variables.$primary;
                            }

                            .menu-arrow {
                                background: variables.$primary-100;

                                &::before {
                                    border-color: variables.$primary;
                                }
                            }
                        }
                    }
                }

                .submenu .submenu-two> {
                    a {
                        &.active {
                            background: transparent;
                        }
                    }
                }

            }

        }

        .submenu-open {
            &:last-child {
                margin-bottom: 0;

                ul {
                    border-bottom: none;
                    padding-bottom: 0;
                }
            }

            .submenu {
                &.submenu-two {
                    ul {
                        li {
                            a {
                                margin-left: 45px;
                            }
                        }
                    }

                    &.submenu-three {
                        ul {
                            li {
                                a {
                                    margin-left: 65px;
                                }
                            }
                        }
                    }
                }

                ul {
                    li {
                        a {
                            margin-left: 25px;
                            padding-bottom: 4px;
                            font-size: variables.$font-size-base;
                            color: variables.$sub-title;

                            &::after {
                                content: "";
                                background: rgba(50, 71, 92, 0.38);
                                width: 8px;
                                height: 8px;
                                border: 2px solid variables.$white;
                                @include mixins.rounded(50px);
                                @include mixins.position(absolute, 16px, null, null, 0);
                            }

                            &:hover {
                                color: variables.$primary;

                                &:after {
                                    background: variables.$primary;
                                    border: 2px solid variables.$primary-100;
                                }
                            }
                        }
                    }
                }
            }
        }

        .menu-arrow {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-justify-content: center;
            justify-content: center;
            -ms-flex-pack: center;
            text-rendering: auto;
            line-height: 40px;
            font-size: variables.$font-size-18;
            line-height: 16px;
            background: rgba(27, 41, 80, 0.04);
            width: 16px;
            height: 16px;
            border-radius: 50%;
            @include mixins.position(absolute, 12px, 15px, null, null);
            @include mixins.transform(translate(0, 0));
            @include mixins.transition(all 0.2s ease);

            &::before {
                height: 5px;
                width: 5px;
                border-top: 0;
                content: "";
                border-left: 0;
                border-right: 1px solid variables.$text-color;
                border-bottom: 1px solid variables.$text-color;
                transform: rotate(-45deg);
                position: absolute;
                left: 5px;
                top: 5px;
            }

            &.inside-submenu {
                right: 40px;

                &.inside-submenu-two {
                    right: 60px;
                }
            }
        }

        &.subdrop {
            .menu-arrow {
                @include mixins.transform(rotate(90deg));
                background: variables.$primary-100;
            }
        }
    }

    .nav-link {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-justify-content: center;
        justify-content: center;
        -ms-flex-pack: center;
        @include mixins.margin-padding(null, 10px);
        color: variables.$secondary;
        position: relative;

        &.active {
            color: variables.$primary;
            background: transparent;
            position: relative;

            &:after {
                content: "";
                border-width: 10px;
                border-color: transparent variables.$primary transparent transparent;
                border-style: solid;
                @include mixins.position(absolute, 36px, 0, null, null);
            }
        }

    }

    .sidebar-left {
        width: 100px;
        @include mixins.box-shadow(null, 5px, 0px, 15px, null, rgba(0, 0, 0, .05));
    }

    .sidebar-right {
        width: 250px;
        height: 100%;
        @include mixins.transition(all 0.2s ease);
        display: none;

        @include mixins.respond-below(custom991) {
            width: calc(100% - 70px);
        }

        .slimScrollDiv {
            width: 250px !important;
            overflow: visible !important;

            @include mixins.respond-below(custom991) {
                width: 100% !important;
            }
        }

        .tab-content {
            @include mixins.margin-padding(null, 20px 20px 40px);

            @include mixins.respond-below(custom991) {
                @include mixins.margin-padding(null, 10px);
            }
        }

        p {
            font-size: variables.$font-size-10;
            color: variables.$gray-500;
            text-transform: uppercase;
            font-weight: variables.$font-weight-bold;
            margin-bottom: 10px;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                &.active {
                    a {
                        background: linear-gradient(46.62deg, variables.$primary 0%, variables.$violet 93.64%);
                        color: variables.$white;
                    }
                }

                a {
                    font-weight: variables.$font-weight-normal;
                    font-size: variables.$font-size-base;
                    color: variables.$secondary;

                    &.active {
                        color: variables.$primary;
                    }
                }
            }
        }

        .menu-arrow {
            -webkit-transition: -webkit-transform 0.15s;
            -o-transition: -o-transform 0.15s;
            transition: transform .15s;
            display: inline-block;
            font-family: 'Font Awesome 5 Free';
            text-rendering: auto;
            line-height: 40px;
            font-size: variables.$font-size-18;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            line-height: 18px;
            top: 0px;
            position: relative;
            font-weight: variables.$font-weight-bold;
            @include mixins.transform(translate(0, 0));

            &:before {
                content: "\f105";

            }
        }
    }

    .sub-menu a {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        -ms-flex-pack: space-between;
    }

    li {
        a.subdrop {
            .menu-arrow {
                @include mixins.transform(rotate(90deg));
            }
        }
    }

    .nav-items {
        .nav-link {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-direction: column;
            flex-direction: column;
            @include mixins.rounded(0);
            border-bottom: 1px solid variables.$gray-400;
            @include mixins.margin-padding(null, 20px 0);
            @include mixins.transition(all 0.4s ease);

            &:hover {
                box-shadow: 0 0 0 150px variables.$violet inset;
                color: variables.$white;
            }

            span {
                margin-top: 15px;
                font-weight: variables.$font-weight-medium;
            }
        }

    }

    .sidemenu {
        a {
            width: 100%;
            font-weight: variables.$font-weight-medium;
            display: block;
            @include mixins.rounded(5px);
            @include mixins.margin-padding(0 0 10px, 10px);

            &:hover {
                background: linear-gradient(46.62deg, variables.$primary 0%, variables.$violet 93.64%);
                color: variables.$white;

                svg {
                    color: variables.$white;
                }
            }

            svg {
                stroke-width: 1px;
            }

        }
    }
}

.header-left {
    #toggle_btn {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-justify-content: center;
        justify-content: center;
        -ms-flex-pack: center;
        position: absolute;
        opacity: 1;
        background: variables.$primary;
        right: -13px;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        color: variables.$white;
        cursor: pointer;

        svg {
            transform: rotate(180deg);
        }

        &:hover {
            background: variables.$primary-hover;
        }
    }

    &.active svg {
        transform: rotate(0) !important;
    }

}

@include mixins.respond-above(custom991) {
    .mini-sidebar {
        .page-wrapper {
            margin-left: 80px;
        }

        .header-left {
            #toggle_btn {
                opacity: 0;

                &:after {
                    border: 0;
                }
            }
        }

        &.expand-menu {
            .header-left {
                #toggle_btn {
                    opacity: 1;
                }
            }
        }

        .sidebar-right {
            display: none;
        }

        .sidebar {
            .sidebar-menu {
                ul {
                    li {
                        a {
                            span {
                                display: none;
                            }
                        }

                        .submenu-hdr {
                            display: none;
                        }

                        svg {
                            margin-right: 0;
                        }
                    }
                }
            }
        }

        .sidebar {
            width: 80px;
            overflow: hidden;
        }
    }

    .expand-menu {
        .sidebar-right {
            display: block;
            @include mixins.transition(all 0.2s ease);
        }

        .sidebar {
            width: 260px;

            .sidebar-menu {
                ul {
                    >li {
                        >a {
                            @include mixins.margin-padding(null, 10px 15px);

                            span {
                                display: inline-block;
                                line-height: 0;
                            }
                        }

                        svg {
                            margin-right: 10px;
                        }
                    }
                }

                .submenu-hdr {
                    display: block;
                }
            }
        }

        .header-left {
            width: 260px;
        }
    }

    #toggle_btn {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-justify-content: center;
        justify-content: center;
        -ms-flex-pack: center;
        float: left;
        margin-left: 15px;
    }
}

body.mini-sidebar {
    .sidebar {
        .sidebar-menu>ul>li.submenu-open ul li a {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-justify-content: center;
            justify-content: center;
            -ms-flex-pack: center;
            font-weight: variables.$font-weight-medium;
            font-size: variables.$font-size-15;
            color: #67748E;
            position: relative;
            width: 100%;
            padding: 10px;
        }
    }

    &.expand-menu {
        .sidebar {
            .sidebar-menu>ul>li.submenu-open ul li a {
                -webkit-justify-content: left;
                justify-content: left;
                -ms-flex-pack: left;
                padding-left: 18px;
            }
        }
    }
}

.sidebarrightmenu {
    .sidebar-right {
        display: block;
    }
}

.slide-nav {
    .sidebar {
        margin-left: 0;
    }
}

.sidebar-overlay {
    display: none;
    height: 100%;
    width: 100%;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.6);
    @include mixins.position(fixed, 60px, null, null, 0);

    &.opened {
        display: block;
    }
}

.nosidebar {
    .sidebar {
        left: -350px;
    }

    .page-wrapper {
        margin: 0;
    }

    #toggle_btn {
        svg {
            @include mixins.transform(rotate(180deg));
        }
    }
}

.mini-sidebar {
    .active.subdrop~ul {
        display: none !important;
    }
}

.mini-sidebar.expand-menu {
    .active.subdrop~ul {
        display: block !important;
    }
}

.active.subdrop~ul {
    display: block !important;
}


.sidebar {
    &.horizontal-sidebar {
        &.sidebar-one {
            .submenu {
                ul {
                    background: variables.$body-bg;
                }
            }
        }
    }
}