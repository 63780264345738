@use "../utils/variables";

.card {
  margin-bottom: 1.5rem;
  background-color: variables.$white;
  transition: all .5s ease-in-out;
  position: relative;
  border-radius: variables.$border-radius;
  border: 1px solid variables.$border-color;
  box-shadow: none;

  .card-body {
    padding: 1.25rem;
  }

  .card-title {
    font-size: variables.$font-size-18;
    font-weight: variables.$font-weight-bold;
    text-transform: capitalize;
  }

  .card-header {
    border-color: variables.$border-color;
    position: relative;
    background: transparent;
    padding: 1rem 1.25rem 1rem;

    .card-title {
      margin-bottom: 0rem;
    }

    .subtitle {
      padding-top: 0.3125rem;
      font-size: 0.875rem;
      line-height: 1.5;
    }
  }

  .card-footer {
    border-color: variables.$border-color;
    background: transparent;
    padding: 1rem 1.25rem 1rem;
  }
}

.card-group {
  .card {
    margin-bottom: 1.5rem;
  }
}

.card {
  &.custom-card {
    border-radius: variables.$border-radius;
    border: 0;
    background-color: variables.$white;
    position: relative;
    margin-block-end: 1.5rem;
    width: 100%;

    .card-header {
      padding: 1rem 1.25rem;
      background-color: transparent !important;
      border-block-end: 1px solid variables.$default-border;
      display: flex;
      align-items: center;
      position: relative;
      flex-wrap: wrap;
      gap: 0.25rem;

      &:first-child {
        border-radius: 0.625rem 0.625rem 0 0;
      }

      .card-title {
        position: relative;
        margin-block-end: 0;
        font-size: 0.9375rem;
        font-weight: variables.$font-weight-bold;
        text-transform: capitalize;

        .subtitle {
          margin-block-end: 0;
          text-transform: initial;
        }
      }
    }

    .card-footer {
      background-color: transparent !important;
      border-block-start: 1px solid variables.$default-border;
      padding: 1rem 1.5rem;
      font-size: variables.$font-size-base;
    }

    .card-body {
      padding: 1.5rem;
      color: variables.$text-color;
    }

    .card-link {
      font-size: 0.75rem;
    }
  }

  .card-text {
    font-size: 0.813rem;
  }

  .footer-card-icon {
    width: 60px;
    height: 60px;
  }

  &.overlay-card {
    position: relative;
    overflow: hidden;
    color: rgba(255, 255, 255, 0.9);

    .card-header {
      border-block-end: 1px solid rgba(255, 255, 255, 0.1);
    }

    .card-footer {
      border-block-start: 1px solid rgba(255, 255, 255, 0.1);
    }

    .over-content-bottom {
      top: auto;
    }

    &:before {
      content: "";
      inset-inline-start: 0;
      inset-inline-end: 0;
      inset-block-start: 0;
      inset-block-end: 0;
      background-color: rgba(0, 0, 0, 0.2);
      position: absolute;
    }
  }

  &.card-bg-primary {
    background-color: variables.$primary;
    color: variables.$white;

    .card-header {
      background-color: rgba(255, 255, 255, 0.1) !important;
      color: variables.$white;
      border-block-end: 1px solid rgba(255, 255, 255, 0.2);

      &:before {
        background-color: variables.$white;
      }
    }

    .card-body {
      color: variables.$white;
    }

    .card-footer {
      background-color: rgba(255, 255, 255, 0.1) !important;
      color: variables.$white;
      border-block-start: 1px solid rgba(255, 255, 255, 0.2);
    }
  }

  &.card-bg-secondary {
    background-color: variables.$secondary;
    color: variables.$white;

    .card-header {
      background-color: rgba(255, 255, 255, 0.1) !important;
      color: variables.$white;
      border-block-end: 1px solid rgba(255, 255, 255, 0.2);

      &:before {
        background-color: variables.$white;
      }
    }

    .card-body {
      color: variables.$white;
    }

    .card-footer {
      background-color: rgba(255, 255, 255, 0.1) !important;
      color: variables.$white;
      border-block-start: 1px solid rgba(255, 255, 255, 0.2);
    }
  }

  &.card-bg-warning {
    background-color: variables.$warning;
    color: variables.$white;

    .card-header {
      background-color: rgba(255, 255, 255, 0.1) !important;
      color: variables.$white;
      border-block-end: 1px solid rgba(255, 255, 255, 0.2);

      &:before {
        background-color: variables.$white;
      }
    }

    .card-body {
      color: variables.$white;
    }

    .card-footer {
      background-color: rgba(255, 255, 255, 0.1) !important;
      color: variables.$white;
      border-block-start: 1px solid rgba(255, 255, 255, 0.2);
    }
  }

  &.card-bg-info {
    background-color: variables.$info;
    color: variables.$white;

    .card-header {
      background-color: rgba(255, 255, 255, 0.1) !important;
      color: variables.$white;
      border-block-end: 1px solid rgba(255, 255, 255, 0.2);

      &:before {
        background-color: variables.$white;
      }
    }

    .card-body {
      color: variables.$white;
    }

    .card-footer {
      background-color: rgba(255, 255, 255, 0.1) !important;
      color: variables.$white;
      border-block-start: 1px solid rgba(255, 255, 255, 0.2);
    }
  }

  &.card-bg-success {
    background-color: variables.$success;
    color: variables.$white;

    .card-header {
      background-color: rgba(255, 255, 255, 0.1) !important;
      color: variables.$white;
      border-block-end: 1px solid rgba(255, 255, 255, 0.2);

      &:before {
        background-color: variables.$white;
      }
    }

    .card-body {
      color: variables.$white;
    }

    .card-footer {
      background-color: rgba(255, 255, 255, 0.1) !important;
      color: variables.$white;
      border-block-start: 1px solid rgba(255, 255, 255, 0.2);
    }
  }

  &.card-bg-danger {
    background-color: variables.$danger;
    color: variables.$white;

    .card-header {
      background-color: rgba(255, 255, 255, 0.1) !important;
      color: variables.$white;
      border-block-end: 1px solid rgba(255, 255, 255, 0.2);

      &:before {
        background-color: variables.$white;
      }
    }

    .card-body {
      color: variables.$white;
    }

    .card-footer {
      background-color: rgba(255, 255, 255, 0.1) !important;
      color: variables.$white;
      border-block-start: 1px solid rgba(255, 255, 255, 0.2);
    }
  }

  &.card-bg-light {
    background-color: variables.$light;
    color: variables.$text-color;

    .card-header {
      background-color: rgba(0, 0, 0, 0.05) !important;
      color: variables.$text-color;
      border-block-end: variables.$default-border;
    }

    .card-body {
      color: variables.$text-color;
    }

    .card-footer {
      background-color: rgba(0, 0, 0, 0.05) !important;
      color: variables.$text-color;
      border-block-start: variables.$default-border;
    }
  }

  &.card-bg-dark {
    background-color: variables.$dark;
    color: variables.$white;

    .card-header {
      background-color: rgba(255, 255, 255, 0.1) !important;
      color: variables.$white;
      border-block-end: 1px solid rgba(255, 255, 255, 0.2);

      &:before {
        background-color: variables.$white;
      }
    }

    .card-body {
      color: variables.$white;
    }

    .card-footer {
      background-color: rgba(255, 255, 255, 0.1) !important;
      color: variables.$white;
      border-block-start: 1px solid rgba(255, 255, 255, 0.2);
    }
  }

  .card-anchor {
    position: absolute;
    top: 0;
    inset-inline-end: 0;
    bottom: 0;
    inset-inline-start: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
  }

  .collapse-close {
    display: none;
  }

  &.card .collapsed {
    .collapse-close {
      display: block;
    }

    .collapse-open {
      display: none;
    }
  }

  &.card-fullscreen {
    position: fixed;
    top: 0;
    inset-inline-start: 0;
    inset-inline-start: 0;
    bottom: 0;
    z-index: 9999;
    margin: 0;
  }
}

.cals-icon {
  font-size: variables.$font-size-50;
  color: variables.$gray-500;
}

.callout {
  padding: 1.25rem;
  margin-block-start: 1.25rem;
  margin-block-end: 1.25rem;
  background-color: variables.$light;
  border-inline-start: 0.25rem solid variables.$default-border;
}

.callout-info {
  background-color: rgba(variables.$info, 0.075);
  border-color: rgba(variables.$info, 0.5);
}

.callout-warning {
  background-color: rgba(variables.$warning, 0.075);
  border-color: rgba(variables.$warning, 0.5);
}

.callout-danger {
  background-color: rgba(variables.$danger, 0.075);
  border-color: rgba(variables.$danger, 0.5);
}

.btn-facebook {
  background-color: variables.$facebook;
  color: variables.$white;
  border: 1px solid variables.$facebook;

  &:hover,
  &:focus,
  &:active {
    background-color: variables.$facebook !important;
    color: variables.$white !important;
    border: 1px solid variables.$facebook !important;
  }
}

.btn-instagram {
  background: #f09433;
  background: -moz-linear-gradient(45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%);
  background: -webkit-linear-gradient(45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%);
  background: linear-gradient(45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
  color: variables.$white;
  border: 1px solid transparent;

  &:hover,
  &:focus,
  &:active {
    color: variables.$white;
    border: 1px solid transparent;
  }
}

.btn-twitter {
  background-color: variables.$twitter;
  color: variables.$white;
  border: 1px solid variables.$twitter;

  &:hover,
  &:focus,
  &:active {
    background-color: variables.$twitter !important;
    color: variables.$white !important;
    border: 1px solid variables.$twitter !important;
  }
}

.btn-icon {
  width: 2.313rem;
  height: 2.313rem;
  font-size: .95rem;
  flex-shrink: 0;
}

.btn-wave {
  display: inline-block !important;
}

@media (max-width: 575px) {
  .overlay-card {
    .card-text {
      font-size: .613rem !important;
    }
  }
}

@media (max-width: 400px) {
  .overlay-card {
    .card-text {
      font-size: .413rem !important;
    }
  }
}