@use "../utils/mixins";
@use "../utils/variables";

.page-wrapper-three {
    margin-left: 0;
    padding-top: 0;

    .content {
        background: variables.$body-bg;
        border-radius: 20px;
        margin-top: -82px;
    }
}

.header-three {
    position: relative;

    .header-left {
        border-right: 0 !important;
        @include mixins.margin-padding(null, 25px);
    }
}

.logo-white {
    display: none !important;
}

.sidebar.side-three.horizontal-sidebar {
    position: relative;
    top: 0;
    width: 100%;
    height: 150px;
    background: #1B2950;
    border-top: 1px solid variables.$gray-400;
    box-shadow: 0px 4px 14px rgba(198, 198, 198, 0.25);
    z-index: unset;

    .sidebar-menu {
        @include mixins.margin-padding(null, 0 15px);

        &>ul {
            &>li {
                margin-bottom: 0;
                margin-right: 20px;

                &>a {
                    @include mixins.margin-padding(null, 22px 15px);

                    img,
                    svg {
                        filter: brightness(0) invert(1);
                        margin-right: 0;
                    }

                    &:hover {
                        background: transparent;

                        span {
                            color: variables.$primary !important;
                        }

                        img,
                        svg {
                            filter: brightness(0) saturate(100%) invert(75%) sepia(10%) saturate(5676%) hue-rotate(326deg) brightness(105%) contrast(112%);
                        }
                    }

                    img {
                        top: 0 !important;
                        width: auto;
                    }

                    &::before {
                        content: "";
                        @include mixins.position(absolute, 50%, -10px, null, null);
                        width: 1px;
                        height: 15px;
                        background: #6B7A99;
                        transform: translateY(-50%);
                    }
                }

                &>a {
                    span {
                        color: variables.$white !important;
                    }
                }

                a {
                    font-weight: variables.$font-weight-medium;
                    font-size: variables.$font-size-15;
                    color: variables.$white;

                    img {
                        position: relative;
                        top: 3px;
                        width: 18px;
                        margin-right: 7px;
                        filter: brightness(0) invert(1);
                    }

                    svg {
                        width: 18px;
                        margin-right: 7px;
                    }

                    &:hover {

                        img,
                        svg {
                            filter: brightness(0) saturate(100%) invert(75%) sepia(10%) saturate(5676%) hue-rotate(326deg) brightness(105%) contrast(112%);
                        }
                    }
                }

                .menu-arrow {
                    position: initial;
                    transform: rotate(90deg);
                    margin-left: 15px;
                }

                ul {
                    position: absolute;
                    width: 250px;
                    height: auto;
                    z-index: 1;
                    background: #1B2950;

                    li {
                        &.submenu {
                            a {
                                padding: 8px 15px !important;
                                color: variables.$white;

                                .menu-arrow {
                                    float: right;
                                    margin-right: 15px;
                                }

                                i {
                                    margin-right: 7px;
                                }

                                img {
                                    position: relative;
                                    top: 3px;
                                    width: 18px;
                                    margin-right: 7px;
                                }

                                svg {
                                    width: 18px;
                                    margin-right: 7px;
                                }

                                &:hover {
                                    color: variables.$primary;

                                    img {
                                        filter: brightness(0) saturate(100%) invert(75%) sepia(10%) saturate(5676%) hue-rotate(326deg) brightness(105%) contrast(112%);
                                    }
                                }
                            }

                            & ul {
                                box-shadow: none;
                            }

                            ul {
                                position: relative;
                                @include mixins.margin-padding(null, 0);

                                li {
                                    a {
                                        padding: 8px 10px 8px 40px !important;
                                        color: variables.$white;
                                    }
                                }
                            }
                        }

                        a {
                            padding: 8px 15px !important;

                            &::after {
                                content: none !important;
                            }
                        }
                    }
                }

                @include mixins.respond-below(custom1199) {
                    margin-right: 0;
                }

                &:last-child {
                    &>a {
                        &::before {
                            content: none;
                        }
                    }
                }
            }
        }
    }
}

@include mixins.respond-above(custom992) {
    .hide-sidebar {
        display: none;
    }
}

@include mixins.respond-below(custom991) {
    .header-three {
        position: fixed;
    }

    .horizontal-sidebar {
        display: none;
    }

    .page-wrapper-three {
        padding-top: 60px;

        .content {
            margin-top: 0;
        }
    }
}