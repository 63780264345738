@use "../utils/variables";

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
	background-color: variables.$warning;
	text-shadow: unset;
}

.bootstrap-datetimepicker-widget table td.today:before {
	border-bottom-color: variables.$warning;
}

.bootstrap-datetimepicker-widget table thead tr:first-child th:hover {
	background: transparent;
}

.bor-b1 {
	border-bottom: 1px solid variables.$gray-300;
}