@use "../utils/mixins";
@use "../utils/variables";

.account-page .customizer-links,
.error-page .customizer-links {
    display: none;
}

.customizer-links {
    @include mixins.position(fixed, 50%, 0, null, null);
    @include mixins.transform(translateY(-50%));
    background: variables.$orange;
    border-radius: 10px 0px 0px 10px;
    z-index: 3;
    width: 41px;
    height: 38px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    @include mixins.transition(all 0.3s ease);

    &:hover {
        background: variables.$title-color;
    }

    ul {
        li {
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);

            &:last-child {
                border-bottom: 0;
            }

            a {
                padding: 5px;
                text-align: center;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                align-items: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                -webkit-justify-content: center;
                justify-content: center;
                -ms-flex-pack: center;
                border-radius: 5px;

                svg {
                    color: variables.$white;
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }
}

.sidebar-settings {
    width: 450px;
    background-color: variables.$white;
    @include mixins.position(fixed, 0, -450px, null, null);
    height: 100vh;
    background-color: rgb(0, 0, 0, 0.2);
    z-index: 9999;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    @include mixins.transition(ease all 0.8s);

    &.show-settings {
        right: 0;
        @include mixins.transition(ease all 0.8s);
        -ms-overflow-style: none;
        scrollbar-width: none;
        overflow-y: scroll;
        width: 100%;
    }
}

.sidebar-content {
    max-width: 450px;
    width: 100%;
    @include mixins.position(absolute, 0, 0, null, null);
    background: variables.$white;
    height: 100vh;
    overflow-x: hidden;

    .sidebar-header {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        -ms-flex-pack: space-between;
        @include mixins.margin-padding(null, 20px);
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        z-index: 100;
        background-color: variables.$primary;

        h5 {
            font-size: variables.$font-size-20;
            color: variables.$white;
            font-weight: variables.$font-weight-bold;
            margin-bottom: 0;
            text-transform: uppercase;

            @include mixins.respond-below(custom991) {
                font-size: variables.$font-size-18;
            }
        }

        p {
            color: variables.$white;
        }

        a {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-justify-content: center;
            justify-content: center;
            -ms-flex-pack: center;
            border: 1px solid variables.$white;
            width: 26px;
            height: 26px;
            border-radius: 5px;
            color: variables.$white;

            svg {
                width: 15px;
                height: 15px;
                color: variables.$white
            }
        }
    }

    .theme-head {
        margin-bottom: 20px;

        h6 {
            color: variables.$text-color;
            font-weight: variables.$font-weight-bold;
            font-size: variables.$font-size-18;
        }
    }

    .sidebar-body {
        @include mixins.margin-padding(null, 20px);

        .theme-title {
            font-size: variables.$font-size-18;
            font-weight: variables.$font-weight-bold;
            color: variables.$gray-600;
            margin-bottom: 10px;
        }
    }
}

.switch-wrapper {
    border-bottom: 1px solid variables.$gray-400;
    @include mixins.margin-padding(0 0 20px, 0 0 20px);

    #dark-mode-toggle {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;

        .light-mode {
            border-radius: 5px 0 0 5px;
        }

        .dark-mode {
            border-radius: 0 5px 5px 0;
        }

        .light-mode,
        .dark-mode {
            background: variables.$gray-400;
            font-size: variables.$font-size-16;
            color: variables.$black;
            ;
            display: -webkit-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;
            align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            @include mixins.margin-padding(null, 11px 18px);
            cursor: pointer;

            img {
                filter: brightness(0) saturate(100%)
            }

            &.active {
                background: variables.$orange;
                color: variables.$white;

                img {
                    filter: brightness(0) invert(1);
                }
            }
        }
    }
}

.layout-wrap {
    margin-bottom: 20px;

    .status-toggle {
        .checktoggle {
            background: variables.$black;
            ;
            width: 25px;
            height: 15px;

            :after {
                width: 12px;
                height: 12px;
                right: 4px;
            }
        }
    }

    .layout-img {
        img {
            max-width: 120px;
        }
    }

    .status-text {
        font-size: variables.$font-size-16;
        color: variables.$black;
        ;
    }
}

.layout-link {
    position: relative;
    z-index: 9;
    width: 100%;
    height: 100%;
    display: inline-block;
}

.theme-mode {
    .theme-body-main {
        padding: 20px 20px 0;
    }

    @include mixins.margin-padding(0 0 20px, 0);

    .layout-wrap {
        .check {
            +label {
                text-align: center;
                cursor: pointer;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                align-items: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                flex-direction: column;

                img {
                    border-radius: 8px;
                    border: 1px solid variables.$gray-100;
                }

                .theme-name {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    align-items: center;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    -webkit-justify-content: center;
                    justify-content: center;
                    -ms-flex-pack: center;
                    color: variables.$text-color;
                    font-weight: variables.$font-weight-bold;
                    font-size: variables.$font-size-base;
                    margin-top: 10px;
                    position: relative;

                    &::before {
                        content: "";
                        width: 14px;
                        height: 14px;
                        border: 1px solid #919EAB4D;
                        display: block;
                        margin-right: 5px;
                        border-radius: 50%;
                    }
                }
            }

            &:checked {
                +label {
                    img {
                        border-color: variables.$primary;
                    }

                    .theme-name {
                        &::after {
                            content: "";
                            width: 6px;
                            height: 6px;
                            border-radius: 50%;
                            background-color: variables.$primary;
                            position: absolute;
                            left: 4px;
                        }
                    }
                }
            }
        }
    }
}

#layout_mode {
    @include mixins.respond-below(custom991) {
        display: none;
    }
}

.sidebar-footer {
    position: sticky;
    padding: 20px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: variables.$white;
    box-shadow: 0px 4px 60px 0px rgba(190, 190, 190, 0.27);
    z-index: 110;
}