@use "../utils/mixins";
@use "../utils/variables";

.main-wrappers {
    background-color: variables.$body-bg;

    &.pos-page {
        .sidebar {
            display: none;

            @include mixins.respond-below(custom991) {
                display: block;
            }
        }

        #toggle_btn {
            display: none;
        }
    }
}

.menu-opened {
    .pos-page {
        &.slide-nav {
            .sidebar {
                margin-left: 0;
            }
        }

        .sidebar {
            margin-left: -100%;
        }
    }
}

.product-view {
    width: 100%;
    border: 1px solid variables.$gray-300;
    margin-bottom: 20px;

    &:hover {
        border: 1px solid variables.$primary;
    }

    .product-viewimg {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-justify-content: center;
        justify-content: center;
        -ms-flex-pack: center;
        max-height: 120px;
        overflow: hidden;

        h6 {
            background: variables.$success;
            color: variables.$white;
            font-size: variables.$font-size-8;
            font-weight: variables.$font-weight-semibold;
            @include mixins.margin-padding(null, 3px);
            @include mixins.position(absolute, null, null, 0, 0);
        }
    }

    .product-viewcontent {
        @include mixins.margin-padding(null, 13px);

        h3 {
            font-size: variables.$font-size-base;
            color: variables.$black;
            font-weight: variables.$font-weight-medium;
        }

        h4 {
            font-size: variables.$font-size-12;
            color: variables.$gray-600;
            font-weight: variables.$font-weight-medium;
            margin-bottom: 5px;
        }

        h5 {
            font-size: variables.$font-size-base;
            color: variables.$gray-600;
            font-weight: variables.$font-weight-medium;
        }
    }
}

.main-set {
    @include mixins.margin-padding(null, 0 30px);

    @include mixins.respond-below(custom991) {
        @include mixins.margin-padding(null, 0 10px);
    }
}

.text-underline {
    position: relative;

    &::after {
        content: "";
        background: variables.$gray-600;
        width: 10px;
        height: 2px;
        @include mixins.position(absolute, null, null, 12px, 10px);
    }
}

.select-split {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    margin-bottom: 20px;

    .select-group {
        width: calc(100% - 55px)
    }

    .btn-filters {
        width: 40px;
        height: 40px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-justify-content: center;
        justify-content: center;
        -ms-flex-pack: center;
        @include mixins.margin-padding(0 0 0 15px, 0);
    }

    .select2-container--default {
        .select2-selection--single {
            background-color: variables.$white;
            border: 1px solid variables.$gray-300;
            height: 40px;
            @include mixins.rounded(4px);

            .select2-selection__rendered {
                line-height: 40px;
            }

            .select2-selection__arrow {
                height: 40px;
            }
        }
    }
}

.table {
    tr {
        &:hover {
            .productimg {
                .productcontet {
                    h4 {
                        img {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}

.table-product {
    tr {
        th {
            font-weight: variables.$font-weight-semibold;
            color: variables.$gray-600;
            font-size: variables.$font-size-base;
        }

        td {
            font-weight: variables.$font-weight-semibold;
            color: variables.$gray-600;
            font-size: variables.$font-size-base;
        }
    }
}

.btn-totallabel {
    color: variables.$white;
    background: variables.$purple;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    @include mixins.rounded(5px);
    @include mixins.margin-padding(0 0 15px, 12px);

    h5 {
        font-size: variables.$font-size-base;
        margin: 0;
        font-weight: variables.$font-weight-semibold;
    }

    h6 {
        margin: 0;
        font-size: variables.$font-size-base;
        font-weight: variables.$font-weight-semibold;
    }
}

.setvaluecash {
    margin: 0 0 15px 0;

    ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;

        li {
            width: 33%;
            margin-right: 10px;

            &:last-child {
                margin: 0;
            }

            a {
                border: 1px solid variables.$gray-300;
                color: variables.$black;
                font-size: variables.$font-size-base;
                font-weight: variables.$font-weight-semibold;
                min-height: 95px;
                @include mixins.rounded(5px);
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                align-items: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                -webkit-justify-content: center;
                justify-content: center;
                -ms-flex-pack: center;
                -ms-flex-direction: column;
                flex-direction: column;
                @include mixins.margin-padding(null, 10px 20px);

                &:hover {
                    border: 1px solid variables.$purple;
                    color: variables.$purple;
                }

                img {
                    margin-bottom: 10px;
                }
            }
        }
    }
}

.setvalue {
    padding: 15px 0;

    ul {
        li {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-justify-content: space-between;
            justify-content: space-between;
            -ms-flex-pack: space-between;
            margin-bottom: 10px;

            h5 {
                font-size: variables.$font-size-13;
                font-weight: variables.$font-weight-medium;
            }

            h6 {
                font-size: variables.$font-size-13;
                font-weight: variables.$font-weight-medium;
            }

            &.total-value {
                h5 {
                    font-size: variables.$font-size-16;
                    font-weight: variables.$font-weight-semibold;
                    color: variables.$purple;
                }

                h6 {
                    font-size: variables.$font-size-16;
                    font-weight: variables.$font-weight-semibold;
                    color: variables.$purple;
                }
            }
        }
    }
}

.product-lists {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    border: 1px solid variables.$body-bg;
    box-shadow: 0px 4px 4px 0px #EDEDED40;
    @include mixins.margin-padding(0 0 15px, 15px);

    @include mixins.respond-below(sm) {
        flex-direction: column;

        li {
            margin: 10px 0;
        }
    }
}

.increment-decrement {
    .input-groups {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        margin-top: 10px;

        input[type="button"] {
            background-color: transparent;
            font-size: variables.$font-size-20;
            border: 1px solid variables.$gray-500;
            border-radius: 4px;
            height: 20px;
            width: 20px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-justify-content: center;
            justify-content: center;
            -ms-flex-pack: center;
            margin-top: 0px;
            line-height: 0;
            padding: 0;
            color: variables.$gray-500;
        }
    }

    .quantity-field {
        width: 30px;
        text-align: center;
        background-color: transparent;
        float: left;
        border: 0;
    }
}

.productimg {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;

    .productimgs {
        width: 82px;
        margin-right: 13px;

        img {
            width: 82px;
            height: 82px;
            object-fit: cover;
        }
    }

    .productcontet {
        h4 {
            font-size: variables.$font-size-base;
            color: variables.$black;
            font-weight: variables.$font-weight-semibold;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-justify-content: center;
            justify-content: center;
            -ms-flex-pack: center;
            margin-bottom: 5px;

            img {
                opacity: 0;
                margin-top: 5px;
                display: block;
            }
        }
    }

    .productlinkset {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;

        h5 {
            background: variables.$primary;
            color: variables.$white;
            font-size: variables.$font-size-12;
            @include mixins.rounded(5px);
            margin-right: 10px;
            @include mixins.margin-padding(null, 2px 10px);
        }

        a {
            position: relative;
            top: 2px;
        }
    }
}

.product-table {
    height: 34vh;
    overflow: auto;

    @include mixins.respond-below(custom991) {
        height: auto;
    }
}

.btn-set {
    @include mixins.margin-padding(15px 0 25px, null);

    h4 {
        font-weight: variables.$font-weight-bold;
    }
}

.product-detils {
    height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;

    @include mixins.respond-below(custom991) {
        height: auto;
    }
}

.tab-set {
    margin-bottom: 20px;

    ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;

        li {
            width: 48%;
            text-align: center;
            margin-right: 5px;

            @include mixins.respond-below(custom575) {
                width: 100%;
                margin-right: 0;
            }

            &:last-child {
                margin: 0;
                width: 50%;

                @include mixins.respond-below(custom575) {
                    width: 100%;
                }
            }

            a {
                background: variables.$gray-700;
                width: 100%;
                display: block;
                color: variables.$white;
                font-weight: variables.$font-weight-semibold;
                @include mixins.rounded(5px);
                @include mixins.margin-padding(null, 10px);

                @include mixins.respond-below(custom575) {
                    @include mixins.rounded(0);
                }
            }

            &.active {
                a {
                    background: variables.$primary;
                }
            }
        }
    }
}

#delete {
    .modal-body {
        .btn {
            min-width: 100px;
            @include mixins.margin-padding(null, 10px);
        }
    }
}

.owl-product {
    padding-top: 35px;

    .owl-nav {
        margin-top: 10px;
        @include mixins.position(absolute, -10px, 0, null, null);

        button {
            border-radius: 50px !important;
            width: 20px;
            height: 20px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-justify-content: center;
            justify-content: center;
            -ms-flex-pack: center;
            width: 19px;
            position: relative;
            height: 19px;
            background: variables.$white !important;
            color: variables.$purple !important;

            span {
                font-size: 0;
            }

            &.owl-next {
                span:before {
                    @include mixins.position(absolute, 2px, 5px, null, null);
                    content: "\f054";
                    font-family: "Font Awesome 5 Free";
                    font-style: normal;
                    font-weight: 900;
                    font-size: 10px;
                    color: #1A54C7;
                }
            }

            &.owl-prev {
                span:before {
                    content: "\f053";
                    font-family: "Font Awesome 5 Free";
                    font-style: normal;
                    font-weight: 900;
                    font-size: 10px;
                    color: #1A54C7;
                    @include mixins.position(absolute, 2px, 7px, null, null);
                }
            }

            &:hover {
                background: #1A54C7 !important;

                span:before {
                    color: variables.$white;
                }
            }
        }
    }

}

.actionproduct {
    text-align: right;
    margin-bottom: 15px;

    ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-justify-content: end;
        justify-content: flex-end;
        -ms-flex-pack: end;

        li {
            margin-left: 10px;
        }
    }
}

.btn-pos {
    ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        -ms-flex-pack: space-between;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;

        li {
            margin-right: 1px;
            margin-bottom: 10px;

            @include mixins.respond-below(custom991) {
                width: 48%;
            }

            @include mixins.respond-below(custom575) {
                width: 100%;
                margin-right: 10px;
            }

            a {
                &.btn {
                    color: #888B94;
                    font-size: 10px;
                    width: 100%;
                    padding: 5px 12px;
                    background: variables.$gray-300;
                    border-radius: 50px;
                    border: 1px solid variables.$gray-300;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    @include mixins.margin-padding(null, 5px 12px);

                    @include mixins.respond-below(custom991) {
                        min-width: auto;
                        @include mixins.margin-padding(null, 6px 15px);
                    }

                    &:hover {
                        border: 1px solid #EA5455;
                        color: #EA5455;
                        background: rgba(234, 84, 85, 0.06);
                    }
                }

                &.btn-hold {
                    background: variables.$warning;
                }

                &.btn-quation {
                    background: variables.$gray-600;
                }

                &.btn-void {
                    background: #D0512E;
                }

                &.btn-payment {
                    background: variables.$success ;
                }

                &.btn-recent {
                    background: variables.$primary ;
                }
            }
        }
    }
}

.calculator-set {
    .calculatortotal {
        margin-bottom: 15px;

        h4 {
            width: 100%;
            background: variables.$primary;
            text-align: center;
            color: variables.$white;
            font-size: variables.$font-size-36;
            font-weight: variables.$font-weight-bold;
            @include mixins.rounded(5px);
        }
    }

    ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;

        li {
            width: 33.3%;
            text-align: center;

            a {
                width: 100%;
                border: 1px solid variables.$gray-500;
                font-size: variables.$font-size-24;
                font-weight: variables.$font-weight-medium;
                display: block;
                color: variables.$black;
                @include mixins.rounded(0);
                @include mixins.margin-padding(null, 10px);

                &.btn-closes {
                    background: variables.$danger-700;
                }

                &.btn-reverse {
                    background: #2E7D32;
                }

                &:hover {
                    background: variables.$gray-100;
                }
            }
        }
    }
}

.hold-order {
    text-align: center;
    @include mixins.margin-padding(15px 0, null);

    h2 {
        @include mixins.margin-padding(null, 0 0 30px);
        color: variables.$primary;
        font-size: variables.$font-size-36;
        font-weight: variables.$font-weight-bold;
    }
}

.para-set {
    margin-bottom: 30px;

    p {
        color: variables.$gray-700;
        font-size: variables.$font-size-13;
        font-weight: variables.$font-weight-medium;
    }
}

.delete-order {
    text-align: center;
}

.btn-set {
    h4 {
        background: variables.$primary;
        text-align: center;
        color: variables.$white;
        @include mixins.rounded(5px);
        @include mixins.margin-padding(null, 10px);
    }
}

.header-menuset {
    @include mixins.margin-padding(null, 15px);

    ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;

        li {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-justify-content: space-between;
            justify-content: space-between;
            -ms-flex-pack: space-between;
            width: 50%;
            margin-bottom: 10px;

            @include mixins.respond-below(custom991) {
                width: 100%;
            }

            .win-maximize {
                display: none;
            }

            a {
                border: 1px solid variables.$primary;
                width: 95%;
                text-align: center;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                align-items: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                -webkit-justify-content: center;
                justify-content: center;
                -ms-flex-pack: center;
                background: variables.$primary;
                color: variables.$white;
                @include mixins.margin-padding(null, 10px);

                img {
                    filter: brightness(0) invert(1);
                }
            }
        }
    }
}

.invoice-load-btn {
    text-align: center;
    margin-top: 20px;

    .btn {
        font-weight: variables.$font-weight-bold;
        font-size: variables.$font-size-16;
        color: variables.$primary;
        min-width: 150px;
        background: variables.$white;
        border: 2px solid variables.$primary;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        -ms-flex-pack: space-between;
        @include mixins.rounded(6px);
        @include mixins.margin-padding(null, 12px 15px);

        span {
            display: inline-block;
            width: 25px;
            height: 25px;
            margin-right: 8px;
            vertical-align: 0;
            border: 3px solid #fc88037d;
            border-right-color: variables.$primary;
            animation: .75s linear infinite spinner-border;
            @include mixins.rounded(50%);
        }

        &:hover {
            background: variables.$primary;
            color: variables.$white;

            span {
                border: 3px solid variables.$white;
                border-right-color: variables.$white;
            }
        }
    }
}

.form-new {
    .input-blocks {
        input {
            height: 50px;
            background: variables.$light;
        }

        .scanner-set {
            min-width: 50px;
            @include mixins.position(relative, null, null, null, -2px);
        }
    }
}

.product-details {
    background: variables.$white;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0px 4px 4px 0px #EDEDED40;
    border: 1px solid #F8F8F8 !important;
    height: 105px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    flex-direction: column;

    &:hover {
        background: variables.$purple;

        h6 {
            color: variables.$white;
        }
    }

    &.active {
        background-color: variables.$purple !important;

        h6 {
            color: variables.$white;
        }
    }

    img {
        width: auto !important;
    }

    h6 {
        font-size: variables.$font-size-13;
        color: variables.$black;
        margin-top: 10px;
    }
}

.btn-scanner-set {
    background: variables.$purple;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    max-width: 160px;
    margin-left: auto;
    color: variables.$white;
    font-size: variables.$font-size-base;
    font-weight: variables.$font-weight-bold;

    &:hover {
        background: variables.$primary;
        color: variables.$white;
    }
}

.split-card {
    margin: 0 0 15px;
    border: 1px dashed variables.$body-bg;
    position: relative;

    &::before {
        position: absolute;
        content: "";
        background: variables.$body-bg;
        width: 22px;
        height: 22px;
        border-radius: 50px;
        top: -10px;
        left: -10px;
    }

    &::after {
        position: absolute;
        content: "";
        background: variables.$body-bg;
        width: 22px;
        height: 22px;
        border-radius: 50px;
        top: -10px;
        right: -10px;
    }
}

.totalitem {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    margin-bottom: 15px;

    h4 {
        padding: 7px 13px;
        background: #F8F8F8;
        color: variables.$purple;
        font-size: 12px;
        font-weight: variables.$font-weight-bold;
        border-radius: 50px;
    }

    a {
        font-size: variables.$font-size-base;
        color: #EA5455;
        font-weight: variables.$font-weight-bold;
    }
}

.card {
    &.card-order {
        border: 0;
    }
}

.btn-adds {
    border: 2px solid variables.$success;
    color: variables.$success;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    font-weight: variables.$font-weight-semibold;
    margin-bottom: 20px;

    &:hover {
        background: variables.$success;
        color: variables.$white;
    }
}

.order-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    margin-bottom: 25px;

    .orderid {
        h4 {
            font-size: variables.$font-size-18;
            font-weight: variables.$font-weight-bold;
            color: #5E5873;
            margin-bottom: 0;
        }

        h5 {
            font-size: variables.$font-size-base;
            font-weight: variables.$font-weight-normal;
            color: variables.$black;
        }
    }

    .actionproducts {
        >ul {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;

            >li {
                margin-left: 15px;

                .deletebg {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    align-items: center;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    -webkit-justify-content: center;
                    justify-content: center;
                    -ms-flex-pack: center;
                    background: variables.$white;
                    box-shadow: 0px 4px 14px 0px #C7C7C740;
                    width: 30px;
                    height: 30px;
                    border-radius: 5px;
                }
            }
        }
    }
}

.tabs_wrapper {
    .owl-product {
        margin-bottom: 25px;
    }
}

.productset {
    border: 1px solid #F8F8F8;
    box-shadow: 0px 4px 4px 0px #EDEDED40;
    background: variables.$white;
    margin: 0 0 25px;
    border-radius: 5px;
    position: relative;
    width: 100%;
    overflow: hidden;

    &:hover {
        border: 1px solid variables.$purple;
    }

    .check-product {
        display: none;
        width: 34px;
        height: 23px;
        color: variables.$white;
        position: absolute;
        left: 0;
        top: 0;
        background: variables.$purple;
        border-radius: 4px 0;
    }

    &.active {
        border: 1px solid variables.$purple;

        .check-product {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-justify-content: center;
            justify-content: center;
            -ms-flex-pack: center;
        }
    }

    &:hover {
        .productsetimg {
            img {
                transform: scale(1.2);
            }

            h6 {
                transform: translatey(0);
            }
        }
    }

    .productsetimg {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-justify-content: center;
        justify-content: center;
        -ms-flex-pack: center;
        position: relative;
        overflow: hidden;

        img {
            transition: all 0.5s;
            border-radius: 5px 5px 0px 0px;

            @include mixins.respond-below(custom991) {
                max-height: 150px;
            }
        }

        h6 {
            position: absolute;
            background: variables.$primary;
            color: variables.$white;
            font-size: 10px;
            padding: 5px;
            border-radius: 5px;
            top: 20px;
            right: 20px;
            transform: translatey(-60px);
            transition: all 0.5s;
        }
    }

    .productsetcontent {
        padding: 13px;
        text-align: center;

        h4 {
            font-size: variables.$font-size-base;
            font-weight: variables.$font-weight-semibold;
            color: variables.$black;
            margin-bottom: 13px;
        }

        h5 {
            color: #6E6B7B;
            font-size: 12px;
        }

        h6 {
            color: #EA5455;
            margin: 0 0 0 auto;
            font-weight: variables.$font-weight-semibold;
            font-size: variables.$font-size-base;
        }
    }
}

.tabs_wrapper {
    ul.tabs {
        display: inline-block;
        width: 100%;
        padding-left: 0;

        li {
            list-style: none;
            cursor: pointer;
            display: inline-block;
            border-radius: 5px;
            color: white;
            font-family: sans-serif;
            width: 100%;

            &.active {
                background-color: variables.$primary;
                border-color: variables.$primary;

                h6 {
                    a {
                        color: variables.$white;
                    }
                }

                .product-details {
                    background: transparent;
                    border: 0 !important;

                    h6 {
                        color: variables.$white;
                    }
                }
            }
        }
    }

    .tabs_container {
        padding-top: 00px;
        display: inline-block;
        width: 100%;

        .tab_content {
            display: none;
            width: 100%;

            &.active {
                display: inline-block;
            }
        }
    }
}