@use "../utils/variables";

.page-header-breadcrumb {
  .breadcrumb-item+.breadcrumb-item::before {
    content: var(--bs-breadcrumb-divider, "\ea65");
    font-family: "tabler-icons" !important;
  }
}

[dir="rtl"] {
  .page-header-breadcrumb {
    .breadcrumb-item+.breadcrumb-item::before {
      content: var(--bs-breadcrumb-divider, "\ea64");
      font-family: "tabler-icons" !important;
    }
  }
}

.breadcrumb {
  .breadcrumb-item {
    a {
      color: variables.$text-muted;
    }

    &.active {
      color: variables.$primary;
    }
  }
}

.breadcrumb-item+.breadcrumb-item::before {
  color: variables.$text-muted;
  opacity: 0.4;
}

.breadcrumb-arrow {
  .breadcrumb-item+.breadcrumb-item::before {
    color: variables.$text-muted;
    content: "\f101";
    font-family: variables.$font-awesome !important;
  }
}

.breadcrumb-pipe {
  .breadcrumb-item+.breadcrumb-item::before {
    color: variables.$text-muted;
    content: "\f061";
    font-family: variables.$font-awesome !important;
  }
}

.breadcrumb-line {
  .breadcrumb-item+.breadcrumb-item::before {
    color: variables.$text-muted;
    content: "-";
  }
}

.breadcrumb-dot {
  .breadcrumb-item+.breadcrumb-item::before {
    color: variables.$text-muted;
    content: "•";
  }
}

.breadcrumb-divide {
  .breadcrumb-item+.breadcrumb-item::before {
    color: variables.$text-muted;
    content: "\f054";
    font-family: variables.$font-awesome !important;
  }
}

.breadcrumb-separatorless {
  .breadcrumb-item+.breadcrumb-item::before {
    content: "";
    display: none;
  }
}

.breadcrumb-colored {
  padding: 12px 15px;
  border-radius: variables.$border-radius;

  li {
    a {
      color: variables.$white;
    }
  }

  .breadcrumb-item {
    a {
      color: variables.$white;
    }

    &.active {
      color: variables.$white;
      opacity: 0.8;
    }

    &+.breadcrumb-item {
      a {
        color: variables.$white;
      }

      &::before {
        color: variables.$white;
      }
    }
  }
}

.embedded-breadcrumb:before {
  opacity: 0.7;
}

[data-theme-mode="dark"] {
  .embedded-breadcrumb:before {
    filter: invert(1);
  }
}