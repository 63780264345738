@use "../utils/mixins";
@use "../utils/variables";

.icons-list {
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  li {
    display: block;
    width: 40px;
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    font-size: variables.$font-size-18;
    @include mixins.rounded(50px);
    border: 1px solid #e6ebf1;
    color: variables.$primary;
    @include mixins.margin-padding(5px, null);
    @include mixins.box-shadow(null, 0, 2px, 3px, null, rgb(215, 197, 255));
  }
}

.icons-color {
  color: variables.$primary;
}