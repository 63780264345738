@use "../utils/variables";

.img-cropper {
  .dropup {
    .dropdown-toggle {
      &::after {
        content: '';
        display: none;
      }
    }
  }
}

.dropdown-auto {
  overflow: auto;
}

.dropdown-divider {
  margin: 0;
}

.dropdown {
  .dropdown-toggle {

    &:after {
      display: inline-block;
      margin-inline-start: 0.25rem;
      vertical-align: 0;
      content: "\f078";
      font-family: "Font Awesome 5 Free";
      border: 0;
      font-size: 0.6rem;
      font-weight: variables.$font-weight-semibold;
    }

    &.btn-primary {
      color: variables.$white;
    }
  }

  .dropdown-menu {
    font-family: variables.$font-family-primary;
  }
}

.dropdown-menu {
  border-color: variables.$border-color;
  box-shadow: 0 1rem 1.125rem rgba(169, 169, 169, 0.15);
  font-size: 0.875rem;
  color: variables.$text-color;
  background-color: variables.$white;
  padding: 0;
  z-index: 10;
}

.dropdown-divider {
  border-color: variables.$border-color;
}

.dropdown-item-text {
  color: variables.$text-color;
}

.dropdown-header {
  color: variables.$text-color;
}

.dropdown-item {
  color: variables.$text-color;
  white-space: unset;
  padding: 0.5rem 0.9375rem;
  font-size: 0.8125rem;

  &.disabled,
  &:disabled {
    color: variables.$text-color;
    opacity: 0.5;
  }

  &:not(.active) {

    &:hover,
    &:focus,
    &:active {
      color: variables.$primary;
      background-color: rgba(variables.$primary, 0.05);
    }
  }

  &.active {
    background-color: variables.$primary !important;
  }
}

.dropdown-toggle::after {
  display: inline-block;
  margin-inline-start: 0.25rem;
  vertical-align: 0;
  content: "\f078";
  font-family: "Font Awesome 5 Free";
  border: 0;
  font-size: 0.6rem;
  font-weight: variables.$font-weight-semibold;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-inline-start: 0.25rem;
  vertical-align: 0;
  content: "\f078";
  font-family: "Font Awesome 5 Free";
  border: 0;
  font-size: 0.6rem;
  font-weight: variables.$font-weight-semibold;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-inline-start: 0.25rem;
  vertical-align: 0;
  content: "\f077";
  font-family: "Font Awesome 5 Free";
  border: 0;
  font-size: 0.6rem;
  font-weight: variables.$font-weight-semibold;
}

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-inline-start: 0.25rem;
  vertical-align: 0;
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  border: 0;
  font-size: 0.6rem;
  font-weight: variables.$font-weight-semibold;
}

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-inline-start: 0.25rem;
  vertical-align: 0;
  content: "\f053";
  font-family: "Font Awesome 5 Free";
  border: 0;
  font-size: 0.6rem;
  font-weight: variables.$font-weight-semibold;
}

.btn.dropdown-toggle-split {
  opacity: 0.85;
}

.dropdown-menu-dark {
  background-color: variables.$dark;
  border-color: rgba(255, 255, 255, 0.1);

  li a {
    color: variables.$white;
  }
}

.btn-list {
  a.dropdown-item {
    margin-block-end: 0;
  }

  .btn-soft-success {
    &:hover {
      transform: translateY(0);
    }
  }

  .btn-soft-danger {
    &:hover {
      transform: translateY(0);
    }
  }
}

.dropdown-menu {
  @mixin dropdown-menu-color($color) {
    background-color: $color;

    li a {
      color: rgba(255, 255, 255, 0.9);
    }

    .dropdown-item {

      &:hover,
      &:focus,
      &:active,
      &.active {
        color: variables.$white;
      }
    }
  }

  &.dropdown-menu-primary {
    @include dropdown-menu-color(variables.$primary);
  }

  &.dropdown-menu-secondary {
    @include dropdown-menu-color(variables.$secondary);
  }

  &.dropdown-menu-warning {
    @include dropdown-menu-color(variables.$warning);
  }

  &.dropdown-menu-info {
    @include dropdown-menu-color(variables.$info);
  }

  &.dropdown-menu-success {
    @include dropdown-menu-color(variables.$success);
  }

  &.dropdown-menu-danger {
    @include dropdown-menu-color(variables.$danger);
  }
}

.dropdown-menu {
  &.dropmenu-item-primary {
    .dropdown-item {

      &:hover,
      &:focus,
      &:active,
      &.active {
        background-color: rgba(variables.$primary, 0.1) !important;
        color: variables.$primary;
      }
    }
  }

  &.dropmenu-item-secondary {
    .dropdown-item {

      &:hover,
      &:focus,
      &:active,
      &.active {
        background-color: rgba(variables.$secondary, 0.1) !important;
        color: variables.$secondary;
      }
    }
  }

  &.dropmenu-item-warning {
    .dropdown-item {

      &:hover,
      &:focus,
      &:active,
      &.active {
        background-color: rgba(variables.$warning, 0.1) !important;
        color: variables.$warning;
      }
    }
  }

  &.dropmenu-item-info {
    .dropdown-item {

      &:hover,
      &:focus,
      &:active,
      &.active {
        background-color: rgba(variables.$info, 0.1) !important;
        color: variables.$info;
      }
    }
  }

  &.dropmenu-item-success {
    .dropdown-item {

      &:hover,
      &:focus,
      &:active,
      &.active {
        background-color: rgba(var(--success-rgb), 0.1) !important;
        color: variables.$success;
      }
    }
  }

  &.dropmenu-item-danger {
    .dropdown-item {

      &:hover,
      &:focus,
      &:active,
      &.active {
        background-color: rgba(var(--danger-rgb), 0.1) !important;
        color: variables.$danger;
      }
    }
  }
}

.dropdown-menu {
  @mixin dropmenu-light-color($color) {
    -webkit-backdrop-filter: blur(2rem);
    backdrop-filter: blur(2rem);

    .dropdown-item {

      &:hover,
      &:focus,
      &:active,
      &.active {
        color: variables.$white;
        background-color: $color !important;
      }
    }
  }

  &.dropmenu-light-primary {
    background-color: rgba(var(variables.$secondary), 0.1);
    @include dropmenu-light-color(variables.$primary);
  }

  &.dropmenu-light-secondary {
    background-color: rgba(var(variables.$secondary), 0.1);
    @include dropmenu-light-color(variables.$secondary);
  }

  &.dropmenu-light-info {
    background-color: rgba(variables.$info, 0.1);
    @include dropmenu-light-color(variables.$info);
  }

  &.dropmenu-light-warning {
    background-color: rgba(variables.$warning, 0.1);
    @include dropmenu-light-color(variables.$warning);
  }

  &.dropmenu-light-success {
    background-color: rgba(variables.$success, 0.1);
    @include dropmenu-light-color(variables.$success);
  }

  &.dropmenu-light-danger {
    background-color: rgba(var(--danger-rgb), 0.1);
    @include dropmenu-light-color(variables.$danger);
  }
}

.dropdown-divider {
  margin: 0;
}

.bd-example>.dropdown-menu {
  position: static;
  display: block;
}