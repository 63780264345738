@use "../utils/variables";

[data-layout-mode=dark_mode] {
    .searchinputs input {
        border-color: variables.$black-bg;
        background: variables.$input-bg;
    }

    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6 {
        color: variables.$theme-title;
    }

    &[data-nav-color=grey] {
        .header {
            background: variables.$black-bg;

            .user-menu .nav-item-box>a {
                background: variables.$input-bg;
            }
        }

        .sidebar .slimScrollDiv,
        .sidebars .slimScrollDiv {
            background: variables.$black-bg;
        }

        .searchinputs input {
            border-color: variables.$black-bg;
            background: variables.$input-bg;
        }
    }

    &[data-layout-style=collapsed] {
        &[data-nav-color=grey] {
            aside {
                background: variables.$black-bg;
            }
        }
    }

    &[data-layout-style=horizontal] {
        .sidebar.horizontal-sidebar {
            background: variables.$black-bg;
            border-top-color: variables.$input-bg;
        }

        .horizontal-sidebar .sidebar-menu>ul>li.submenu ul {
            background: variables.$black-bg;
        }
    }

    &[data-layout-style=modern] {
        .page-wrapper .content {
            background: variables.$black-bg;
        }
    }

    --bs-heading-color: $black-bg;
    --dt-html-background: $black-bg;

    &:root {
        --dt-html-background: $black-bg;
    }

    &[data-layout-style=collapsed] {
        aside {
            background-color: variables.$black-bg;
        }
    }

    .fc-unthemed th,
    .fc-unthemed td,
    .fc-unthemed thead,
    .fc-unthemed tbody,
    .fc-unthemed .fc-divider,
    .fc-unthemed .fc-row,
    .fc-unthemed .fc-popover {
        border-color: variables.$input-bg !important;
    }

    div#global-loader {
        background-color: variables.$black-bg !important;
    }

    body {
        background-color: variables.$black-bg;
        color: variables.$theme-title;

        &.header-collapse {
            .header {
                .header-left {
                    background-color: variables.$black-bg;
                }
            }
        }

        &.mini-sidebar {
            .header-left .logo-white {
                display: none !important;
            }
        }

        &.expand-menu {
            .header-left .logo-white {
                display: block !important;
            }
        }

        .page-wrapper-new {
            background-color: variables.$black-bg;
        }

        label,
        .form-label,
        .form-control,
        .notes-summernote p {
            color: variables.$theme-title;
        }
    }

    background-color: variables.$black-bg;
    color: variables.$theme-title;

    .header {
        background: variables.$black-bg;
        border-bottom: 1px solid variables.$navy;

        .header-left {
            background: variables.$black-bg;
            border-color: variables.$navy;
            border-bottom: 1px solid variables.$navy;

            .logo-normal {
                display: none;
            }

            .logo-white {
                display: block !important;
            }
        }
    }

    table {
        background: variables.$black-bg;
        --bs-table-bg: transparent;
    }

    .sidebar {
        border-right: 1px solid variables.$navy;
        background-color: variables.$black-bg;

        .slimScrollDiv {
            background: variables.$black-bg;
        }

        &.collapsed-sidebar {
            border: 0;
        }

        .sidebar-menu> {
            ul>li {
                &.submenu ul {
                    background: variables.$black-bg;
                    border-radius: 5px;
                    padding: 10px 0;

                    li {
                        a {
                            color: variables.$gray-500;
                        }
                    }
                }

                a {
                    img {
                        filter: brightness(0) saturate(100%) invert(65%) sepia(27%) saturate(206%) hue-rotate(165deg) brightness(93%) contrast(88%);
                    }

                    span {
                        color: variables.$gray-500;
                    }

                    &:hover {
                        img {
                            color: variables.$gray-500;
                        }

                        svg {
                            filter: brightness(0) saturate(100%) invert(65%) sepia(27%) saturate(206%) hue-rotate(165deg) brightness(93%) contrast(88%);
                        }
                    }
                }

                .submenu-hdr {
                    color: variables.$gray-500 !important;
                }
            }
        }
    }

    .sidebar .sidebar-menu>ul>li.submenu-open ul,
    .sidebars .sidebar-menu>ul>li.submenu-open ul {
        border-bottom: 1px solid variables.$navy;
    }

    .page-wrapper {
        background: variables.$black-bg;

        &.page-wrapper-three {
            .content {
                background: variables.$black-bg;
            }
        }
    }

    #toggle_btn {
        &:before {
            border: 2px solid variables.$theme-title;
        }
    }

    .user-menu.nav>li>a.select-store {
        .user-info .user-name {
            color: variables.$theme-title;
        }
    }

    .user-info .user-name {
        color: variables.$theme-title;
    }

    .user-menu {
        .nav-item-box {
            >a {
                background: variables.$secondary;
            }
        }
    }

    .header-left {
        &.active {
            #toggle_btn:after {
                border: 2px solid variables.$theme-title;
            }
        }
    }

    .dash-widget {
        background: variables.$card-dark-bg;
        border: 1px solid variables.$navy;

        .dash-widgetcontent {
            h5 {
                color: variables.$theme-title;
            }

            h6 {
                color: variables.$theme-title;
            }
        }
    }

    .page-header {

        h4,
        h6 {
            color: variables.$theme-title;
        }
    }

    .form-control {
        color: variables.$theme-title;
        border: 1px solid variables.$navy;
    }

    select.form-control {
        &:focus {
            color: variables.$theme-title;
        }
    }

    .input-blocks label {
        color: variables.$theme-title;
    }

    .dataTables_info {
        color: variables.$theme-title;
    }

    .setvaluecash ul li a {
        color: variables.$theme-title;
    }

    .inbox-menu li a i {
        color: variables.$theme-title;
    }

    .card {
        background-color: variables.$card-dark-bg;
        border: 1px solid variables.$navy;

        .card-header {
            border-color: variables.$navy;

            .card-title {
                color: variables.$theme-title;
            }
        }

        .card-body {
            .card-title {
                color: variables.$theme-title;
            }
        }
    }

    .modal-content {
        background-color: variables.$gray-900;

        .modal-header {
            border-bottom: 1px solid variables.$navy;
        }
    }

    .inputcheck {
        color: variables.$theme-title;
    }

    .custom_radio {
        color: variables.$theme-title;
    }

    .fc th.fc-widget-header {
        background-color: variables.$gray-900;
        border-color: variables.$input-bg;
    }

    .fc-day {
        background-color: variables.$gray-900 !important;
    }

    .chat-cont-left .chat-scroll .media {
        .media-body {

            .user-name,
            .user-last-chat {
                color: variables.$theme-title;
            }
        }

        &.active,
        &:hover {
            background: variables.$title-color;
        }
    }

    .chat-cont-right ul.list-unstyled .media.sent .media-body .msg-box p {
        color: variables.$secondary;
    }

    .inbox-menu {
        li {
            a {
                color: variables.$theme-title;
            }
        }
    }

    .increment-decrement .quantity-field {
        color: variables.$theme-title;
    }

    .select2-results__options {
        color: variables.$gray-800;
    }

    .image-upload .image-uploads h4 {
        color: variables.$theme-title;
    }

    .horizontal-sidebar {
        background: variables.$black-bg;
        border-top: 1px solid variables.$navy;

        &.side-three {
            border-color: variables.$navy;
        }

        .sidebar-menu>ul>li {
            &>a {
                span {
                    color: variables.$gray-500;
                }

                img,
                svg {
                    filter: brightness(0) saturate(100%) invert(65%) sepia(27%) saturate(206%) hue- rotate(165deg) brightness(93%) contrast(88%);
                }
            }

            &.submenu ul {
                background: variables.$black-bg;

                li {
                    a {
                        color: variables.$gray-500;
                    }

                    img,
                    svg {
                        filter: brightness(0) saturate(100%) invert(65%) sepia(27%) saturate(206%) hue-rotate(165deg) brightness(93%) contrast(88%) !important;
                    }

                    &>ul {
                        box-shadow: none;
                    }
                }
            }
        }

    }

    .sidebar.side-three.horizontal-sidebar .sidebar-menu>ul>li ul li.submenu {
        a {
            svg {
                filter: brightness(0) saturate(100%) invert(65%) sepia(27%) saturate(206%) hue-rotate(165deg) brightness(93%) contrast(88%);
            }
        }

        ul li a {
            color: variables.$gray-500;
        }
    }

    .table {
        thead {
            background: variables.$black-bg;
            border-bottom: 1px solid variables.$black-bg;

            tr {
                &:hover {
                    background: transparent;
                }

                th {
                    color: variables.$theme-title;
                }

            }
        }

        tbody {
            tr {
                &:hover {
                    background: transparent;
                }

                td {
                    color: variables.$theme-title;
                    border-bottom: 1px solid variables.$navy;
                    background: variables.$card-dark-bg;

                    a {
                        color: variables.$theme-title !important;

                        &:hover {
                            color: variables.$primary !important;
                        }
                    }

                    .action-table-data {
                        .edit-delete-action {
                            a {
                                &:hover {
                                    background-color: rgb(40, 142, 199);
                                    border-color: rgb(40, 142, 199);
                                }
                            }

                            .confirm-text {
                                &:hover {
                                    background-color: variables.$danger;
                                    border-color: variables.$danger;
                                }
                            }
                        }

                        a {
                            .action-eye {
                                color: variables.$theme-title
                            }
                        }
                    }
                }
            }

        }
    }

    .sidebar.collapsed-sidebar .ui-aside .nav-item .nav-link {
        border-bottom: variables.$navy;
    }

    .sidebar.collapsed-sidebar .sidebar-menu ul li a img {
        filter: brightness(0) saturate(100%) invert(65%) sepia(27%) saturate(206%) hue-rotate(165deg) brightness(93%) contrast(88%);
    }

    .select2-results__option {
        background: variables.$gray-900;
        color: variables.$theme-title;
    }

    .custom-file-container label {
        color: variables.$theme-title;
    }

    .select2-dropdown {
        border: 2px solid #26264e;
    }

    .swal2-popup {
        background-color: variables.$gray-900;

        .swal2-title {
            color: variables.$theme-title;
        }

        .swal2-html-container {
            color: variables.$theme-title;
        }
    }

    .searchinputs {
        input {
            border: 1px solid variables.$navy;
            background: variables.$card-dark-bg;
            color: variables.$theme-title;

            &::placeholder {
                color: variables.$gray-500;
            }
        }
    }

    .image-upload-new .productviewscontent .productviewsname {

        h2,
        h3 {
            color: variables.$gray-500;
        }
    }

    .split-card {

        &::before,
        &::after {
            background: variables.$navy;
        }
    }

    input[type="email"] {
        border: 1px solid variables.$gray-700;
    }

    .tabs-set .nav-tabs .nav-link {
        background: variables.$black-bg;
        color: variables.$theme-title;

        &.active {
            background: variables.$primary;
            color: variables.$white;
        }
    }

    .bor-b1 {
        border-bottom: 1px solid variables.$navy;
    }

    .total-order ul li {
        border: 1px solid variables.$navy;

        &:first-child {
            border-top: 1px solid variables.$navy;
        }

        h4 {
            border-right: 1px solid variables.$navy;
        }
    }

    hr {
        background-color: variables.$navy;
        border-top: 1px solid variables.$navy;
    }

    .inputcheck .checkmark {
        background: transparent;
    }

    .productset {
        background-color: variables.$gray-900;
        border: 1px solid variables.$navy;

        .productsetcontent {
            h4 {
                color: variables.$gray-500;
            }
        }
    }

    .graph-sets {
        .btn-light {
            background-color: variables.$black-bg !important;
            color: variables.$gray-500 !important;
            border: 1px solid variables.$navy !important;
            box-shadow: none;

            &:hover {
                color: variables.$gray-500 !important;
            }
        }

        .btn-white {
            background-color: variables.$black-bg;
            color: variables.$gray-500;

            &:hover {
                color: variables.$gray-500;
            }
        }

        .dropdown-menu {
            background-color: variables.$black-bg;
            border: 1px solid variables.$navy;

            .dropdown-item {
                color: variables.$gray-500;

                &:hover {
                    color: variables.$gray-500;
                    background: variables.$gray-900;
                }
            }
        }
    }

    .view-all-link {
        a {
            color: variables.$theme-title;

            &:hover {
                color: variables.$primary;
            }
        }
    }

    .header {
        .dropdown-menu {
            background: variables.$black-bg;
            color: variables.$theme-title;
        }
    }

    .order-list .orderid {

        h4,
        h5 {
            color: variables.$theme-title;
        }
    }

    .productimg {
        .productcontet h4 {
            color: variables.$theme-title;
        }
    }

    .topnav-dropdown-header {
        border-bottom: 1px solid variables.$navy;

        .notification-title {
            color: variables.$theme-title;
        }
    }

    .topnav-dropdown-footer {
        border-top: 1px solid variables.$navy;

        a {
            color: variables.$theme-title;

            &:hover {
                color: variables.$primary;
            }
        }
    }

    .user-menu.nav>li>a.select-store {
        border: 1px solid variables.$navy;
    }

    .custom-file-container .custom-file-container__custom-file__custom-file-control__button {
        color: variables.$theme-title;
    }

    .notifications {
        .notification-list {
            li {
                border-bottom: 1px solid variables.$navy;

                .noti-title {
                    color: variables.$theme-title;
                }

                .noti-details {
                    color: variables.$gray-500;
                }

                .notification-time {
                    color: variables.$gray-500;
                }
            }
        }
    }

    .user-menu.nav {
        li {
            &.flag-nav {
                .dropdown-menu {
                    .dropdown-item {
                        color: variables.$gray-500;

                        &.active {
                            color: variables.$white;
                        }

                        &:hover {
                            background: variables.$gray-900 url(../../../../public/assets/img/icons/check.svg) no-repeat calc(100% - 12px) center;
                        }
                    }
                }
            }
        }

        &.nav {
            >li {
                >a {
                    &.dropdown-toggle {
                        &.nav-link {
                            &.show {
                                background: variables.$gray-900;
                            }
                        }
                    }
                }
            }
        }
    }

    .profilename {
        .profileset {

            h5,
            h6 {
                color: variables.$gray-500;
            }
        }
    }

    .header {
        .menu-drop-user {
            .dropdown-item {
                color: variables.$gray-500;

                &:hover {
                    color: variables.$gray-500;
                    background: variables.$gray-900;
                }
            }
        }
    }

    .card {
        &.bg-white {
            background-color: variables.$gray-900 !important;
        }

        .card-header {
            background-color: variables.$card-dark-bg;

            .dropset {
                color: variables.$gray-500;
            }
        }

        .card-footer {
            background-color: variables.$gray-900;
        }
    }

    .custom-file-container .custom-file-container__custom-file__custom-file-control {
        border: 1px solid variables.$navy;

        .custom-file-container__custom-file__custom-file-control__button {
            border-left: 1px solid variables.$navy;
        }
    }

    .scanner-set {
        img {
            filter: brightness(0) saturate(100%) invert(65%) sepia(27%) saturate(206%) hue-rotate(165deg) brightness(93%) contrast(88%);
            width: 16px;
        }
    }

    .dropdown-menu {
        background: variables.$black-bg;
        color: variables.$theme-title;

        .dropdown-item {
            color: variables.$gray-500;

            &:hover {
                color: variables.$gray-500;
                background: variables.$gray-900;
            }
        }
    }

    .page-header {
        h3 {
            color: variables.$theme-title;
        }
    }

    .search-set {
        .search-input {
            input[type="search"] {
                border: 1px solid variables.$navy;

                &::placeholder {
                    color: variables.$theme-title;
                }
            }
        }
    }

    .input-blocks input[type=text],
    .input-blocks input[type=password],
    .select2-container--default .select2-selection--single,
    .select2-container--focus .select2-selection--single,
    .form-control,
    .form-select,
    .select2-container--default .select2-selection--multiple {
        background: variables.$card-dark-bg !important;
        color: variables.$theme-title;

        &::placeholder {
            color: variables.$theme-title;
        }
    }

    .select2-container--default .select2-results__option[aria-selected=true] {
        background: variables.$gray-900 !important;
        color: variables.$theme-title !important;
    }

    .select2-container--default .select2-selection--single .select2-selection__rendered {
        color: variables.$theme-title;
        border-color: variables.$navy;
        border: 1px solid variables.$navy;
    }

    .select2-container--default .select2-selection--single {
        border-color: variables.$navy;
        border: 1px solid variables.$navy;
    }

    .select2-container--focus .select2-selection--single {
        border-color: variables.$navy;
        border: 1px solid variables.$navy;
    }

    .input-blocks {
        input[type=text] {
            &:focus {
                border-color: variables.$navy;
            }
        }
    }

    .custom-file-container {
        .custom-file-container__custom-file__custom-file-control {
            background: variables.$gray-900;
            color: variables.$theme-title;
            border-color: transparent;
        }
    }

    .input-group-text {
        background: variables.$gray-900;
        color: variables.$theme-title;
        border: 1.5px solid rgba(145, 158, 171, 0.32);
    }

    .image-upload {
        &:hover {
            background: variables.$gray-900;
        }
    }

    .productdetails {
        &>ul {
            &>li {
                h4 {
                    color: variables.$gray-500;
                }
            }
        }
    }

    .profile-set .profile-top .profile-contentname h2 {
        color: variables.$theme-title;
    }

    .total-order {
        ul {
            li {
                h4 {
                    background: variables.$gray-900;
                }

                h5 {
                    color: variables.$theme-title;
                }
            }
        }
    }

    .product-details {
        background: variables.$gray-900;

        h6 {
            color: variables.$theme-title;
        }
    }

    .comp-section {
        .card-header {
            border: 1px solid variables.$navy;
        }

        .nav-tabs.nav-tabs-solid {
            background-color: variables.$gray-900;
        }
    }

    .list-group-item {
        background: variables.$gray-900;
        color: variables.$theme-title;
    }

    .bg-white {
        background-color: variables.$gray-900 !important;
    }

    .section-header {
        .section-title {
            color: variables.$theme-title;
        }
    }

    .breadcrumb .breadcrumb-item a {
        color: variables.$theme-title;
    }

    .product-list ul li {
        .productviews .productviewscontent .productviewsname {

            h2,
            h3 {
                color: variables.$theme-title;
            }
        }
    }

    .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
        border-color: variables.$theme-title;
    }

    .alertify {
        .ajs-header {
            background: variables.$gray-900;
        }

        .ajs-dialog {
            background: variables.$gray-900;
        }

        .ajs-footer {
            background: variables.$gray-900;

            .ajs-buttons .ajs-button {
                color: variables.$theme-title;
            }
        }
    }

    .bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
    .bs-popover-top>.popover-arrow::after {
        border-top-color: variables.$navy;
    }

    .popover {
        background-color: variables.$gray-900;
        border: 1px solid variables.$navy;

        .popover-header {
            background-color: variables.$gray-900;
            border-bottom: 1px solid variables.$navy;
        }

        .popover-body {
            color: variables.$theme-title;
        }
    }

    .scroll-demo {
        border: 1px solid variables.$navy;
    }

    .timeline:before {
        background-color: variables.$navy;
    }

    .timeline>li {
        &>.timeline-panel {
            border: 1px solid variables.$navy;

            &::before {
                border-top: 8px solid transparent;
                border-right: 0 solid variables.$navy;
                border-bottom: 8px solid transparent;
                border-left: 8px solid variables.$navy;
            }

            &::after {
                border-top: 7px solid transparent;
                border-right: 0 solid variables.$navy;
                border-bottom: 7px solid transparent;
                border-left: 7px solid variables.$navy;
            }
        }

        &.timeline-inverted>.timeline-panel:after {
            border-right-width: 7px;
            border-left-width: 0;
        }

        &.timeline-inverted>.timeline-panel::before {
            border-right-width: 8px;
            border-left-width: 0;
        }
    }

    .twitter-bs-wizard .twitter-bs-wizard-nav .nav-link::after {
        background-color: variables.$navy;
    }

    .btn-white {
        background-color: variables.$black-bg;
        border: 1px solid variables.$navy;
        color: variables.$theme-title;
    }

    .calendar-events:hover {
        background-color: variables.$navy;
        border: 1px solid variables.$navy;
        color: variables.$theme-title;
    }

    .fc-button {
        color: variables.$theme-title;
        background: variables.$secondary;
        text-shadow: none;
    }

    .sidebar.side-three.horizontal-sidebar .sidebar-menu>ul>li>a {
        span {
            color: variables.$gray-500 !important;
        }

        img,
        svg {
            filter: brightness(0) saturate(100%) invert(65%) sepia(27%) saturate(206%) hue-rotate(165deg) brightness(93%) contrast(88%);
        }
    }

    .cardhead .card-header {
        border-bottom: 1px solid variables.$navy;
    }

    .sidebar-content {
        background: variables.$gray-900;

        .sidebar-header {
            border: 1px solid variables.$navy;

            h5 {
                color: variables.$white;
            }

            a img {
                filter: brightness(0) saturate(100%) invert(65%) sepia(27%) saturate(206%) hue-rotate(165deg) brightness(93%) contrast(88%);
            }
        }

        .switch-wrapper {
            border-bottom: 1px solid variables.$navy;
        }

        .layout-wrap {
            .status-text {
                color: variables.$theme-title;
            }

            .status-toggle .checktoggle {
                background: variables.$theme-title;
            }
        }
    }

    .login-logo.logo-normal {
        display: none;
    }

    .login-logo.logo-white {
        display: block !important;
    }

    .login-wrapper .login-content {
        .login-userheading {

            h3,
            h4 {
                color: variables.$theme-title;
            }
        }

        .form-login {
            label {
                color: variables.$theme-title;
            }
        }

        .signinform {
            h4 {
                color: variables.$theme-title;

                a {
                    color: variables.$theme-title;
                }
            }
        }

        .form-sociallink ul li a {
            color: variables.$theme-title;

            &:hover {
                color: variables.$white;
            }
        }

        .form-login {
            input {
                background: variables.$gray-900 !important;
                color: variables.$theme-title;

                &::placeholder {
                    color: variables.$theme-title;
                }
            }
        }
    }

    .welcome {
        background-color: variables.$black-bg;
        color: variables.$theme-title;
    }

    .table-top-head {
        li {
            a {
                background-color: variables.$black-bg;
                border: 1px solid variables.$navy;

                svg {
                    color: variables.$theme-title;
                }

                &:hover {
                    background: variables.$title-color;
                }
            }
        }
    }

    .sales-cards {
        .card {

            h3,
            p {
                color: variables.$theme-title !important;
            }
        }
    }

    .main-chat-blk {
        .all-chats {
            background: variables.$secondary;
        }

        .chat-member-details .member-details ul li {

            span,
            h5 {
                color: variables.$gray-600;
            }
        }

        .right-sidebar .right-sidebar-wrap {
            background: variables.$black-bg;
        }

        .sidebar-body {
            .profile-name {
                color: variables.$theme-title;
            }
        }

        .chat-page-wrapper {
            .setting-title-head {
                h4 {
                    color: variables.$theme-title;
                }
            }

            .left-chat-title {
                border-bottom-color: variables.$gray-600;
                background: variables.$black-bg;
            }

            .dropdown-menu {
                background: variables.$black-bg;
                border-color: variables.$gray-600;

                .dropdown-item {
                    color: variables.$theme-title;

                    i {
                        color: variables.$theme-title;
                    }
                }
            }
        }

        .top-online-contacts {
            background: variables.$black-bg;
        }

        .sidebar-body {
            background: variables.$black-bg;
        }

        .sidebar {
            .user-list {
                li {
                    a {
                        border-color: variables.$gray-600;
                    }

                    .users-list-body {

                        h5,
                        p {
                            color: variables.$theme-title;
                        }

                        .last-chat-time {
                            .text-muted {
                                color: variables.$sub-title !important;
                            }
                        }
                    }
                }
            }
        }

        .chat {
            background: variables.$black-bg;

            .chat-header {
                background: variables.$black-bg;
                box-shadow: none;

                .user-details {

                    h5,
                    small {
                        color: variables.$theme-title;
                    }
                }

                li {
                    a {
                        i {
                            color: #67748E;
                        }
                    }
                }
            }

            .chat-body .messages .chat-profile-name h6 {
                color: variables.$theme-title;
            }
        }

        .main-wrapper {
            .content .sidebar-group .sidebar {
                border-right-color: variables.$gray-600;
            }
        }
    }

    .part-name.sub-part-name,
    .recession-meet-blk .partispant-blk span a {
        background: variables.$secondary;

        h4 {
            color: variables.$theme-title;
        }
    }

    .more-icon a {
        background: variables.$secondary;
    }

    .recession-meet-blk .reccession-head h5 {
        color: variables.$theme-title;
    }

    .welcome {

        button,
        a {
            border-color: variables.$navy;
            color: variables.$theme-title;
        }
    }

    .bar-code-view {
        border-color: variables.$navy;
    }

    .productdetails {
        >ul {
            >li {
                border-color: variables.$navy;

                h4 {
                    border-color: variables.$navy;
                }

                &:last-child {
                    border-color: variables.$navy;
                }

                &:first-child {
                    border-color: variables.$navy;
                }
            }
        }
    }

    .right-user-side {
        .slimscroll {
            background: variables.$black-bg;
            border-color: variables.$gray-600;

            .left-chat-title {
                background: variables.$secondary;
                border-bottom-color: variables.$gray-600 !important;

                .chat-title h4 {
                    color: variables.$theme-title;
                }
            }

            .chat-footer {
                background: variables.$black-bg;
            }
        }

        &.right-side-party {
            .slimscroll {
                background: variables.$black-bg;
                border-color: variables.$gray-600;

                .left-chat-title {
                    background: variables.$secondary;

                    .chat-title h4 {
                        color: variables.$theme-title;
                    }
                }

                .chat-footer {
                    background: variables.$black-bg;
                }

                .card-body-blk .user-list .user-list-item {
                    background: variables.$secondary;
                }
            }
        }
    }

    .history-modal-profile .right-sidebar-profile {
        background: variables.$black-bg;
    }

    .modal-profile-detail .modal-userlist li {
        color: variables.$theme-title;
    }

    .fc-unthemed td {
        color: variables.$theme-title;
    }

    .notes-dash {
        background: variables.$black-bg;
        border-color: variables.$navy;

        .notes-top-head {
            border-bottom: 1px solid variables.$navy;

            h5 {
                color: variables.$theme-title;
            }
        }

        .content-submenu-tag h6 {
            color: variables.$theme-title;
        }
    }

    .notes-page-wrapper {
        .content {
            .section-bulk-wrap {
                background: variables.$black-bg;
                box-shadow: 0px 4px 60px 0px variables.$white-7;
            }

            .section-card-body {
                .notes-card-details {
                    background: variables.$black-bg;
                    box-shadow: 0px 4px 60px 0px variables.$white-1;
                    border-color: variables.$navy;

                    .notes-card-body {
                        a {
                            color: variables.$text-color;
                        }
                    }

                    .notes-wrap-content {
                        h4 {
                            a {
                                color: variables.$theme-title;
                            }
                        }
                    }
                }

                .dropdown-menu {
                    border-color: variables.$navy;
                }
            }

            .notes-card-body {
                .dropdown-menu {
                    border-color: variables.$navy;
                    box-shadow: unset;
                }
            }

            .section-notes-slider {
                .notes-card {
                    .notes-card-body {
                        a {
                            color: variables.$text-color;
                        }
                    }

                    .notes-slider-content {
                        h4 {
                            a {
                                color: variables.$theme-title;
                            }
                        }
                    }
                }
            }
        }
    }

    .todo-today-content .todo-today-header h3 {
        color: variables.$theme-title;
    }

    .todo-wrapper-list {
        background: variables.$black-bg;
        border-color: variables.$navy;

        .todo-inbox-check {
            .todo-wrapper-list-content {
                h4 {
                    color: variables.$theme-title;
                }
            }
        }
    }

    .dropdown-menu.notes-menu a.dropdown-item {
        color: variables.$gray-500;
    }

    .notes-header h3 {
        color: variables.$theme-title;
    }

    .custom-modal-header {
        background: variables.$secondary;

        .page-title {
            h4 {
                color: variables.$theme-title;
            }
        }
    }

    .search-set .search-input .btn-searchset {
        color: #5B6670;
    }

    .accordion {
        background: transparent;
        --bs-accordion-bg: $card-dark-bg;

        .accordion-item {
            background: variables.$card-dark-bg;
        }
    }

    .accordion-card-one .accordion-item .accordion-button {
        &:not(.collapsed) {
            background: transparent !important;
        }

        .addproduct-icon h5 {
            color: variables.$theme-title;
        }
    }

    .card .card-body.add-product .text-editor.add-list.add .custom-filed {
        background: transparent;
    }

    .bootstrap-tagsinput {
        background: variables.$black-bg;
        border: 1px solid variables.$gray-700;
    }

    .product-quantity {
        background: variables.$black-bg;

        span {
            color: variables.$theme-title;
        }

        input {
            background: variables.$black-bg;
            color: variables.$theme-title;
        }
    }

    .searchinputs.list input {
        background: variables.$black-bg;
    }

    .table-tab ul li button.nav-link {
        color: variables.$theme-title;
        background-color: variables.$card-dark-bg;
    }

    .table-top-head li {
        .status-toggle {
            background: variables.$black-bg;
        }
    }

    .barcode-content-list {
        background: variables.$black-bg;

        .search-modal-header {
            background: variables.$gray-900;
        }

        .paper-search-size {
            .search-toggle-list {
                p {
                    color: variables.$theme-title;
                }
            }
        }
    }

    .barcode-scanner-link {
        background: variables.$black-bg;

        h6 {
            color: variables.$theme-title;
        }
    }

    .seacrh-barcode-item .searchInput {
        background: variables.$black-bg;
    }

    .barcode-scan-header h5 {
        color: variables.$theme-title;
    }

    .modal-body-table {
        background: variables.$gray-900;
    }

    span.customer-toggle,
    .status-label {
        color: variables.$theme-title;
    }

    .layout-drop-item .drop-item-head h5,
    p {
        color: variables.$theme-title;
    }

    .total-employees {
        background: variables.$black-bg;

        h6 {
            color: variables.$theme-title;
        }
    }

    .employee-grid-profile {
        border-color: variables.$gray-700;
        background: variables.$black-bg;

        ul.department {
            background: variables.$gray-900;

            li {
                color: variables.$theme-title;
            }
        }

        .profile-info.department-profile-info {
            background: variables.$gray-900;
        }
    }

    aside {
        border-color: variables.$navy;

        &.product-order-list {
            .block-section {
                border-color: variables.$navy;
            }

            .btn-block {
                border-color: variables.$navy;
            }
        }
    }

    .product-wrap .product-list .action a {
        border-color: variables.$navy;
    }

    .pos-categories,
    aside,
    .pos-products .product-info .img-bg {
        background: variables.$black-bg;
    }

    aside.product-order-list .order-total,
    aside.product-order-list .order-total table {
        background: variables.$gray-900;
    }

    .product-wrap .product-list {
        box-shadow: none;
        background: variables.$gray-900;
    }

    .pos-categories h5,
    .pos-categories h6,
    .order-list h5,
    .order-list h6,
    .pos-products .product-info h6.product-name a,
    .product-wrap .product-list .qty-item .dec,
    .product-wrap .product-list .qty-item .inc,
    aside.product-order-list .payment-method .methods .default-cover a span,
    .product-wrap .product-list .info h6 a,
    .file-manager aside h5,
    .file-manager aside ul li a,
    .file-manager h4,
    .file-manager h6 a,
    .file-manager .accordion .accordion-item .accordion-button::after,
    .fc-toolbar h2,
    .file-manager,
    .file-manager .card,
    .search-dropdown .search-info h6,
    .search-dropdown .search-info h6 svg,
    .search-set .search-input .btn-searchset .feather-search {
        color: variables.$theme-title;
    }

    .file-manager aside .dropdown>a .feather-16,
    .file-manager aside ul li a .feather-16,
    .file-manager .project-plan ul li,
    .file-manager .project-plan label,
    .file-manager .page-header .btn-added .feather-16 {
        color: variables.$white;
    }

    aside.product-order-list .head,
    .file-manager .info,
    .file-manager .folders {
        background: variables.$gray-900;
    }

    .file-manager .folders {
        border-color: variables.$navy;
        background: variables.$card-dark-bg;
    }

    .file-manager .folders,
    .file-manager .info {
        box-shadow: none;
    }

    aside.product-order-list .payment-method .methods .default-cover img {
        filter: invert(75%) sepia(66%) saturate(1955%) hue-rotate(327deg) brightness(103%) contrast(102%);
    }

    .page-wrapper .content.settings-content,
    .sidebars {
        background: variables.$black-bg;
    }

    .sidebars.settings-sidebar {
        .sidebar-menu {
            .submenu-open {
                ul {
                    .submenu {
                        a {
                            span {
                                color: variables.$theme-title;
                            }

                            &.active {
                                background: variables.$secondary;

                                &.subdrop {
                                    background: variables.$secondary;
                                }
                            }

                            svg {
                                color: #67748E;
                            }
                        }
                    }
                }
            }
        }
    }

    .security-settings {
        ul {
            li {
                .security-btn .manage-btn {
                    color: variables.$theme-title;
                }

                .security-type {
                    .security-icon {
                        background: variables.$secondary;
                    }
                }
            }
        }
    }

    .connected-app-card ul {
        background: variables.$black-bg;
        box-shadow: 0px 4px 60px 0px variables.$gray-800;

        li .integration-btn a {
            background: variables.$secondary;
            color: variables.$theme-title;
        }
    }

    .header-left #toggle_btn svg,
    .btn-row .btn svg {
        color: variables.$white;
    }

    .table.profit-table thead,
    .table.profit-table tbody .profit-table-bg td {
        background: variables.$secondary;
        color: variables.$theme-title;
    }

    .comming-soon-pg .coming-soon-box {
        background: variables.$secondary;
        opacity: 0.9;

        .coming-soon-timer li {
            background: variables.$secondary;
            color: variables.$theme-title;

            span {
                color: variables.$theme-title;
            }
        }
    }

    .login-wrapper.bg-img .login-content {
        background: variables.$secondary;
        opacity: 0.9;
    }

    .login-wrapper .login-content .login-logo {
        display: none;
    }

    .pos-modal .modal-header,
    .pos-modal#hold-order h2 {
        background: variables.$black-bg;
        color: variables.$theme-title;
    }

    .pos-modal#orders .order-body .record {
        table {
            background: transparent;
        }
    }

    .tabs_wrapper ul.tabs li {
        background: variables.$secondary;
    }

    .pos-category {
        .default-cover {
            h6 a {
                color: variables.$theme-title;
            }

            span {
                color: #B8BCC9;
            }
        }

        .owl-item {
            background: variables.$secondary;
            box-shadow: none;
            border-color: variables.$navy;

            h6 {
                a {
                    color: variables.$theme-title;
                }
            }
        }
    }

    aside.product-order-list {
        h6 {
            color: variables.$theme-title;
        }

        .head {
            h5 {
                color: variables.$theme-title;
            }
        }

    }

    #print-receipt .modal-body .tax-invoice span,
    #print-receipt .modal-body table thead th,
    #print-receipt .modal-body table tbody tr table tr:last-child td {
        color: variables.$theme-title;
    }

    aside.product-order-list .order-total table tr td {
        border-bottom: none;
    }

    #print-receipt .modal-body table {
        background: none;
    }

    .sidebar-footer {
        background: variables.$black-bg;
    }

    .sidebar-content .theme-head h6 {
        color: variables.$theme-title;
    }

    table tbody td.action-table-data a {
        background: variables.$secondary;
    }

    .disabled>.page-link {
        color: #67748E;
    }

    .apply-leave {
        background: variables.$card-dark-bg;
    }

    .card .card-body .payslip-month .month-of-slip h4 {
        color: variables.$theme-title;
    }

    .paysilp-table-border th,
    .payslip-border-bottom th {
        color: variables.$theme-title;
    }

    .card .card-body .emp-details {
        .emp-name-id {
            h6 {
                color: variables.$theme-title;
            }

            span {
                color: #5B6670;
            }
        }

        .emp-location-info {
            h6 {
                color: variables.$theme-title;
            }

            span {
                color: #5B6670;
            }
        }
    }

    .pay-slip-box .card .card-body .emp-details.justify-content-start {
        background: variables.$black-bg ;
    }

    .card .card-body .emp-details {
        background: variables.$black-bg ;
    }

    .connected-app-card ul li .security-title h5,
    .appearance-settings h6,
    .logo-company li .logo-info h6 {
        color: variables.$theme-title;
    }

    .localization-info .setting-info h6 {
        color: variables.$theme-title;
    }

    .login-wrapper .login-content.user-login .login-userset {
        background: variables.$card-dark-bg;
        border-color: variables.$card-dark-bg;
        box-shadow: unset;
    }

    .custom-alert1 {
        background: #212529;
    }

    .custom-alert-icon {
        background: #212529 !important;
    }

    .accordion-button,
    .accordion .accordion-button.collapsed {
        background: transparent !important;

        &::after {
            background: #212529;
        }
    }

    .accordion-body {
        background: variables.$card-dark-bg;
    }

    .offcanvas {
        background: variables.$card-dark-bg;
        color: variables.$theme-title !important;
    }

    .activity {
        .activity-list {
            li {
                background-color: variables.$black-bg;
                border: 1px solid variables.$navy;

                .activity-content {
                    background-color: variables.$black-bg;
                }

                a {
                    color: variables.$gray-700;

                    &:hover {
                        color: variables.$primary;
                    }

                    &.name {
                        color: variables.$gray-400;

                        &:hover {
                            color: variables.$primary;
                        }
                    }
                }
            }
        }
    }

    .select2-container--default .select2-results__option--highlighted[aria-selected] {
        background: #FF9F43 !important;
        color: #fff !important;
    }

    .profile-pic-upload {
        .profile-pic {
            span {
                color: variables.$theme-title;
            }
        }
    }

    .search-dropdown {
        .search-info {
            border-bottom: 1px solid variables.$gray-600;
        }
    }

    .card {
        .card-body {
            &.add-product {
                .addproduct-icon {
                    border-color: variables.$navy;
                }
            }
        }
    }

    .add-product {
        .add-icon {
            a {
                color: variables.$sub-title;
            }
        }
    }

    .table-list-card {
        .table-responsive {
            border-top: 1px solid variables.$navy;
        }
    }

    .appearance-settings {
        .theme-type-images {
            .theme-image {
                background: variables.$secondary;
                border-color: variables.$secondary;
                box-shadow: 0px 4px 60px 0px variables.$white-1;
            }
        }
    }

    .attendance-widget {
        .card {
            .card-body {
                .card.attendance {
                    background: variables.$black-bg;
                }
            }
        }
    }

    .audio-call-group {
        ul {
            li {
                .user-audio-call {
                    h5 {
                        color: variables.$theme-title;
                    }
                }
            }
        }
    }

    .record-time {
        span {
            color: variables.$theme-title;
        }
    }

    .bank-box {
        &.active {
            &::after {
                background-color: variables.$gray-800;
            }
        }

        .bank-header {
            .bank-name {
                h6 {
                    color: variables.$theme-title;
                }

                p {
                    color: variables.$theme-title;
                }
            }
        }

        .bank-info {
            h6 {
                color: variables.$theme-title;
            }
        }
    }

    .sidebars.settings-sidebar {
        .sidebar-menu {
            .menu-arrow {
                background-color: variables.$theme-title;

                &::before {
                    border-left: 1px solid variables.$black;
                    border-bottom: 1px solid variables.$black;
                    color: variables.$black;
                }
            }
        }
    }

    .legendLabel {
        color: variables.$theme-title;
    }

    .main-chat-blk {
        .chat .chat-body {
            .messages {
                .chats {
                    .message-content {
                        background: variables.$secondary;
                        color: variables.$theme-title;
                    }
                }

                .file-download {
                    background: variables.$secondary;

                    .file-details {
                        span {
                            &.file-name {
                                color: variables.$theme-title;
                            }
                        }

                        ul {
                            li {
                                color: variables.$theme-title;
                            }
                        }
                    }
                }
            }
        }

        .chat_form {
            border: 1px solid variables.$navy;
            box-shadow: 0px 4px 4px variables.$gray-800;
        }

        .chat-date {
            background: variables.$secondary;
            color: variables.$theme-title;
            border: 1px solid variables.$navy;

            &:before {
                background-color: variables.$navy;
            }
        }

        .chat-line {
            &:before {
                background-color: variables.$navy;
            }
        }
    }

    .notes-page-wrapper {
        .content {
            .page-add-notes {
                border-bottom: 1px solid variables.$navy;
            }
        }
    }

    .file-manager {
        aside {
            box-shadow: 0px 4px 60px 0px variables.$white-7;

            h5 {
                border-bottom: 1px solid variables.$navy;
            }
        }
    }

    .seprator-lg {
        border-bottom: 1px solid variables.$navy;
    }

    .pos-modal.upload-modal {
        .modal-body {
            ul {
                li {
                    h6 {
                        a {
                            color: variables.$theme-title;
                        }
                    }
                }
            }

            p {
                color: variables.$theme-title;
            }
        }
    }

    .social-media-icons li a i {
        color: variables.$theme-title;
    }

    .employee-grid-profile .dropdown.profile-action svg {
        color: variables.$theme-title;
    }

    .login-wrapper .login-content .login-userset .my-4 p {
        color: variables.$theme-title;
    }

    .alert-secondary {
        background-color: rgba(9, 44, 76, 0.8);
        color: variables.$white;
        border-color: rgba(9, 44, 76, 0.8);
    }

    .badge {
        &.text-dark {
            color: variables.$white;
        }
    }

    .btn-outline-secondary {
        border: 1px solid variables.$blue !important;
        color: variables.$blue !important;
    }

    .btn-outline-light {
        border: 1px solid variables.$light;
        color: variables.$light !important;
    }

    .btn {
        &.text-dark {
            color: variables.$white !important;
        }
    }

    .callout-info {
        background-color: rgba(23, 162, 184, 0.25);
        border-color: rgba(23, 162, 184, 0.5);
        color: variables.$white;
    }

    .callout-warning {
        background-color: rgba(255, 153, 0, 0.25);
        border-color: rgba(255, 153, 0, 0.5);
        color: variables.$white;
    }

    .callout-danger {
        background-color: rgba(255, 0, 0, 0.25);
        border-color: rgba(255, 0, 0, 0.5);
        color: variables.$white;
    }

    .grid-vertical {
        color: #fff;

        .bg-light {
            background-color: transparent !important;
        }
    }

    .grid-showcase {
        .text-dark {
            color: variables.$white !important;
        }
    }

    .btn.btn-secondary-light {
        background-color: rgba(9, 44, 76, 0.5);
        color: #489be6;
    }

    .nav-tabs {
        .nav-tabs-rounded {
            border-radius: 50px;
        }
    }

    .card {
        .card-footer {
            background-color: transparent;
            color: variables.$theme-title;
        }
    }

    .file-manager .all-files {
        box-shadow: 0px 4px 60px 0px variables.$white-7;
    }

    .form-floating {
        >.form-control-plaintext {
            ~label {
                &::after {
                    background-color: transparent;
                }
            }
        }
    }

    .form-select {
        border-color: variables.$gray-700;
    }

    .productdetails {
        >ul {
            >li h6 {
                color: variables.$white;
            }
        }
    }

    .connected-app-card {
        ul {
            box-shadow: 0px 4px 6px 0px rgba(231, 231, 231, 0.17);
        }
    }

    .login-wrapper {
        .login-content.user-login {
            .login-userset {
                box-shadow: 0px 4px 6px 0px rgba(190, 190, 190, 0.27);
            }
        }
    }

    .sales-cards {
        .card.color-info {
            h3 {
                color: variables.$white !important;
            }

            p {
                color: variables.$white !important;
            }
        }
    }

    .table {
        &.table-borderless {
            tr {
                td .head-text {
                    color: variables.$theme-title;
                }
            }
        }
    }

    .accordion {
        .accordion-item {
            background: transparent;
        }
    }

    .card-text {
        color: variables.$theme-title;
    }

    .login-wrapper.login-new {
        .login-content.user-login {
            .login-userset form .otp-box input {
                background: transparent;
            }
        }
    }

    .file-manager .accordion .accordion-item {
        border-bottom: 1px solid variables.$navy;
    }

    .main-chat-blk .review-files p {
        color: variables.$theme-title;
    }

    .main-chat-blk .chat-action-col>a {
        color: variables.$text-color;
    }

    .social-authent-settings .connected-app-card ul li:first-child {
        border-bottom: 1px solid variables.$navy;
    }

    .notes-page-wrapper {
        .content {
            .section-notes-slider {
                border-bottom: 1px solid variables.$navy;
            }

            .section-card-body {
                .notes-card-details {
                    .notes-wrap-content {
                        border-bottom: 1px solid variables.$navy;
                    }
                }
            }
        }
    }

    .notes-dash .notes-top-head-submenu {
        border-bottom: 1px solid variables.$navy;
    }

    .login-body .login-wrapper .terms li {
        a {
            color: variables.$theme-title;
        }
    }

    .input-blocks .bootstrap-tagsinput .tag {
        background: variables.$card-dark-bg;
        color: variables.$theme-title;
        border-color: variables.$gray-700;
    }

    .company-info {
        border-bottom: 1px solid variables.$navy;
    }

    .apply-leave .leave-apply .leave-date p {
        color: variables.$theme-title;
    }

    .main-chat-blk .chat-page-wrapper .dropdown-menu .dropdown-item:hover {
        color: variables.$title-color;
    }

    .form-floating>.form-control:not(:placeholder-shown)~label::after {
        background-color: transparent;
    }

    .form-select {
        option {
            background-color: transparent;
        }
    }

    .selected-language {
        color: variables.$theme-title
    }

    .file-manager {
        aside {
            box-shadow: 0px 4px 60px 0px variables.$white-7;

            h6 {
                color: variables.$theme-title;
            }
        }
    }

    .table {
        border-color: variables.$navy;

        tr {
            th {
                border-color: variables.$navy;
                color: variables.$theme-title;
            }
        }

        &.table-primary {
            tr {
                th {
                    background-color: variables.$card-dark-bg;
                }
            }
        }
    }

    .pos-category .owl-nav button {

        &.owl-next,
        &.owl-prev {
            background-color: variables.$card-dark-bg;
            border: 1px solid variables.$navy;

            i {
                color: variables.$theme-title;
            }
        }
    }

    .select2-container--default {
        &.select2-container--focus {
            .select2-selection--multiple {
                border-color: variables.$navy;
            }
        }

        .select2-selection--multiple {
            border-color: variables.$navy;
        }
    }

    textarea {
        &::placeholder {
            color: variables.$theme-title;
        }
    }
}