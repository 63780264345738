@use "../utils/mixins";
@use "../utils/variables";

.page-header {
    .page-btn {
        &.import {
            margin-left: 15px;

            @include mixins.respond-below(custom575) {
                margin-left: 0;
            }
        }
    }
}

.summer-description-box.transfer {
    margin-bottom: 0;

    p {
        color: variables.$text-color;
    }
}

.summer-description-box.transfer .note-editor {
    margin-bottom: 10px;
}

.modal-body-table.total-orders {
    margin-bottom: 0;
}

.image-upload .image-uploads h4 span {
    color: variables.$primary;
}

.modal-dialog {
    &.payment {
        min-width: 1439px;
    }
}

.input-blocks {
    &.image-upload-down {
        margin-bottom: 15px;
    }
}

.badges.status-badge.ordered {
    background-color: variables.$warning;
}

.input-blocks.leave-table {
    margin-bottom: 0;
    width: 98px;
}

.page-header .page-btn.attendance {
    margin-left: 0px;
}

.page-header {
    &.transfer {
        @include mixins.respond-below(custom767) {
            display: block;
        }

        .table-top-head {
            @include mixins.respond-below(custom767) {
                justify-content: flex-start;
                margin-top: 24px;
                margin-bottom: 24px;
            }
        }

        .purchase-pg-btn {
            .page-btn {
                &:first-child {
                    @include mixins.respond-below(custom767) {
                        margin-left: 0;
                        margin-top: 0;
                    }
                }
            }

            @include mixins.respond-below(custom575) {
                flex-direction: column;
                align-items: self-start;
            }
        }
    }
}