@use "../utils/mixins";
@use "../utils/variables";

.btn-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
}

.pos-design {
    .btn-row {
        padding: 24px;

        a {
            display: flex;
            align-items: center;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .pos-wrapper {
        --bs-gutter-x: 0;
    }
}

.btn-row {
    @include mixins.respond-below(custom575) {
        flex-direction: column;
    }

    .btn {
        font-size: variables.$font-size-base;
        color: variables.$white;
        @include mixins.margin-padding(0 10px 0 0, 10px);
        @include mixins.rounded(4px);

        @include mixins.respond-below(custom575) {
            margin: 0 0 15px 0;
            width: 100%;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.pos-categories,
.order-list {

    h5,
    h6 {
        color: variables.$secondary;
    }

    h5 {
        font-size: variables.$font-size-18;
        font-weight: variables.$font-weight-bold;
    }
}

.btn-info {
    background-color: variables.$blue;
    border-color: variables.$blue;

    &:hover {
        background-color: variables.$primary;
        border-color: variables.$primary;
    }
}

.btn-primary {
    background-color: variables.$primary;
    border-color: variables.$primary;

    &:hover {
        background-color: variables.$secondary;
        border-color: variables.$secondary;
    }
}

.btn-secondary {
    background-color: variables.$secondary;
    border-color: variables.$secondary;

    &:hover {
        background-color: variables.$primary;
        border-color: variables.$primary;
    }
}

.btn-danger {
    background-color: variables.$danger;
    border-color: variables.$danger;

    &:hover {
        background-color: variables.$secondary;
        border-color: variables.$secondary;
    }
}

.btn-success {
    background-color: variables.$success;
    border-color: variables.$success;

    &:hover {
        background-color: variables.$secondary;
        border-color: variables.$secondary;
    }
}

.owl-carousel {
    .owl-nav {
        margin: 0;
        @include mixins.position(absolute, -60px, 0, null, null);

        button {
            margin-top: 0;
            margin-bottom: 0;

            &.owl-next,
            &.owl-prev {
                position: relative;
                width: 28px;
                height: 28px;
                @include mixins.rounded(28px);

                i {
                    color: variables.$primary;
                }

                &:hover {
                    background-color: variables.$secondary;
                    border-color: variables.$secondary;
                }
            }
        }
    }
}

.pos-categories {
    background-color: variables.$light;
    padding: 24px;
    border-collapse: collapse;
}

.pos-category {
    margin-bottom: 24px;
    //height: 125px;

    .owl-item {
        background-color: variables.$white;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-justify-content: center;
        justify-content: center;
        -ms-flex-pack: center;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        text-align: center;
        cursor: pointer;
        border: 1px solid variables.$gray-100;
        box-shadow: variables.$box-shadow;
        @include mixins.transition(all 0.5s ease);
        @include mixins.rounded(8px);
        margin-left: -0.1px !important;

        li {
            padding: 15px 0;
        }

        a {
            img {
                @include mixins.transition(all 0.5s ease);
                min-width: 40px;
                width: 40px;
                height: 40px;
                margin: 0 auto;
            }
        }

        h6 {
            font-size: variables.$font-size-15;
            font-weight: variables.$font-weight-semibold;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-top: 10px;
            width: 100%;
            margin-bottom: 5px;

            a {
                color: variables.$secondary;
            }
        }

        span {
            color: variables.$text-color;
        }

        &:hover {
            border-color: variables.$primary;
            border-radius: 5px;

            h6 {
                a {
                    color: variables.$primary;
                }
            }

            a {
                img {
                    transform: scale(1.2);
                }
            }
        }
    }

    .owl-nav {
        button {
            &.owl-next {
                margin-left: 5px;
            }

            &.owl-prev {
                margin-right: 5px;
            }

            &.owl-next,
            &.owl-prev {
                background-color: variables.$white;
                border: 1px solid variables.$gray-100;

                i {
                    color: variables.$secondary;
                }

                &:hover {
                    background-color: variables.$primary;
                    border-color: variables.$primary;

                    i {
                        color: variables.$white;
                    }
                }
            }
        }
    }
}

.pos-products {
    .product-info {
        padding: 15px;
        text-align: center;
        justify-content: center;
        color: variables.$sub-title;
        @include mixins.transition(all 0.5s ease);

        .img-bg {
            background-color: variables.$gray-100;
            padding: 14px;
            @include mixins.rounded(10px);
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-justify-content: center;
            justify-content: center;
            -ms-flex-pack: center;
            margin-bottom: 10px;
            position: relative;

            img {
                @include mixins.transition(all 0.5s ease);
            }

            span {
                @include mixins.transition(all 0.5s ease);
                @include mixins.position(absolute, 0, 0, null, null);
                background-color: white;
                border-radius: 100%;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                align-items: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                -webkit-justify-content: center;
                justify-content: center;
                -ms-flex-pack: center;
                display: none;

                svg {
                    color: variables.$success;
                    //@include position(absolute, 1px, null, null, 1px);
                }
            }
        }

        h6 {
            font-size: variables.$font-size-15;
            font-weight: variables.$font-weight-bold;

            &.cat-name {
                a {
                    color: variables.$sub-title;
                }
            }

            &.product-name {
                a {
                    color: variables.$secondary;
                }
            }
        }

        .price {
            margin-top: 17px;

            span {
                color: variables.$purple;
            }

            p {
                color: variables.$primary;
            }

        }

        &:hover,
        &.active {
            border-color: variables.$primary;

            .img-bg {
                img {
                    transform: scale(1.2);
                }

                span {
                    display: block;
                }
            }
        }
    }
}

.product-wrap {
    max-height: 34vh;
    overflow: auto;

    .product-list {
        box-shadow: variables.$box-shadow;
        padding: 0px;
        margin: 0px;

        .product-info {
            //width: 60%;

            .img-bg {
                width: 83px;
                height: auto;
                background-color: variables.$body-bg;
                @include mixins.rounded(10px);
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                align-items: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                -webkit-justify-content: center;
                justify-content: center;
                -ms-flex-pack: center;
            }

        }

        .info {
            span {
                background-color: variables.$primary;
                @include mixins.rounded(3px);
                font-weight: variables.$font-weight-semibold;
                color: variables.$white;
                font-size: variables.$font-size-base;
                padding: 0 10px;
                min-width: 64px;
            }

            h6 {
                font-size: variables.$font-size-base;
                font-weight: variables.$font-weight-bold;
                margin-bottom: 0;

                a {
                    color: variables.$secondary;
                }
            }

            p {
                font-size: variables.$font-size-15;
                font-weight: variables.$font-weight-semibold;
                color: variables.$text-color;
            }
        }

        .qty-item {
            position: relative;
            margin-right: 7px;

            input {
                padding: 1px 9px;
                background: variables.$body-bg;
                @include mixins.rounded(8px);
                height: 28px;
                width: 71px;
                text-align: center;
                font-size: variables.$font-size-base;
            }

            .dec,
            .inc {
                position: absolute;
                top: 50%;
                @include mixins.transform(translateY(-50%));
                color: variables.$secondary;
                @include mixins.transition(all 0.5s ease);

                &:hover {
                    color: variables.$primary;
                }
            }

            .dec {
                left: 9px;
            }

            .inc {
                right: 9px;
            }
        }

        .action {
            a {
                padding: 12px;
                @include mixins.rounded(8px);
                border: 1px solid variables.$gray-100;
                cursor: pointer;

                &.edit-icon {
                    color: variables.$blue;

                    &:hover {
                        background-color: variables.$blue;
                        border-color: variables.$blue;
                        color: variables.$white;
                    }
                }

                &.delete-icon {
                    color: variables.$danger;

                    &:hover {
                        background-color: variables.$danger;
                        border-color: variables.$danger;
                        color: variables.$white;
                    }
                }
            }
        }
    }
}

aside {
    height: 100%;
    padding: 24px;
    background-color: variables.$white;
    border-left: 1px solid variables.$gray-100;

    &.product-order-list {
        h6 {
            font-size: variables.$font-size-16;
            font-weight: variables.$font-weight-semibold;
            color: variables.$title-color;
        }

        .block-section {
            @include mixins.margin-padding(10px 0 10px 0, 0 0 10px 0);
            border-bottom: 1px solid variables.$gray-100;
        }

        .head {
            background-color: variables.$body-bg;
            @include mixins.rounded(8px);
            padding: 10px;

            h5 {
                color: variables.$secondary;
                font-size: variables.$font-size-18;
                font-weight: variables.$font-weight-bold;
            }

            span {
                color: variables.$text-color;
                font-size: variables.$font-size-15;
                font-weight: variables.$font-weight-medium;
            }
        }

        .customer-info {
            .input-block {
                margin-bottom: 10px;

                .btn {
                    min-width: 40px;
                    min-height: 40px;
                    @include mixins.margin-padding(0 0 0 10px, 5px);
                }
            }
        }

        .product-added {
            .head-text {
                margin-bottom: 20px;

                .trash {
                    svg {
                        margin-right: 8px;
                    }
                }
            }

            h6 {
                .count {
                    width: 15px;
                    height: 15px;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    align-items: center;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    -webkit-justify-content: center;
                    justify-content: center;
                    -ms-flex-pack: center;
                    background: variables.$primary;
                    @include mixins.rounded(100%);
                    color: variables.$white;
                    font-size: variables.$font-size-10;
                    font-weight: variables.$font-weight-semibold;
                    @include mixins.margin-padding(0 0 0 7px, null);
                }
            }
        }

        .selling-info {
            margin-bottom: 24px;

            .input-block {
                label {
                    margin-bottom: 5px;
                }

                .select2-container--default .select2-selection--single .select2-selection__rendered {
                    @include mixins.rounded(5px);
                    border-color: variables.$navy;
                }
            }
        }

        .order-total {
            background-color: variables.$gray-100;
            padding: 15px 30px;
            @include mixins.rounded(8px);

            table {
                tr {
                    td {
                        padding: 0 0 10px 0;
                        color: variables.$text-color;
                        font-size: variables.$font-size-15;
                        font-weight: variables.$font-weight-medium;
                        background: transparent;

                        &.danger {
                            color: variables.$danger;
                        }
                    }

                    &:last-child {
                        td {
                            padding: 15px 0 0 0;
                            font-size: variables.$font-size-16;
                            font-weight: variables.$font-weight-semibold;
                        }
                    }
                }
            }

        }

        .payment-method {
            .methods {
                .item {
                    padding: 0 7px;

                    @include mixins.respond-below(custom991) {
                        padding-bottom: 15px;
                    }
                }

                .default-cover {
                    @include mixins.transition(all 0.5s ease);

                    a {
                        box-shadow: variables.$box-shadow-sm;
                        @include mixins.margin-padding(null, 10px 15px);
                        text-align: center;
                        display: block;
                        font-size: variables.$font-size-15;
                        color: variables.$secondary;

                        img {
                            width: 180px;
                            max-height: 50px;
                        }

                        span {
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            display: block;
                        }

                    }

                    &:hover {
                        background-color: variables.$gray-200;
                        border-color: variables.$primary;
                    }

                }

                .default-cover.selected {
                    border: 2px solid variables.$secondary;
                }

            }
        }

        .btn-block {
            @include mixins.margin-padding(20px 0 20px 0, 0 0 20px 0);
            border-bottom: 1px solid variables.$gray-100;

            .btn {
                font-size: variables.$font-size-base;
                font-weight: variables.$font-weight-bold;
                padding: 13px 10px;
            }
        }

        .btn-row {
            padding: 0;

            .btn {
                margin-right: 5px;
                @include mixins.rounded(4px);
                font-size: variables.$font-size-base;

                &.btn-info {
                    background-color: variables.$purple;
                    border-color: variables.$purple;
                }

                &.btn-danger {
                    background-color: variables.$danger;
                    border-color: variables.$danger;
                }

                &.btn-success {
                    background-color: variables.$success;
                    border-color: variables.$success;
                }

                &:hover {
                    background-color: variables.$secondary;
                    border-color: variables.$secondary;
                }
            }
        }
    }
}

#payment-completed {
    .modal-body {
        padding: 40px;

        .icon-head {
            margin-bottom: 10px;

            svg {
                color: variables.$success;
            }
        }

        h4 {
            font-size: variables.$font-size-20;
            font-weight: variables.$font-weight-bold;
        }

        .modal-footer {
            .btn {
                &:hover {
                    color: variables.$white;
                }
            }
        }
    }
}

#print-receipt {
    color: variables.$text-color;
    font-family: 'Consolas';

    @media print {
        @page {
            size: 50mm 150mm;
        }
    }

    .modal-dialog {
        width: 424px;
    }

    .modal-body {
        padding: 30px;
        color: variables.$text-color;

        h6 {
            font-size: variables.$font-size-16;
            font-family: 'Consolas';
        }

        .info {
            h6 {
                margin: 10px 0;
                font-weight: normal;
            }

            a {
                color: variables.$text-color;
            }
        }

        .tax-invoice {
            h6 {
                margin: 10px 0;
                font-weight: variables.$font-weight-semibold;
                position: relative;

                &:after,
                &:before {
                    @include mixins.transform(translateY(-50%));
                    content: "";
                    border-top: 1px dashed variables.$text-color;
                    width: 35%;
                }

                &:before {
                    @include mixins.position(absolute, 50%, null, null, 0);
                }

                &:after {
                    @include mixins.position(absolute, 50%, 0, null, null);
                }
            }

            .invoice-user-name {
                margin-bottom: 10px;
            }

            span {
                color: variables.$secondary;
            }
        }

        table {
            thead {
                th {
                    color: variables.$secondary;
                    width: auto;
                    min-width: auto;
                    padding: 5px;
                    border-top: 1px dashed variables.$text-color;
                    border-bottom: 1px dashed variables.$text-color;
                }
            }

            tbody {

                tr {
                    td {
                        padding: 5px;
                    }

                    table {
                        border-top: 1px dashed variables.$text-color;

                        tr {
                            &:last-child {
                                td {
                                    font-weight: variables.$font-weight-medium;
                                    font-size: variables.$font-size-15;
                                    color: variables.$secondary;
                                }
                            }
                        }
                    }
                }
            }
        }

        .invoice-bar {
            border-top: 1px dashed variables.$text-color;
            @include mixins.margin-padding(null, 20px 0 0 0);
        }

        .btn {
            width: 200px;
        }
    }
}

.pos-modal {
    .modal-header {
        background-color: variables.$body-bg;

        h5 {
            font-size: variables.$font-size-18;
            font-weight: variables.$font-weight-bold;
        }
    }

    &#hold-order {
        h2 {
            font-size: variables.$font-size-36;
            font-weight: variables.$font-weight-bold;
            color: variables.$gray-900;
            margin-bottom: 10px;
            background-color: variables.$body-bg;
            @include mixins.rounded(10px);
        }

        .input-block {
            label {
                margin-bottom: 5px;
            }
        }

        p {
            margin-top: 20px;
        }
    }

    &#recents {
        .table {
            tbody {
                tr {
                    td {
                        padding: 10px 15px;
                    }
                }
            }

        }
    }

    .badge {
        padding: 5px 10px;
        font-size: variables.$font-size-15;
        font-weight: normal;
    }

    &#orders {
        .modal-dialog {
            max-width: 575px;
        }

        .order-body {
            height: 50vh;
            overflow: auto;

            .default-cover {
                border: 1px solid variables.$gray-100;
                box-shadow: variables.$box-shadow;
                @include mixins.rounded(8px);
            }

            .record {
                font-size: variables.$font-size-15;

                td {
                    padding-bottom: 15px;
                }

                tr {
                    &:last-child {
                        td {
                            padding-bottom: 0;
                        }
                    }
                }

                .colon {
                    padding: 0 10px;
                    color: variables.$secondary;
                }

                .text {
                    color: variables.$text-color;
                    white-space: nowrap;
                }
            }

            p {
                font-size: variables.$font-size-15;
                background-color: variables.$body-bg;
                @include mixins.rounded(8px);
            }

            .search-set {
                .search-input {
                    width: 100%;

                    label {
                        width: 100%;

                        input {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    &.upload-modal {
        .modal-body {
            .drag-drop {
                position: relative;
                padding: 20px;
                border: 1px dashed variables.$sub-title;

                input[type="file"] {
                    @include mixins.position(absolute, 0, 0, null, 0);
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    cursor: pointer;
                }
            }

            span {
                font-size: variables.$font-size-13;
                font-weight: variables.$font-weight-medium;
                color: variables.$text-color;
            }

            p {
                font-size: variables.$font-size-16;
                font-weight: variables.$font-weight-semibold;
                color: variables.$secondary;
                margin-bottom: 0;
            }

            .progress {
                height: 6px;
                @include mixins.rounded(5px);
            }

            ul {
                li {
                    @include mixins.margin-padding(0 0 15px 0, 15px);
                    @include mixins.rounded(8px);
                    border: 1px solid variables.$gray-100;

                    h6 {
                        a {
                            font-size: variables.$font-size-16;
                            font-weight: variables.$font-weight-semibold;
                            color: variables.$secondary;

                            svg {
                                color: variables.$success;
                            }
                        }

                        &:hover {
                            a {
                                color: variables.$primary;
                            }
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    &#upload-message {
        .modal-dialog {
            position: fixed;
            bottom: 0px;
            right: 24px;
            margin: 0px;
            left: auto;
            top: calc(100% - 300px);
            width: 600px;
        }

        .progress {
            height: 6px;
            @include mixins.rounded(5px);
        }
    }
}

label span {
    color: variables.$danger;
}

.wordset {
    ul {
        li {
            a {
                width: 38px;
                height: 38px;
                @include mixins.rounded(8px);
                border: 1px solid variables.$gray-100;
                color: variables.$secondary;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

.w-85 {
    width: 85%;
}

.search-order {
    .search-input {
        width: 100%;
    }

    .dataTables_filter {
        label {
            width: 100%;
        }
    }
}

.search-set.search-order .search-input input[type=search] {
    width: 100%;
}

.tabs-sets {
    .wordset {
        li {
            margin-right: 8px;
            margin-left: 0;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}