@use "../utils/variables";

.tooltip {
  @mixin tooltip-color($color) {
    .tooltip-inner {
      background-color: $color;
    }

    &.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before,
    &.bs-tooltip-top .tooltip-arrow::before {
      border-top-color: $color;
    }

    &.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before,
    &.bs-tooltip-end .tooltip-arrow::before {
      border-right-color: $color;
    }

    &.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before,
    &.bs-tooltip-start .tooltip-arrow::before {
      border-left-color: $color;
    }

    &.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before,
    &.bs-tooltip-end .tooltip-arrow::before {
      border-bottom-color: $color;
    }
  }

  &.tooltip-primary {
    @include tooltip-color(variables.$primary);
  }

  &.tooltip-secondary {
    @include tooltip-color(variables.$secondary);
  }

  &.tooltip-warning {
    @include tooltip-color(variables.$warning);
  }

  &.tooltip-info {
    @include tooltip-color(variables.$info);
  }

  &.tooltip-success {
    @include tooltip-color(variables.$success);
  }

  &.tooltip-danger {
    @include tooltip-color(variables.$danger);
  }

  &.tooltip-dark {
    @include tooltip-color(variables.$dark);

    .tooltip-inner {
      color: variables.$white;
    }
  }

  &.tooltip-light {
    @include tooltip-color(variables.$light);

    .tooltip-inner {
      color: variables.$text-color;
    }
  }
}