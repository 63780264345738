@use "../utils/mixins";
@use "../utils/variables";

.page-wrapper-one {
    margin-left: 0px;
    padding-top: 130px;
}

.header-one {
    .header-left {
        border-right: 0 !important;
        @include mixins.margin-padding(null, 25px);
    }
}

.logo-white {
    display: none !important;
}

.sidebar.horizontal-sidebar {
    position: fixed;
    width: 100%;
    height: 68px;
    background: variables.$white;
    border-top: 1px solid variables.$gray-400;
    box-shadow: 0px 4px 14px rgba(198, 198, 198, 0.25);
    z-index: 500;

    .sidebar-menu {
        @include mixins.margin-padding(null, 0 15px);

        &>ul {
            &>li {
                &.submenu {
                    ul {
                        li {
                            a.active {
                                color: variables.$primary;
                            }
                        }
                    }
                }

                margin-bottom: 0;
                margin-right: 20px;

                &>a {
                    &.active {
                        background: transparent;
                    }

                    @include mixins.margin-padding(null, 22px 15px);

                    @include mixins.respond-below(custom1199) {
                        @include mixins.margin-padding(null, 22px 5px);

                        span {
                            @include mixins.respond-below(custom1199) {
                                font-size: variables.$font-size-base;
                            }
                        }
                    }

                    &:hover {
                        background: transparent;

                        span {
                            color: variables.$primary;
                        }

                        img,
                        svg {
                            filter: brightness(0) saturate(100%) invert(75%) sepia(10%) saturate(5676%) hue-rotate(326deg) brightness(105%) contrast(112%);
                        }
                    }

                    img {
                        top: 0 !important;
                        width: auto;
                    }
                }

                a {
                    font-weight: variables.$font-weight-medium;
                    font-size: variables.$font-size-15;
                    color: variables.$gray-600;

                    img {
                        position: relative;
                        top: 3px;
                        width: 18px;
                        margin-right: 7px;
                    }

                    svg {
                        width: 18px;
                        margin-right: 7px;

                        @include mixins.respond-below(custom1199) {
                            margin-right: 5px;
                        }
                    }
                }

                .menu-arrow {
                    position: initial;
                    transform: rotate(90deg);
                    margin-left: 15px;
                }

                ul {
                    position: absolute;
                    width: 250px;
                    height: auto;

                    li {
                        &.submenu {
                            a {
                                padding: 8px 15px !important;

                                .menu-arrow {
                                    float: right;
                                    margin-right: 15px;
                                }

                                i {
                                    margin-right: 7px;
                                }

                                img {
                                    position: relative;
                                    top: 3px;
                                    width: 18px;
                                    margin-right: 7px;
                                }

                                svg {
                                    width: 18px;
                                    margin-right: 7px;
                                }
                            }

                            & ul {
                                box-shadow: 0px 4px 14px rgb(198 198 198 / 25%);
                            }

                            ul {
                                position: relative;
                                @include mixins.margin-padding(null, 0);

                                li {
                                    a {
                                        padding: 8px 10px 8px 40px !important;
                                    }
                                }
                            }

                            &.submenu-two {
                                ul {
                                    li {
                                        a {
                                            margin-left: 10px;
                                        }
                                    }
                                }

                                &.submenu-three {
                                    ul {
                                        li {
                                            a {
                                                margin-left: 20px;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        a {
                            padding: 8px 15px !important;

                            &::after {
                                content: none !important;
                            }
                        }
                    }
                }

                @include mixins.respond-below(custom1199) {
                    margin-right: 0;
                }
            }
        }
    }
}

@include mixins.respond-above(custom992) {
    .hide-sidebar {
        display: none;
    }
}

@include mixins.respond-below(custom991) {
    .horizontal-sidebar {
        display: none;
    }

    .page-wrapper-one {
        padding-top: 60px;
    }
}