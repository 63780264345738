@use "../utils/mixins";
@use "../utils/variables";

@media print {
    @page {
        size: 50mm 150mm;
    }

    #print-receipt-content {
        color: variables.$text-color;
        font-family: 'Consolas';

        .modal-body {
            padding: 30px;
            color: variables.$text-color;

            h6 {
                font-size: variables.$font-size-16;
                font-family: 'Consolas';
            }

            .info {
                h6 {
                    margin: 10px 0;
                    font-weight: normal;
                }

                a {
                    color: variables.$text-color;
                }
            }

            .tax-invoice {
                h6 {
                    margin: 10px 0;
                    font-weight: variables.$font-weight-semibold;
                    position: relative;

                    &:after,
                    &:before {
                        @include mixins.transform(translateY(-50%));
                        content: "";
                        border-top: 1px dashed variables.$text-color;
                        width: 35%;
                    }

                    &:before {
                        @include mixins.position(absolute, 50%, null, null, 0);
                    }

                    &:after {
                        @include mixins.position(absolute, 50%, 0, null, null);
                    }
                }

                .invoice-user-name {
                    margin-bottom: 10px;
                }

                span {
                    color: variables.$secondary;
                }
            }

            table {
                thead {
                    th {
                        color: variables.$secondary;
                        width: auto;
                        min-width: auto;
                        padding: 5px;
                        border-top: 1px dashed variables.$text-color;
                        border-bottom: 1px dashed variables.$text-color;
                    }
                }

                tbody {

                    tr {
                        td {
                            padding: 5px;
                        }

                        table {
                            border-top: 1px dashed variables.$text-color;

                            tr {
                                &:last-child {
                                    td {
                                        font-weight: variables.$font-weight-medium;
                                        font-size: variables.$font-size-15;
                                        color: variables.$secondary;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .invoice-bar {
                border-top: 1px dashed variables.$text-color;
                @include mixins.margin-padding(null, 20px 0 0 0);
            }

            .btn {
                width: 200px;
            }
        }
    }
}