@use "../utils/mixins";
@use "../utils/variables";

.table-tab {
    ul {
        margin-bottom: 24px;

        li {
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }

            button.nav-link {
                font-weight: variables.$font-weight-bold;
                padding: 10px;
                line-height: normal;
                color: variables.$secondary;
                background-color: variables.$gray-400;

                &.active {
                    background-color: variables.$primary;
                    color: variables.$white;
                }
            }
        }
    }

    .nav-pills {
        @include mixins.respond-below(custom575) {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            justify-content: center;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
        }
    }
}

.badge {
    &.badge-bgdanger {
        background-color: rgba(255, 0, 0, 0.06);
        color: variables.$danger;
        padding: 5px 10px;
    }
}

button.swal2-styled.swal2-confirm {
    background-color: variables.$primary;

    &:hover {
        background-color: variables.$secondary
    }
}

table {
    tbody {
        td {
            .action-table-data {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                padding: 0 !important;
                -webkit-justify-content: center;
                justify-content: center;
                -ms-flex-pack: center;

                a {
                    height: 31px;
                    width: 31px;
                    border: 1px solid rgba(145, 158, 171, 0.30);
                    background-color: variables.$white;
                    border-radius: 8px;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    align-items: center;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    -webkit-justify-content: center;
                    justify-content: center;
                    -ms-flex-pack: center;
                    padding: 8px;

                    .feather-edit {
                        color: variables.$blue;
                        width: 14px;
                        height: 14px;
                    }

                    .action-edit {
                        color: variables.$text-color;
                        width: 14px;
                        height: 14px;
                    }

                    .feather-trash-2 {
                        color: variables.$danger;
                        width: 14px;
                        height: 14px;
                    }

                    .feather-shield {
                        color: variables.$secondary;
                        width: 14px;
                        height: 14px;
                    }

                    .feather-check-circle {
                        color: #008000;
                        width: 14px;
                        height: 14px;
                    }

                    .feather-x-circle {
                        color: variables.$danger;
                        width: 14px;
                        height: 14px;
                    }

                    .feather-eye {
                        width: 14px;
                        height: 14px;
                    }

                    .feather-truck {
                        color: variables.$purple;
                        width: 14px;
                        height: 14px;
                    }

                    .feather-send {
                        color: variables.$orange;
                        width: 14px;
                        height: 14px;
                    }

                    .feather-dark {
                        color: variables.$dark;
                        width: 14px;
                        height: 14px;
                    }

                    .action-download {
                        color: variables.$blue;
                        width: 14px;
                        height: 14px;

                    }

                    .action-eye {
                        color: variables.$secondary;
                        width: 14px;
                        height: 14px;
                    }
                }

            }
        }

        .productimgname {
            min-width: 200px;

            a {
                &.stock-img.product-img {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    align-items: center;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    -webkit-justify-content: center;
                    justify-content: center;
                    -ms-flex-pack: center;
                    margin-right: 8px;

                    img {
                        width: 50px;
                        height: 50px;
                        max-width: 100%;
                        margin-right: 0;
                    }
                }
            }
        }
    }

    .edit-delete-action {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-justify-content: center;
        justify-content: center;
        -ms-flex-pack: center;
        height: 50px;

        a {
            padding: 0;
        }

    }

    .edit-delete-action.data-view {
        min-height: 52px;
    }

    .edit-delete-action.data-row {
        height: auto;
    }

    .edit-delete-action.data {
        height: 44px;
    }
}

.table-top-head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;

    &.low-stock-top-head {
        @include mixins.respond-below(custom575) {
            flex-wrap: wrap;
            justify-content: start;
        }

        li {
            margin-bottom: 10px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    li {
        margin-right: 10px;
        flex-shrink: 0;

        &:last-child {
            margin-right: 0;
        }

        #collapse-header {
            margin-right: 0;

            @include mixins.respond-below(custom991) {
                display: none;
            }
        }

        a {
            height: 38px;
            width: 38px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-justify-content: center;
            justify-content: center;
            -ms-flex-pack: center;
            border: 1px solid variables.$gray-300;
            background: variables.$white;
            border-radius: 8px;
            padding: 6px;
            font-size: 18px;

            svg {
                height: 20px;
            }

            &:hover {
                background: #e3e3e3;
            }

            label {
                margin-right: 10px;
            }

            &.btn-secondary {
                background-color: variables.$secondary;
                color: variables.$white;
                padding: 10px;
                font-weight: variables.$font-weight-bold;
                font-size: variables.$font-size-base;

                &:hover {
                    background-color: variables.$primary;
                }

                svg {
                    width: 16px;
                    margin-right: 5px;
                    color: variables.$white;
                }

                @include mixins.respond-below(custom767) {
                    padding: 5px;
                    font-size: variables.$font-size-base;
                }
            }

            .action-edit.sales-action {
                color: variables.$blue;
            }
        }

        .status-toggle {
            height: 38px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-justify-content: center;
            justify-content: center;
            -ms-flex-pack: center;
            border: 1px solid variables.$gray-100;
            background: variables.$white;
            border-radius: 8px;
            padding: 6px 10px;

            label {
                margin-right: 10px;
            }
        }
    }
}

.custom-modal-header {
    background: variables.$body-bg;
    padding: 24px;

    .page-title {
        h4 {
            font-size: variables.$font-size-18;
            font-weight: variables.$font-weight-bold;
            color: variables.$secondary;
        }
    }
}

.custom-modal-body {
    padding: 24px;

    .input-blocks {
        margin-right: 0;
    }

    label {
        font-size: variables.$font-size-15;
        font-weight: variables.$font-weight-normal;
    }
}

.status-label {
    color: #212B36;
}

.modal-footer-btn {
    margin-top: 40px;
    text-align: right;

    a {
        min-width: auto;

        @include mixins.respond-below(custom575) {
            padding: 10px 10px;
        }
    }
}

.status-toggle.modal-status label {
    height: 15px;
    width: 32px;
    background-color: #B9B9C3;
    margin-bottom: 0;
}

.status-toggle.modal-status label::after {
    top: 7px;
}

td {
    p {
        &.description-para {
            max-width: 400px;
            white-space: break-spaces;
        }
    }
}

.form-sort {
    .select2-container--default .select2-selection--single .select2-selection__rendered {
        padding-left: 35px;
    }

    span.select2-container.select2-container--default.select2-container--open {
        z-index: 99;
    }
}

#filter_inputs {
    .select2-container--default .select2-selection--single .select2-selection__rendered {
        padding-left: 35px;
    }

    input {
        padding-left: 35px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    span.select2-container.select2-container--default.select2-container--open {
        z-index: 98;
    }
}

#filter_inputs1 {
    .select2-container--default .select2-selection--single .select2-selection__rendered {
        padding-left: 35px;
    }

    input {
        padding-left: 35px;
    }

    span.select2-container.select2-container--default.select2-container--open {
        z-index: 98;
    }
}

#filter_inputs2 {
    .select2-container--default .select2-selection--single .select2-selection__rendered {
        padding-left: 35px;
    }

    input {
        padding-left: 35px;
    }

    span.select2-container.select2-container--default.select2-container--open {
        z-index: 98;
    }
}

.summer-description-box {
    .card .card-header {
        padding: 8px;
        border-bottom: 1px solid rgba(145, 158, 171, 0.32);
    }

    .note-editor {
        border: 1px solid rgba(145, 158, 171, 0.32);
    }

    .note-editable {
        height: 150px !important;
    }

    .note-resizebar {
        display: none;
    }
}

.table tbody {
    td {
        a {
            &.view-note {
                color: variables.$blue;
                font-weight: variables.$font-weight-bold;
            }
        }
    }
}

.userimgname {
    .product-img {
        display: inherit;
        width: 35px;
        height: 35px;
        margin-right: 8px;
    }
}

.modal-body-table {
    background-color: #F6F7F8;
    padding: 24px;
    margin-bottom: 20px;
    border-radius: 8px;
}

.product-quantity {
    border: 1px solid variables.$gray-400;
    background-color: variables.$body-bg;
    width: 105px;
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    border-radius: 8px;

    input {
        width: 35px;
        border: 0;
        background-color: variables.$body-bg;
        text-align: center;
        height: 35px !important;
    }

    span {
        color: variables.$secondary;
        font-size: 0;
        cursor: pointer;

        .plus-circle-qty {
            width: 20px;
            height: 20px;
        }

        .feather-search-qty {
            width: 20px;
            height: 20px;
        }
    }
}

.modal-dialog.stock-adjust-modal {
    max-width: 800px;

    .dataTables_length,
    .dataTables_info,
    .dataTables_paginate {
        display: none;
    }
}

.input-blocks.search-form {
    input {
        padding-left: 30px;
    }

    svg {
        position: absolute;
        left: 10px;
        top: 37px;
        width: 15px;
    }
}

.badge badge-linedanger {
    background-color: rgba(255, 0, 0, 0.06);
    color: variables.$danger;
    border-radius: 8px;
    padding: 4px 10px;
    font-size: variables.$font-size-base;
}

.input-groupicon.calender-input input {
    padding-left: 40px;
    padding-top: 12px;
}

span {
    &.customer-toggle {
        color: variables.$secondary;
    }
}

span {
    &.unlimited-text {
        color: variables.$text-color;
        margin-top: 5px;
        display: inline-block;
    }
}

.modal-title-head {
    h6 {
        font-size: variables.$font-size-16;
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        svg {
            width: 15px;
            color: variables.$primary;
            margin-right: 5px;
        }
    }
}

#phone,
#phone2,
#phone3 {
    padding-left: 50px;
}

.total-employees {
    background-color: variables.$body-bg;
    padding: 10px 8px;
    margin-right: 24px;
    border-radius: 8px;

    h6 {
        font-size: variables.$font-size-base;
        font-weight: variables.$font-weight-bold;
        display: flex;
        align-items: center;

        svg {
            width: 15px;
            margin-right: 5px;
        }

        span {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-justify-content: center;
            justify-content: center;
            -ms-flex-pack: center;
            font-size: variables.$font-size-10;
            width: 18px;
            height: 18px;
            background-color: variables.$danger;
            color: variables.$white;
            border-radius: 50%;
            margin-left: 5px;
        }
    }
}

.search-path.search-path-new {

    .btn-list,
    .btn-grid {
        width: 36px;
        height: 36px;
        background-color: variables.$body-bg;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-justify-content: center;
        justify-content: center;
        -ms-flex-pack: center;
        color: variables.$secondary;
        margin-right: 15px;
        border-radius: 8px;

        &.active {
            background-color: variables.$primary;
            color: variables.$white;

            &:hover {
                background-color: variables.$secondary;
                color: variables.$white;
            }
        }

        svg {
            width: 18px;
            height: 18px;
        }

        &:hover {
            background-color: variables.$primary;
            color: variables.$white;
        }
    }

    .form-sort {
        @include mixins.respond-below(custom575) {
            margin-top: 0;
        }
    }
}

.layout-box {
    width: 36px;
    height: 36px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    background: variables.$primary;
    color: variables.$white;
    border-radius: 8px;

    &:hover {
        background-color: variables.$secondary;
        color: variables.$white;
    }
}

td {
    span {
        &.emp-team {
            display: block;
            line-height: normal;
        }
    }
}

.badges-inactive {
    border: 1px solid variables.$danger;
    color: variables.$danger;
    padding: 2px 10px;
    font-size: variables.$font-size-14;
    font-weight: variables.$font-weight-semibold;
    border-radius: 8px;
}

.success-email-send.modal-body {
    padding: 24px;

    span {
        svg {
            width: 40px;
            height: 40px;
            color: variables.$success;
            margin-bottom: 20px;
        }
    }

    h4 {
        font-size: variables.$font-size-20;
        font-weight: variables.$font-weight-bold;
        margin-bottom: 5px;
    }

    p {
        font-size: variables.$font-size-15;
    }

    a.btn {
        padding: 10px 20px;
    }
}

.layout-drop-item {
    width: 350px;
    max-height: 400px;
    overflow-y: scroll;
    padding: 20px;
    box-shadow: 0px 4px 60px 0px rgba(231, 231, 231, 0.47);
    background: variables.$white;
    border-radius: 8px;
    position: absolute;
    right: 0;
    margin-top: 10px;
    z-index: 101;
    display: none;

    .drop-item-head {
        border-bottom: 1px solid rgba(145, 158, 171, 0.30);
        @include mixins.margin-padding(0 0 20px, 0 0 20px);

        h5 {
            font-size: variables.$font-size-16;
            font-weight: variables.$font-weight-semibold;
            margin-bottom: 10px;
        }

        p {
            font-size: variables.$font-size-base;
        }
    }

    ul {
        li {
            margin-bottom: 20px;

            span {
                display: flex;
                align-items: center;

                svg {
                    width: 16px;
                    margin-right: 5px;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.layout-hide-box {
    position: relative;
}

.layout-show-box {
    .layout-drop-item {
        display: block;
    }
}

.table-top {
    &.table-top-new {
        @include mixins.respond-below(custom767) {
            flex-direction: column;
            align-items: self-start;
        }

        .search-path {
            margin-left: 0;

            @include mixins.respond-below(custom767) {
                margin-top: 24px;
            }

            @include mixins.respond-below(custom575) {
                flex-direction: column;
                align-items: self-start;

            }

            .form-sort {
                @include mixins.respond-below(custom575) {
                    margin-top: 20px;
                }
            }
        }

        .search-set {
            @include mixins.respond-below(custom767) {
                flex-direction: column;
                align-items: flex-start;

                .total-employees {
                    margin: 0 0 20px 0;
                }
            }
        }
    }
}

.badge-applied {
    background-color: variables.$purple;
    padding: 2px 10px;
    color: variables.$white;
    border-radius: 5px;
}

.badge-approved {
    background-color: variables.$success;
    padding: 2px 10px;
    color: variables.$white;
    border-radius: 5px;
}

.badge-reject {
    background-color: variables.$danger;
    padding: 2px 10px;
    color: variables.$white;
    border-radius: 5px;
}

.table tbody tr td a.reason-for-reject {
    color: variables.$blue;
    font-weight: variables.$font-weight-semibold;
}

.note-editor.note-frame.card {
    margin-bottom: 0;
}

table tbody td.action-table-data a.cancel-circle {
    border: 0;
    padding: 0;

    svg {
        color: variables.$primary;
    }

    &:hover {
        background-color: transparent;

        svg {
            color: variables.$danger !important;
        }
    }
}

.apply-leave {
    background-color: variables.$body-bg;
    border-radius: 8px;

    .leave-apply {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        padding: 10px;

        @include mixins.respond-below(custom575) {
            flex-direction: column;
            align-items: flex-start;
        }

        .leave-date {
            margin-right: 70px;

            span {
                color: variables.$text-color;
            }

            p {
                color: variables.$secondary;
            }

            @include mixins.respond-below(custom575) {
                margin-right: 0;
                margin-bottom: 20px;
            }
        }

        .leave-time {
            width: 175px;
        }
    }
}

.input-blocks {
    .bootstrap-tagsinput {
        width: 100%;
        padding: 0 .75rem;
        box-shadow: none;
        line-height: 40px;

        .tag {
            background-color: variables.$gray-300;
            color: variables.$secondary;
            padding: 3px 10px;
            border-radius: 4px;
            position: relative;
            z-index: 1;

            .remove {
                &::after {
                    color: variables.$text-color;
                }
            }
        }

        input {
            position: static;
            width: auto;
            border: none;
        }
    }

    span.tag-text {
        color: variables.$text-color;
        font-size: variables.$font-size-13;
    }
}

.table tbody tr td .sales-dropdown-list a {
    padding: 10px 20px;
}

.no-pagination {
    .pagination {
        display: none;
    }
}

#collapse-header.active {
    background-color: variables.$primary;
    border-color: unset;

    svg {
        color: variables.$white;
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
    }

    &:hover {
        svg {
            color: variables.$white;
        }
    }
}

.edit-delete-action {
    a {
        &:hover {
            background-color: variables.$primary;
            border-color: variables.$primary;

            svg {
                color: variables.$white !important;
            }
        }

        &.confirm-text {
            &:hover {
                background-color: variables.$danger;
                border-color: variables.$danger;
            }
        }

        &.edit-icon {
            &:hover {
                background-color: variables.$secondary;
                border-color: variables.$secondary;
            }
        }
    }
}

.modal {
    table.table {
        thead>tr>th.sorting:before {
            display: none !important;
        }

        thead>tr>th.sorting:after {
            display: none !important;
        }
    }
}

.profit-head {
    .input-blocks {
        .info-img {
            color: variables.$secondary;
        }

        input {
            padding-left: 35px;
        }
    }
}

.profile-pic-upload .profile-pic.brand-pic {
    width: 110px;
    height: 105px;
    position: relative;

    .remove-photo {
        position: absolute;
        right: 8px;
        top: 10px;
        background: variables.$danger;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18px;
        height: 18px;
        border-radius: 4px;

        svg {
            color: variables.$white;
            width: 14px;
            height: 14px;
        }
    }
}

.sales-details-items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    margin-bottom: 20px;

    @include mixins.respond-below(custom767) {
        flex-direction: column;
        align-items: start;
    }

    .details-item {
        @include mixins.respond-below(custom767) {
            margin-bottom: 20px;
        }

        h6 {
            margin-bottom: 15px;
            font-size: variables.$font-size-15;
            color: variables.$secondary;
        }

        p {
            font-size: variables.$font-size-base;
            color: variables.$text-color;
        }

        h5 {
            color: variables.$success;
            font-size: variables.$font-size-15;

            span {
                display: block;
                color: variables.$text-color;
            }
        }
    }
}

.order-text {
    color: variables.$secondary;
    font-weight: variables.$font-weight-semibold;
    font-size: variables.$font-size-base;
    margin-bottom: 15px;
}

.profile-head-action {
    .badge-linesuccess {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        padding: 3px 10px;
        height: 24px;
    }

    .badge-linedanger {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        padding: 3px 10px;
        height: 24px;
    }
}