@use "../utils/mixins";
@use "../utils/variables";

.profile-set {

    .profile-head {
        height: 70px;
        background-image: url(../../../../public/assets/img/profiles/profile-banner.jpg);
        background-position: center;
        background-size: cover;
        @include mixins.rounded(0 10px 0 0);

        @include mixins.respond-below(custom991) {
            @include mixins.rounded(10px);
        }
    }

    .profile-top {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        -ms-flex-pack: space-between;

        @include mixins.respond-below(custom767) {
            flex-direction: column;
        }

        .profile-content {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;

            @include mixins.respond-below(custom767) {
                flex-direction: column;
            }
        }

        .profile-contentimg {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-justify-content: space-between;
            justify-content: space-between;
            -ms-flex-pack: space-between;
            margin-top: -40px;
            width: 130px;
            position: relative;
            border: 10px solid variables.$white;
            @include mixins.rounded(50%);
            box-shadow: 0px 4px 4px 0px #00000040;

            img {
                @include mixins.rounded(50px);
            }
        }

        .profileupload {
            input[type="file"] {
                opacity: 0;
                width: 34px;
                height: 34px;
                display: block;
                z-index: 9;
                cursor: pointer;
                @include mixins.position(absolute, null, null, 10px, 42px);
            }

            a {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                align-items: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                -webkit-justify-content: center;
                justify-content: center;
                -ms-flex-pack: center;
                background: variables.$primary;
                width: 34px;
                height: 34px;
                z-index: 1;
                @include mixins.rounded(50px);
                @include mixins.position(absolute, null, null, -5px, 71px);
            }
        }

        .profile-contentname {
            padding-left: 20px;

            @include mixins.respond-below(custom767) {
                text-align: center;
                @include mixins.margin-padding(null, 20px 0 20px 0);
            }

            h2 {
                font-size: variables.$font-size-18;
                color: variables.$secondary;
                font-weight: variables.$font-weight-bold;
            }

            h4 {
                color: #777;
                font-size: variables.$font-size-base;
            }
        }
    }
}

.pass-group {
    position: relative;

    .toggle-password,
    .toggle-passworda {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-justify-content: center;
        justify-content: center;
        -ms-flex-pack: center;
        transform: translateY(-50%);
        cursor: pointer;
        width: 30px;
        height: 30px;
        color: variables.$gray-600;
        font-size: variables.$font-size-base;
        @include mixins.rounded(50%);
        @include mixins.position(absolute, 50%, 10px, null, null);
    }
}