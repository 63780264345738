@use "../utils/mixins";
@use "../utils/variables";

.text-success {
    color: variables.$success !important;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
    background: variables.$primary;
}

.font-14 {
    font-size: variables.$font-size-base;
}

.text-blue {
    color: variables.$blue;
}

.welcome {
    @include mixins.margin-padding(0 0 24px 0, 15px 10px);
    background: variables.$white;
    @include mixins.rounded(8px);
    box-shadow: variables.$box-shadow;
    color: variables.$title-color;

    .welcome-text {
        @include mixins.respond-below(custom1199) {
            flex-wrap: wrap;
        }

        @include mixins.respond-below(custom991) {
            margin-bottom: 15px;
        }

        @include mixins.respond-below(custom767) {
            flex-wrap: wrap;
        }

        h3 {
            font-weight: variables.$font-weight-bold;

            @include mixins.respond-below(custom767) {
                margin-bottom: 10px;
            }
        }

        h6 {
            font-weight: variables.$font-weight-semibold;
            font-size: variables.$font-size-16;
            color: variables.$text-color;
        }
    }

    input {
        min-width: 240px;
    }

    .daterange-wraper {
        svg {
            @include mixins.position(absolute, 50%, null, null, 20px);
            @include mixins.transform(translateY(-50%));
        }

        input {
            @include mixins.margin-padding(0 0 0 10px, 5px 10px 5px 27px);

            @include mixins.respond-below(custom767) {
                margin-left: 0;
                padding-left: 37px;
            }
        }

        &:hover {
            svg {
                color: variables.$white;
            }
        }
    }

    a,
    input,
    button {
        @include mixins.rounded(8px);
        border: 1px solid variables.$gray-100;
        display: inline-block;
        @include mixins.margin-padding(0 0 0 10px, 5px 10px);
        color: variables.$secondary;
        height: 38px;
        @include mixins.transition(all 0.5s ease);

        &:hover {
            border-color: variables.$primary;
            background: variables.$primary;
            color: variables.$white;

            svg {
                color: variables.$white;
            }
        }

        #collapse-header {
            @include mixins.transition(all 0.5s ease);

            svg {
                @include mixins.transition(all 0.5s ease);
            }

            @include mixins.respond-below(custom991) {
                display: none;
            }
        }
    }

}

.sales-cards {
    .card {
        @include mixins.margin-padding(null, 20px);
        @include mixins.rounded(8px);
        flex-direction: initial;

        h6 {
            color: variables.$primary;
            font-size: variables.$font-size-16;
            font-weight: variables.$font-weight-semibold;
            margin-bottom: 20px;
        }

        h3 {
            color: variables.$secondary;
            font-weight: variables.$font-weight-bold;
            margin-bottom: 8px;
        }

        p {
            color: variables.$text-color;
        }

        &.color-info {
            display: block;
            position: relative;
            color: variables.$white;
            border: none;

            svg {
                @include mixins.position(absolute, 15px, 15px, null, null);
                cursor: pointer;
            }

            h3 {
                margin-bottom: 0;
            }

            h3,
            p {
                color: variables.$white;
            }

            p {
                font-size: variables.$font-size-15;
                margin-bottom: 0;
            }
        }
    }
}

.table {
    tbody {
        tr {
            td {
                .product-info {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    align-items: center;
                    -webkit-box-align: center;
                    -ms-flex-align: center;

                    .product-img {
                        display: -webkit-inline-box;
                        display: -ms-inline-flexbox;
                        display: inline-flex;
                        align-items: center;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        -webkit-justify-content: center;
                        justify-content: center;
                        -ms-flex-pack: center;
                        margin: 0 8px 0 0;

                        img {
                            width: 50px;
                            height: 50px;
                        }
                    }
                }

                &.productimgname {
                    .product-img {
                        img {
                            width: 50px;
                            height: 50px;
                        }
                    }
                }
            }
        }
    }

    &.dashboard-recent-products {
        tr {
            td {
                padding: 10px;
            }

            &:last-child {
                td {
                    border-bottom: none;
                    padding-bottom: 0;
                }
            }
        }
    }

    &.table-borderless {
        tr {
            td {
                font-size: variables.$font-size-15;
                border-bottom: none;

                .head-text {
                    color: variables.$secondary;
                }

                .dull-text {
                    color: variables.$text-color;
                }
            }
        }
    }

    &.best-seller {
        tr {
            td {
                padding-bottom: 17px;
            }
        }
    }

    &.recent-transactions {
        thead {
            border-bottom: none;
        }

        tr {
            td {
                padding: 15px;

                span {
                    font-size: variables.$font-size-base;

                    svg {
                        margin-right: 5px;
                    }
                }
            }
        }
    }
}

.dropdown-wraper {
    .btn-white {
        min-width: auto;

        svg {
            margin-right: 4px;
        }

        &:hover,
        &:active,
        &.show {
            background: variables.$primary;
            border-color: variables.$primary;
            color: variables.$white;
        }
    }
}

#sales_db_world_map {

    .jvectormap-zoomin,
    .jvectormap-zoomout {
        display: none;
    }

    .jvectormap-tip {
        background: variables.$white;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border: none;
        text-align: center;
    }
}

.sales-range {
    .text-success {
        font-weight: variables.$font-weight-bold;
    }
}