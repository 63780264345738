@use "../utils/mixins";
@use "../utils/variables";

.nav-item {
  margin-inline-end: 0.5rem;

  &:last-child {
    margin-inline-end: 0;
  }
}

.nav-link {
  color: variables.$text-color;
  border-radius: variables.$border-radius;
  font-weight: variables.$font-weight-semibold;

  &.active {
    background-color: rgba(variables.$primary, 0.1);
    color: variables.$primary;
  }
}

.navbar-brand {
  color: variables.$text-color;

  &:focus,
  &:hover {
    color: variables.$text-color;
    opacity: 0.9;
  }
}

.navbar-nav .nav-link.active,
.navbar-nav .show>.nav-link {
  color: variables.$primary;
}

.nav-link.disabled {
  color: variables.$text-color;
  opacity: 0.3;
}

.nav-tabs .nav-link.disabled,
.nav-tabs .nav-link:disabled {
  color: rgba(variables.$dark, 0.5);
}

.navbar-text {
  color: variables.$text-color;
}

.navbar-dark {
  &.bg-dark {

    .nav-link,
    .navbar-brand {
      color: variables.$white;
    }
  }

  .navbar-nav .nav-link.active,
  .navbar-nav .show>.nav-link {
    color: variables.$white;
  }
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: variables.$white;
  background-color: variables.$primary;
}

.nav.nav-style-1 {
  border: 0;

  .nav-item {
    margin-inline-end: 0.25rem;

    &:last-child {
      margin-inline-end: 0;
    }
  }

  .nav-link {
    &.active {
      background-color: rgba(variables.$primary, 0.1);
      color: variables.$primary;

      &:hover {
        background: rgba(variables.$primary, 0.1);
      }
    }

    &:hover {
      background: transparent;
      color: variables.$primary;
    }
  }
}

.nav.nav-style-2 {
  .nav-item .nav-link {
    border-block-start: 3px solid transparent;

    &.active {
      background-color: rgba(variables.$primary, 0.1);
      border-block-start: 3px solid variables.$primary;
      color: variables.$primary;
    }
  }
}

.nav.nav-style-3 {
  .nav-link {
    border-radius: 0;
  }

  .nav-link {
    border-block-end: 3px solid transparent;
  }

  .nav-link.active {
    background-color: transparent;
    border-block-end: 3px solid variables.$primary;
    color: variables.$primary;
  }
}

.nav.nav-style-4 {
  border: 0;

  .nav-link {
    color: variables.$text-muted;
    border: 0;
  }

  .nav-link {
    margin-bottom: 3px;

    &.active {
      background-color: variables.$light;
      color: variables.$text-color;
      border: 0;
    }

    &:hover,
    &:focus {
      border: 0;
    }

    &:hover {
      background-color: variables.$light;
      color: variables.$text-color;
    }
  }
}

.nav-tabs.nav-style-5 {
  border: 0;

  .nav-link {
    color: variables.$text-muted;
    border: 0;
    margin-bottom: 3px;
  }

  .nav-link {
    &.active {
      background-color: variables.$primary;
      color: variables.$white;
      border: 0;
    }

    &:hover,
    &:focus {
      border: 0;
      background-color: variables.$primary;
      color: variables.$white;
    }
  }
}

.nav-style-6 {
  border: 0;

  &.nav-pills .nav-link {
    &.active {
      border: 1px solid variables.$primary;
      border-block-end: 2px solid variables.$primary;
      background-color: transparent;
      color: variables.$primary;
    }
  }
}

.nav-tabs-header {
  border: 0 !important;

  .nav-item {
    margin-inline-end: 0.5rem;

    &:last-child {
      margin-inline-end: 0;
    }

    .nav-link {
      border: 1px solid transparent;
      font-size: 0.8rem;
      padding: 0.4rem 0.5rem;
      font-weight: variables.$font-weight-medium;

      &.active {
        background: rgba(variables.$primary, 0.1);
        color: variables.$primary;
        border: 1px solid transparent;
      }

      &:hover,
      &:focus {
        border: 1px solid transparent;
        background-color: transparent;
        color: variables.$primary;
      }
    }
  }
}

.nav-tabs-footer {
  border: 0;

  .nav-item {
    margin-inline-end: 0.5rem;

    &:last-child {
      margin-inline-end: 0;
    }

    .nav-link {
      background-color: transparent;
      color: variables.$primary;
      border: 1px solid variables.$primary;
      font-size: 0.8rem;

      &.active {
        background: variables.$primary;
        color: variables.$white;
        border: 1px solid variables.$primary;
      }

      &:hover,
      &:focus {
        border: 1px solid variables.$primary;
        background-color: transparent;
        color: variables.$primary;
      }
    }
  }
}

.vertical-tabs-2 {
  border: 0;

  .nav-item {
    margin-inline-end: 0.5rem;

    &:last-child {
      margin-inline-end: 0;
    }

    .nav-link {
      min-width: 7.5rem;
      max-width: 7.5rem;
      text-align: center;
      border: 1px solid variables.$default-border;
      margin-bottom: 0.5rem;
      color: variables.$text-muted;
      background-color: variables.$light;

      i {
        font-size: 1rem;
      }

      &.active {
        background-color: variables.$primary;
        color: variables.$white;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          inset-inline-end: -0.5rem;
          inset-block-start: 38%;
          transform: rotate(45deg);
          width: 1rem;
          height: 1rem;
          background-color: variables.$primary;
        }

        &:hover {
          color: variables.$white;
        }
      }

      &:hover {
        color: variables.$primary;
      }
    }
  }
}

.vertical-tabs-3 {
  border: 0;

  .nav-item {
    border: 0;
    margin-inline-end: 0.5rem;

    &:last-child {
      margin-inline-end: 0;
    }

    .nav-link {
      min-width: 160px;
      max-width: 160px;
      border: 1px solid variables.$primary;
      padding: 1.3rem;
      margin-block-end: 0.5rem;

      i {
        width: 2rem;
        height: 2rem;
        line-height: 2rem;
        text-align: center;
        font-size: 1rem;
        background: variables.$primary;
        color: variables.$white;
        border-radius: 3.125rem;
        font-weight: variables.$font-weight-medium;
      }

      &.active {
        background: variables.$primary;
        border: 1px solid variables.$primary;
        color: variables.$white;

        &:hover {
          background: variables.$primary;
          border: 1px solid variables.$primary;
          color: variables.$white;
        }

        i {
          background: variables.$white;
          color: variables.$primary;
        }
      }

      &:hover,
      &:focus {
        border: 1px solid variables.$primary;
        color: variables.$primary;
        background: transparent;
      }
    }
  }
}

.nav.tab-style-1 {
  border-block-end: 0;
  padding: 0.65rem;
  background-color: variables.$light;
  border-radius: 7px;
  margin-block-end: 1rem;

  .nav-item {
    margin-inline-end: 0.5rem;

    &:last-child {
      margin-inline-end: 0;
    }
  }

  .nav-link {
    border-radius: 7px;
    padding: 0.35rem 1rem;

    &.active {
      background-color: variables.$primary !important;
      color: variables.$white !important;
      box-shadow: variables.$box-shadow;
      transition: color 0.15s ease-in;
    }
  }
}

.nav-tabs.tab-style-1 {
  .nav-link {
    &:hover {
      background: transparent;
      color: variables.$primary;
    }

  }

}

.tab-style-2 {
  border-block-end: 0;

  .nav-item {
    margin-inline-end: 0.5rem;

    &:last-child {
      margin-inline-end: 0;
    }

    .nav-link {
      border: 0;

      i {
        width: 1.875rem;
        height: 1.875rem;
        padding: 0.4rem;
        border-radius: 50px;
        background-color: variables.$light;
        color: variables.$text-color;
        display: inline-block;
      }

      &:hover {
        border: 0;
        background: transparent;
        color: variables.$primary;

        i {
          color: variables.$primary;
        }
      }

      &.active {
        background-color: transparent;
        position: relative;
        border: 0;
        color: variables.$primary;

        i {
          background-color: rgba(variables.$primary, 0.1);
          color: variables.$primary;
        }

        &:before {
          content: "";
          position: absolute;
          inset-inline-start: 0;
          inset-inline-end: 0;
          inset-block-end: 0;
          width: 100%;
          height: 0.175rem;
          background-color: variables.$primary;
          border-radius: 50px;
        }
      }
    }
  }
}

.tab-style-3 {
  border-block-end: 0;

  .nav-item {
    position: relative;
    margin-inline-end: 0.5rem;

    &:last-child {
      margin-inline-end: 0;
    }

    .nav-link {
      position: relative;
      border: 0;
      font-size: 0.75rem;

      &:before {
        position: absolute;
        content: "";
        inset-block-end: -1.063rem;
        inset-inline-end: 0;
        inset-inline-start: 0;
        width: 100%;
        height: 0.1rem;
        background-color: variables.$light;
        border-radius: 50%;
      }

      &:after {
        position: absolute;
        content: "";
        inset-block-end: -2rem;
        inset-inline-start: 1.75rem;
        inset-inline-end: 0;
        inset-inline-start: 30%;
        width: 2rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: variables.$white;
        border-radius: 50%;
        border: 2px solid variables.$default-border;
      }

      &.home:after {
        content: "1";
      }

      &.about:after {
        content: "2";
      }

      &.services:after {
        content: "3";
      }

      &:hover {
        border: 0;
        background: transparent;
        color: variables.$primary;
      }

      &.active {
        background-color: transparent;
        border: 0;
        color: variables.$primary;

        &:before {
          background-color: variables.$primary;
        }

        &:after {
          border-color: variables.$primary;
          color: variables.$primary;
        }
      }
    }
  }
}

.tab-style-4 {
  &.nav-tabs {
    border: 0;

    .nav-item {
      margin-inline-end: 0.5rem;

      &:last-child {
        margin-inline-end: 0;
      }
    }

    .nav-link {
      border: 0;

      &:hover {
        border: 0;
        background: transparent;
        color: variables.$primary;
      }

      &.active {
        background-color: transparent;
        border: 0;
        color: variables.$primary;
        position: relative;
        overflow: hidden;

        &::before {
          position: absolute;
          content: "";
          width: 0.75rem;
          inset-block-start: 31px;
          transform: rotate(45deg);
          inset-inline-start: 47%;
          overflow: hidden;
          z-index: 1;
          height: 0.75rem;
          background-color: variables.$primary;
          border: 1px solid variables.$primary;
        }

        &:after {
          position: absolute;
          content: "";
          width: 40%;
          height: 1px;
          inset-inline-start: 30%;
          inset-block-end: 0;
          background-color: variables.$primary;
        }
      }
    }
  }
}

#tab-style-4 .tab-pane.active {
  border: 1px solid variables.$primary;
  background-color: variables.$primary;
  color: variables.$white;
  padding: 16px;
  border-radius: 7px;
}

.tab-style-5 {
  .nav-item {
    margin-inline-end: 0.5rem;

    &:last-child {
      margin-inline-end: 0;
    }

    .nav-link.active {
      background: variables.$primary;
    }
  }
}

.tab-style-6 {
  border: 0;
  background-color: variables.$white;
  border-radius: 7px;
  color: variables.$primary;
  padding: 0.5rem;

  .nav-item {
    border: 0;
    margin-inline-end: 0.5rem;

    &:last-child {
      margin-inline-end: 0;
    }

    .nav-link {
      color: variables.$primary;
      padding: 0.5rem 1rem;
      font-size: 0.813rem;
      border: 0;
      font-weight: variables.$font-weight-medium;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;

      &.active {
        border: 0;
        background-color: variables.$primary;
        color: variables.$white;
        border: 0;
        box-shadow: 0px 3px 10px 0px rgba(var(--dark-rgb), 0.05);
      }

      &:hover,
      &:focus {
        border: 0;
      }
    }
  }
}

.tab-style-7 {
  .nav-link {
    margin-block-end: 0.5rem;
    font-size: 0.813rem;
    color: variables.$text-muted;
    text-align: left;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;

    &:active,
    &.active {
      background-color: rgba(variables.$primary, 0.1);
      color: variables.$primary;
    }
  }
}

.nav-style-1 {
  .nav-item {
    .nav-link {
      &.active {
        background-color: rgba(variables.$primary, 0.1);
        color: variables.$primary;
      }
    }
  }
}

@media screen and (max-width:400px) {
  .tab-style-4 {
    .nav-link {
      padding-inline: 0.5rem;
    }
  }
}

.nav-tabs {
  border-bottom: 0;

  &.nav-tabs-bottom {
    li {
      margin-bottom: -1px;
    }
  }

  .nav-link {
    margin-bottom: -1px;
    background: 0 0;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;

    &.active {
      border-color: variables.$gray-300;
    }
  }

  li {
    a {
      color: variables.$gray-600;
      border-bottom-width: 2px;

      &.active {
        border-color: transparent;
        border-bottom-color: variables.$primary;
        background-color: transparent;
        @include mixins.transition(all 0.2s ease);
      }
    }
  }
}

.nav-tabs {
  .nav-tabs-solid {
    background-color: variables.$white;
    border: 0;

    .active {
      background-color: variables.$primary;
      border-color: variables.$primary;
      color: variables.$white;
    }
  }
}

.nav-tabs {
  .nav-link {
    &:hover {
      border-color: transparent;
      color: variables.$primary;
    }
  }

  &.nav-tabs-top {
    li {
      a {
        border-width: 2px 0 0 0;

        &.active {
          border-top-color: variables.$primary;
          @include mixins.rounded(0);
        }

        &:hover {
          border-top-color: variables.$primary;
          background-color: transparent;
          @include mixins.rounded(0);
        }
      }
    }
  }

  &.nav-tabs-bottom {
    li {
      a {
        border-width: 0 0 2px 0;

        &.active,
        &:hover {
          border-bottom-color: variables.$primary;
          background: transparent;
          @include mixins.rounded(0);
        }
      }
    }
  }

  &.nav-tabs-solid {
    background-color: variables.$white;
    border: 0;

    &.nav-tabs-rounded {
      li {
        a {
          &.active {
            background-color: variables.$primary;
            border-color: variables.$primary;
            color: variables.$white;
            @include mixins.rounded(50px);
          }
        }
      }
    }

    li {
      a {
        &.active {
          background-color: variables.$primary;
          border-color: variables.$primary;
          color: variables.$white;
          @include mixins.rounded(0);
        }
      }
    }
  }
}

.nav-link {
  &:hover {
    color: variables.$primary;
  }
}

.page-menu {
  .nav-tabs {
    li {
      a {
        font-size: variables.$font-size-16;
      }
    }
  }
}

.tabs-set {
  .nav-tabs {
    border-bottom: 0;
    margin-bottom: 20px;

    .nav-link {
      color: variables.$secondary;
      @include mixins.margin-padding(null, 8px 15px);
      font-size: variables.$font-size-16;
      font-weight: variables.$font-weight-bold;
      border: 0;
      min-width: 120px;
      margin-right: 10px;
      border-radius: 8px;
      background: var(--Stroke, rgba(145, 158, 171, 0.30));
      @include mixins.margin-padding(null, null);

      &:focus {
        border-color: transparent;
      }

      &:hover {
        border-color: transparent;
      }

      &.active {
        border: 0;
        border-bottom: 2px solid variables.$primary;
        background: variables.$primary;
        color: variables.$white;
      }
    }
  }
}

.tabs-sets {
  .nav-tabs {
    border-bottom: 0;
    @include mixins.margin-padding(0 0 40px, null);

    .nav-link {
      color: variables.$black;
      ;
      font-size: variables.$font-size-base;
      font-weight: variables.$font-weight-bold;
      border: 0;
      background: variables.$gray-300;
      margin-right: 10px;
      @include mixins.margin-padding(null, 8px 15px);
      @include mixins.rounded(8px);
      @include mixins.transition(all 0.5s ease);

      &:focus,
      &:hover,
      &.active {
        background: variables.$primary;
        color: variables.$white;
      }
    }
  }
}

@include mixins.respond-below(custom768) {
  .nav-tabs {
    border-bottom: 0;
    position: relative;
    background-color: variables.$white;
    border: 1px solid #eee;
    @include mixins.margin-padding(null, 5px 0);
    @include mixins.rounded(3px);
  }
}