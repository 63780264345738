@use "../utils/mixins";
@use "../utils/variables";

.chat-user-actives {
    .call-meet-blk {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        background: variables.$white;
        border-radius: 5px;
        margin-bottom: 15px;
        padding: 10px;
        @include mixins.transition(all 0.5s ease);

        &:hover {
            background-color: variables.$black;
            @include mixins.transition(all 0.5s ease);
        }

        .meet-border {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            width: 39px;
            height: 39px;
            background: variables.$white;
            border: 0.5px solid variables.$gray-300;
            border-radius: 10px;
            margin-right: 10px;

            svg {
                width: 16px;
                color: variables.$blue;
            }
        }

        .recent-user-titles {
            h5 {
                font-size: variables.$font-size-base;
                font-weight: variables.$font-weight-semibold;
                color: variables.$black;
                margin-bottom: 5px;
                width: 150px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            p {
                font-size: variables.$font-size-10;
                color: variables.$text-color;
            }
        }
    }
}

.new-join-link {
    .chat-user-actives {
        .meet-border {
            background: gray;
            border: 0;
            border-radius: 6px;

            &:hover {
                background: variables.$primary;

                svg {
                    color: variables.$white;
                }
            }

            svg {
                color: variables.$primary;
            }
        }

        .recent-user-titles {
            h5 {
                color: variables.$primary;
                width: 100%;
                margin-bottom: 0;
                font-size: variables.$font-size-15;
            }
        }
    }
}

.meet-chat-blk {
    .join-call {
        .join-video {
            position: relative;
            margin-bottom: 25px;

            img {
                border-radius: 10px;
            }

            .video-avatar {
                color: variables.$white;
                background: #5D5D5D;
                font-size: 50px;
                border-radius: 10px;
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                display: none;
                align-items: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                -webkit-justify-content: center;
                justify-content: center;
                -ms-flex-pack: center;

                &.active {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    position: relative;
                    height: 426px;
                }
            }

            .call-icons {
                text-align: center;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;

                .call-items {
                    border-radius: 5px;
                    padding: 0;
                    margin: 0;
                    list-style: none;
                    display: -webkit-inline-box;
                    display: -ms-inline-flexbox;
                    display: inline-flex;
                    position: absolute;
                    bottom: 30px;
                    left: 50%;
                    transform: translate(-50%, 0);

                    .call-item {
                        display: inline-block;
                        text-align: center;
                        margin-right: 15px;

                        &:last-child {
                            margin-right: 0px;
                        }

                        a {
                            color: variables.$white;
                            background: variables.$white;
                            border-radius: 10px;
                            width: 54px;
                            height: 54px;
                            display: -webkit-box;
                            display: -ms-flexbox;
                            display: flex;
                            align-items: center;
                            -webkit-box-align: center;
                            -ms-flex-align: center;
                            -webkit-justify-content: center;
                            justify-content: center;
                            -ms-flex-pack: center;
                            font-size: variables.$font-size-20;
                            white-space: nowrap;

                            @include mixins.respond-below(custom767) {
                                width: 45px;
                                height: 45px;
                            }

                            i {
                                color: variables.$text-color;
                            }

                            &.stop {
                                color: variables.$white;
                                background: variables.$danger;

                                i {
                                    color: variables.$white;
                                }
                            }
                        }
                    }
                }
            }

            .meet-drop {
                ul {
                    li {
                        i {
                            font-size: variables.$font-size-18;
                        }
                    }
                }
            }

        }

        .join-content {
            text-align: center;
            max-width: 500px;
            margin: auto;

            h4 {
                font-size: variables.$font-size-22;
                font-weight: variables.$font-weight-semibold;
                margin-bottom: 20px;

                @include mixins.respond-below(custom767) {
                    font-size: variables.$font-size-18;
                }
            }

            .event-user-group {
                margin-bottom: 10px;

                .avatar-group {
                    .avatar {
                        width: 38px;
                        height: 38px;
                        border: 3px solid variables.$white;

                        &+.avatar {
                            margin-left: -20px;
                        }

                        .avatar-title {
                            color: variables.$black;
                            font-size: variables.$font-size-base;
                            font-weight: variables.$font-weight-semibold;
                            background: gray;
                        }
                    }
                }
            }

            p {
                font-size: variables.$font-size-13;
                color: variables.$text-color;
            }

            .btn-join-call {
                .btn-primary {
                    background: variables.$primary;
                    border-radius: 6px 6px 6px 6px;
                    color: variables.$white;
                    font-size: variables.$font-size-base;
                    border: 1px solid variables.$primary;
                    font-weight: variables.$font-weight-semibold;
                    min-width: 105px;
                    padding: 8px 15px;

                    &:hover {
                        background: variables.$danger;
                        border: 1px solid gray;
                        color: variables.$primary;
                    }
                }
            }
        }
    }
}

.conference-meet-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    .meeting-list {
        width: 100%;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        -ms-transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;

        &.add-meeting {
            width: 65%;
            margin-right: 24px;

            @include mixins.respond-below(custom767) {
                display: none;
                margin-right: 0px;
            }
        }
    }

    .right-user-side {
        width: 0;
        opacity: 0;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        -ms-transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;

        &.right-side-party {
            width: 0;
            opacity: 0;
            -moz-transition: all 0.5s ease;
            -o-transition: all 0.5s ease;
            -ms-transition: all 0.5s ease;
            -webkit-transition: all 0.5s ease;
            transition: all 0.5s ease;

            .party-msg-blk {
                .last-call-time {
                    .call-recent {
                        background: none;

                        svg {
                            color: variables.$primary;
                            width: 14px;
                        }
                    }
                }
            }
        }

        &.chat-rooms {
            width: 0;
            opacity: 0;
            -moz-transition: all 0.5s ease;
            -o-transition: all 0.5s ease;
            -ms-transition: all 0.5s ease;
            -webkit-transition: all 0.5s ease;
            transition: all 0.5s ease;

            .slime-grp {
                padding: 0;

                .left-chat-title {
                    padding: 20px;
                    border-bottom: 1px solid #E1E8ED;
                    border-radius: 10px 10px 0 0;
                }
            }

            &.open-chats {
                width: 35%;
                opacity: 1;

                @include mixins.respond-below(custom767) {
                    width: 100%;
                }
            }
        }

        &.open-message {
            width: 35%;
            opacity: 1;

            @include mixins.respond-below(custom767) {
                width: 100%;
            }
        }
    }
}

.meet-drop {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    position: absolute;
    top: 20px;
    right: 20px;

    ul {
        li {
            .chats-dot {
                a {
                    width: 50px;
                    height: 50px;
                    color: #6B7280;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    align-items: center;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    -webkit-justify-content: center;
                    justify-content: center;
                    -ms-flex-pack: center;

                    @include mixins.respond-below(custom767) {
                        width: 38px;
                        height: 38px;
                    }
                }
            }
        }
    }

    &.meet-mutes {
        background: variables.$primary;
        right: inherit;
        left: 20px;

        ul {
            li {
                a {
                    color: variables.$white;
                    width: 50px;
                    height: 50px;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    align-items: center;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    -webkit-justify-content: center;
                    justify-content: center;
                    -ms-flex-pack: center;

                    @include mixins.respond-below(custom767) {
                        width: 38px;
                        height: 38px;
                    }
                }
            }
        }
    }

    &.meet-mutes-bottom {
        background: variables.$primary;
        bottom: 20px;
        border-radius: 50px;
        top: inherit;

        ul {
            li {
                a {
                    color: variables.$white;
                    width: 50px;
                    height: 50px;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    align-items: center;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    -webkit-justify-content: center;
                    justify-content: center;
                    -ms-flex-pack: center;

                    @include mixins.respond-below(custom767) {
                        width: 38px;
                        height: 38px;
                    }

                    i {
                        font-size: variables.$font-size-20;
                    }
                }
            }
        }
    }
}

.meet-call-menu-blk {
    .video-call-action {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-justify-content: center;
        justify-content: center;
        -ms-flex-pack: center;

        @include mixins.respond-below(custom767) {
            display: block;
        }

        ul {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-justify-content: center;
            justify-content: center;
            -ms-flex-pack: center;

            li {
                margin-right: 5px;

                &:last-child {
                    margin-right: 0;
                }

                a {
                    width: 40px;
                    height: 40px;
                    border-radius: 50px;
                    background: #D9D9D9;
                    border: 1px solid #D9D9D9;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    align-items: center;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    -webkit-justify-content: center;
                    justify-content: center;
                    -ms-flex-pack: center;
                    color: variables.$black;
                    font-size: variables.$font-size-18;

                    &.call-end {
                        background-color: variables.$danger;
                        border-color: variables.$danger;
                        color: variables.$white;
                        width: 60px;
                        height: 60px;
                        rotate: 135deg;
                        font-size: variables.$font-size-20;
                    }

                    &.stop {
                        color: variables.$white;
                        background: variables.$danger;
                        border-color: variables.$danger;

                        i {
                            color: variables.$white;
                        }
                    }
                }

                &.active {
                    a {
                        background: variables.$primary;
                        border-color: variables.$primary;
                        color: variables.$white;

                        img {
                            filter: invert(1) brightness(100);
                        }
                    }
                }
            }

            @include mixins.respond-below(custom767) {
                margin-bottom: 5px;
                justify-content: center;
            }

            &:last-child {
                @include mixins.respond-below(custom767) {
                    margin-bottom: 0px;
                }
            }
        }
    }
}

.recession-meet-blk {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    margin-bottom: 30px;

    @include mixins.respond-below(custom767) {
        display: block;
    }

    .reccession-head {
        @include mixins.respond-below(custom767) {
            margin-bottom: 5px;
        }

        h5 {
            font-size: variables.$font-size-20;
            font-weight: variables.$font-weight-bold;
            color: variables.$secondary;
            margin-bottom: 0px;

            @include mixins.respond-below(custom767) {
                font-size: variables.$font-size-18;
            }
        }

        ul {
            li {
                font-size: variables.$font-size-15;
                font-weight: variables.$font-weight-normal;
                color: variables.$text-color;
                margin-right: 25px;

                &:last-child {
                    margin-right: 0px;
                }

                @include mixins.respond-below(custom767) {
                    margin-bottom: 10px
                }
            }
        }
    }

    .partispant-blk {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;

        .btn-primary {
            background: #1b2950;
            border-radius: 6px;
            color: variables.$white;
            border: 1px solid #1b2950;
            margin-right: 10px;
            font-weight: variables.$font-weight-bold;
            font-size: variables.$font-size-base;  
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            @include mixins.respond-below(custom767) {
                font-size: variables.$font-size-13;
            }

            &:hover {
                background: variables.$primary;
                border: 1px solid variables.$primary;
            }           

            &:last-child {
                margin-right: 0;
            }
        }

        span {
            a {
                width: 42px;
                height: 42px;
                background: #F7F7FA;
                border-radius: 5px;
                color: variables.$text-color;
                font-size: variables.$font-size-18;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                align-items: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                -webkit-justify-content: center;
                justify-content: center;
                -ms-flex-pack: center;

                &:hover {
                    background: variables.$primary;
                    color: variables.$white;
                }
            }
        }
    }
}

.recording-time {
    position: relative;
    padding-left: 20px;

    &::before {
        content: "";
        position: absolute;
        left: 0px;
        top: 4px;
        background-color: variables.$danger;
        width: 13px;
        height: 13px;
        border-radius: 50px;
    }

    &::after {
        content: "";
        position: absolute;
        left: 3px;
        top: 7px;
        background-color: variables.$white;
        width: 7px;
        height: 7px;
        border-radius: 50px;
        -webkit-animation: blink-animation 1.5s steps(5, start) infinite;
        animation: blink-animation 1.5s steps(5, start) infinite;
    }
}

.page-content {
    width: 100%;
}

.join-call {
    width: 100%;
    margin: 0 auto;
}

.join-video {
    position: relative;
    margin-bottom: 25px;

    img {
        border-radius: 10px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &.join-video-front {
        img {
            height: 423px;
        }
    }
}

.video-avatar {
    color: variables.$white;
    background: #5D5D5D;
    font-size: 50px;
    border-radius: 10px;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    h4 {
        font-weight: variables.$font-weight-bold;
        font-size: variables.$font-size-24;
        color: variables.$white;
    }
}

.video-avatar.active {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.join-video.video-hide>img,
.video-wrap.video-hide>video {
    display: none;
}

.join-content {
    p {
        font-weight: variables.$font-weight-normal;
        font-size: variables.$font-size-14;
        margin-bottom: 25px;
        color: variables.$black;
    }

    label {
        font-size: variables.$font-size-18;
        margin-bottom: 0;
        color: variables.$black;
        font-weight: variables.$font-weight-bold;
    }
}

.part-name {
    position: absolute;
    top: 20px;
    left: 12px;
    text-align: left;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 50px;
    padding: 5px 15px;

    h4 {
        font-weight: variables.$font-weight-medium;
        font-size: variables.$font-size-15;
        color: variables.$text-color;
        margin-bottom: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        @include mixins.respond-below(custom767) {
            font-size: variables.$font-size-13;
        }
    }

    &.sub-part-name {
        background: variables.$white;
        bottom: 10px;
        top: inherit;

        h4 {
            color: variables.$secondary;
            font-size: variables.$font-size-12;
        }
    }
}

.grid-join {
    .part-name {
        background: rgba(0, 0, 0, 0.3);
        top: inherit;
        bottom: 20px;

        h4 {
            color: variables.$white;
        }
    }
}

.join-hover-img {
    position: absolute;
    @include mixins.position-alignment(both);

    .event-user-group {
        .avatar-group {
            background: rgba(0, 0, 0, 0.3);
            padding: 2px 10px;
            border-radius: 50px;

            .avatar {
                width: 26px;
                height: 26px;
                border: 1px solid variables.$white;

                &+.avatar {
                    margin-left: -16px;
                }

                &:last-child {
                    margin-right: 0px;
                }

                .avatar-title {
                    color: #1F2937;
                    font-size: 12px;
                    font-weight: variables.$font-weight-semibold;
                    background: #F3F4F6;
                }
            }
        }
    }
}

.more-icon {
    position: absolute;
    bottom: 10px;
    right: 12px;

    @include mixins.respond-below(custom767) {
        bottom: 8px;
        right: 13px;
    }

    a {
        color: variables.$text-color;
        width: 30px;
        height: 30px;
        background: variables.$white;
        border-radius: 50px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-justify-content: center;
        justify-content: center;
        -ms-flex-pack: center;

        &:hover {
            opacity: 0.8;
        }

        &.other-mic-off {
            &.stop {
                i {
                    color: variables.$danger;
                }
            }
        }
    }

    &.audio-more-icon {
        a {
            &.other-mic-off {
                &.stop {
                    background-color: variables.$danger !important;

                    i {
                        color: variables.$white;
                    }
                }
            }
        }
    }
}

.call-recent {
    &.recent-part {
        a {

            &.other-mic-off,
            &.other-video-off {
                &.stop {
                    background: variables.$danger !important;

                    i {
                        color: variables.$white;
                    }
                }
            }
        }
    }
}

.meeting {
    .part-name {
        top: 15px;
        left: 15px;
    }

    .more-icon {
        top: 15px;
        right: 15px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
}

.vertical-view {
    .vertical-sec {
        .part-name {
            h4 {
                font-size: variables.$font-size-14;
                font-weight: variables.$font-weight-normal;
            }
        }
    }
}

.horizontal-view {
    .join-video {
        &.single-user {
            .part-name h4 {
                font-size: variables.$font-size-14;
                font-weight: variables.$font-weight-normal;
            }
        }
    }
}

.meeting-wrapper,
.meeting {
    width: inherit;
    max-width: inherit;
}

.meet-footer {
    position: fixed;
    bottom: 25px;
    width: 100%;
    max-width: 950px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 3;
}

.show-chat-list,
.show-invite-list,
.show-chat-list .user-sidebar-right,
.show-invite-list .invite-sidebar-right {
    transition: all 0.8s ease;
}

.user-sidebar-right,
.invite-sidebar-right {
    background: #dedede;
    border-radius: 10px;
    width: 410px;
    position: absolute;
    top: 105px;
    bottom: 117px;
    right: 0;
    margin-right: -410px;
    transition: all 0.8s ease;
    overflow: hidden;
    font-size: 13px;
    z-index: 3;
}

.join-contents {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    box-sizing: border-box;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;

    &.grid-view {
        align-content: center;
    }
}

.ripple {
    width: 114px;
    height: 114px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.multigrid-view {
    .text-box {
        height: 61px;
        width: 61px;
    }
}

.multigrid-view {
    .ripple {
        width: 80px;
        height: 80px;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 50%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

.meeting {
    .join-video {
        margin: 0 5px 10px;
        width: 100%;
    }
}

.join-video {
    &.user-active {
        background: #415B6A;
        border-radius: 10px;
        width: 100%;
        height: 370px;

        &.audio-calls {
            height: 520px;
        }
    }

    &.vertical-active {
        margin-right: 24px;
    }

    &.single-user {
        width: 100%;
        min-height: 230px;
        box-sizing: border-box;
        margin-right: 24px;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        text-align: center;
        height: auto;
        background: #415B6A;
        border-radius: 10px;
        padding: 0;
        flex-grow: 1;

        @include mixins.respond-below(custom1280) {
            width: 100%;
        }

        @include mixins.respond-below(custom1199) {
            min-height: 203px;
        }

        @include mixins.respond-below(custom991) {
            min-height: 230px;
        }

        &:nth-child(even) {
            margin-right: 0px;

            @include mixins.respond-below(custom767) {
                margin-right: 10px;
            }
        }

        @include mixins.respond-below(custom767) {
            width: 100%;
            min-height: 257px;
        }
    }

}

.multi-grid {
    .join-video.single-user {
        @include mixins.respond-below(custom767) {
            width: 45%;
        }

        &:nth-child(even) {
            margin-right: 24px;

            @include mixins.respond-below(custom767) {
                margin-right: 0px;
            }
        }

        &:nth-child(4n) {
            margin-right: 0px;

            @include mixins.respond-below(custom767) {
                &:nth-child(4n) {
                    margin-right: 0px;
                }
            }
        }
    }
}

.multigrid-view {
    .join-video {
        &.single-user {
            flex-grow: 0;
        }
    }
}

.horizontal-view {
    .join-video.single-user {
        &:nth-child(even) {
            margin-right: 24px;
        }

        &:nth-child(5) {
            margin-right: 0px;
        }

        &:nth-child(3n) {
            @include mixins.respond-below(custom767) {
                margin-right: 0
            }
        }
    }
}

.text-avatar {
    color: variables.$white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    h4 {
        color: variables.$white;
    }
}

.text-box {
    height: 86px;
    width: 86px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.2);
    font-size: variables.$font-size-36;
    font-weight: variables.$font-weight-bold;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.more-icon.mic-view .mic-on,
.more-icon .mic-off {
    display: none;
}

.more-icon.mic-view .mic-off {
    display: inline-flex;
}

.grid-view .join-video.single-user {
    width: 47%;

    @include mixins.respond-below(custom767) {
        width: 40%;
    }

    &:nth-child(2n) {
        @include mixins.respond-below(custom767) {
            margin-right: 0px;
        }
    }

    &:nth-child(3n) {
        @include mixins.respond-below(custom767) {
            margin-right: 24px;
        }
    }
}

.join-contents.multigrid-view {
    max-width: 100%;
    justify-content: center;
}

.meeting {
    position: absolute;
    top: 80px;
    bottom: 107px;
    left: 0;
    right: 0;
}

.meeting-list {
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: relative;
}

.user-active {
    .more-icon {
        .handraise-on {
            display: none;

            &.active {
                display: block;
                animation: mymove 5s;
            }
        }
    }
}

.user-active .more-icon .handraise-on.active .meeting .join-video.user-active::before {
    animation: mymove 5s infinite;
    opacity: 1;
}

@keyframes mymove {
    from {
        color: #0074E0;
    }

    to {
        color: transparent;
    }
}

.join-contents {
    &.vertical-view {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        align-content: center;
        max-width: 1300px;

        @include mixins.respond-below(custom767) {
            display: block;
        }

        .join-video {
            &.user-active {
                flex: 1;
                margin-bottom: 24px;
                ;
            }

            &.single-user {
                width: 97%;
                flex-grow: 1;
                display: flex;

                @include mixins.respond-below(custom767) {
                    width: 100%;
                }
            }
        }

        .vertical-sec {
            width: 20%;

            @include mixins.respond-below(custom1024) {
                width: 30%;
            }

            @include mixins.respond-below(custom767) {
                width: 100%;
            }
        }
    }

    &.vertical {
        flex-direction: column;
        height: 200px;
    }
}

.meeting .join-contents.vertical .join-video.user-active {
    flex: 1 0 100%;
}

.meeting .join-contents.vertical .join-video.single-user {
    flex: 0;
}

.meeting .join-contents.vertical .join-video {
    width: auto;
}

.chat-footer .btn:hover {
    background: #0066c5;
    color: variables.$white;
}

.join-contents {
    &.show-whiteboard {
        display: none;
    }

    &.single-view {
        padding-top: 0;
        margin-top: 25px;
        position: relative;

        .single-user {
            position: absolute;
            right: 8px;
            top: 40px;
            width: 130px;
            height: 130px;
            min-height: 130px;

            img {
                height: 130px;
            }
        }
    }
}

.record-time {
    position: absolute;
    top: 20px;
    z-index: 1;
    position: absolute;
    left: 20px;

    span {
        background-color: rgba(255, 255, 255, 0.2);
        display: inline-block;
        border-radius: 130px;
        color: variables.$white;
        font-size: variables.$font-size-14;
        font-weight: variables.$font-weight-semibold;
        width: 80px;
        height: 30px;
        text-align: center;
        line-height: 34px;
        position: relative;
        padding-left: 15px;

        &:before {
            content: "";
            position: absolute;
            left: 7px;
            top: 7px;
            background-color: variables.$white;
            width: 18px;
            height: 18px;
            border-radius: 50px;
        }

        &:after {
            content: "";
            position: absolute;
            left: 12px;
            top: 12px;
            background-color: variables.$danger;
            width: 8px;
            height: 8px;
            border-radius: 50px;
            -webkit-animation: blink-animation 1.5s steps(5, start) infinite;
            animation: blink-animation 1.5s steps(5, start) infinite;
        }
    }
}

@keyframes blink-animation {
    to {
        visibility: hidden;
    }
}

@-webkit-keyframes blink-animation {
    to {
        visibility: hidden;
    }
}

.volume-col {
    position: absolute;
    left: 20px;
    bottom: 20px;
}

.volume-col .inner-volume-col {
    background-color: variables.$gray-300;
    border-radius: 130px;
    width: 30px;
    height: 120px;
    position: relative;
    z-index: 9;
}

.volume-col #volume {
    position: absolute;
    left: 50%;
    top: 15px;
    margin: 0 auto;
    height: 80px;
    width: 5px;
    background: variables.$white;
    border-radius: 15px;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.volume-col #volume .ui-slider-range-min {
    height: 5px;
    width: 100%;
    position: absolute;
    bottom: 0;
    background: variables.$primary;
    border: none;
    border-radius: 10px;
    outline: none;
}

.volume-col #volume .ui-slider-handle {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background: variables.$primary;
    position: absolute;
    left: 50%;
    margin-top: -8px;
    cursor: pointer;
    outline: none;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.volume-col #player {
    width: auto;
    height: 104px;
    position: relative;
    margin: 0 auto;
    top: 0px;
}

.volume-col #player i {
    position: absolute;
    margin-top: -6px;
    color: #666;
}

.volume-col .volume-icons {
    color: variables.$white;
    margin-left: 0px;
    font-size: variables.$font-size-18;
    margin-top: 22px;
}

.chat-msg-blk {
    padding: 15px;

    .chats {
        max-width: 100%;
        margin-bottom: 1.5rem;
        display: -webkit-flex;
        display: flex;

        @include mixins.respond-below(custom767) {
            max-width: 100%;
        }

        .chat-action-btns {
            .chat-user-time {
                .chats-dot {
                    a {
                        color: variables.$green;
                    }
                }
            }
        }

        &.chats-right {
            margin-left: auto;
            -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;

            .chat-content {
                -webkit-order: 1;
                order: 1;

                .message-content {
                    background-color: variables.$gray-300;
                    border-radius: 8px;

                    .chat-time {
                        -webkit-justify-content: flex-start;
                        justify-content: flex-start;
                    }

                    .download-col {
                        position: relative;

                        ul {
                            li {
                                margin-right: 8px;

                                &:last-child {
                                    margin-right: 0px;
                                }

                                .image-download-col {
                                    position: relative;

                                    &.image-not-download {
                                        a {
                                            img {
                                                -webkit-filter: blur(4px);
                                                filter: blur(1px);
                                                border-color: variables.$black;
                                            }
                                        }

                                        span {
                                            position: absolute;
                                            @include mixins.position-alignment(both);

                                            i {
                                                color: variables.$white;
                                            }
                                        }
                                    }

                                    .download-action {
                                        position: absolute;
                                        right: 8px;
                                        bottom: 8px;
                                        line-height: 0;

                                        a {
                                            color: variables.$white;
                                            font-size: 10px;
                                        }
                                    }

                                    img {
                                        border-radius: 5px;
                                    }
                                }
                            }
                        }
                    }

                    &::before {
                        border-bottom: 6px solid transparent;
                        border-left: 6px solid variables.$gray-300;
                        border-right: 0;
                        border-top: 6px solid transparent;
                        content: "";
                        height: 0;
                        width: 0;
                        position: absolute;
                        top: 8px;
                        right: -4px;
                        left: auto;
                    }
                }
            }

            .chat-avatar {
                padding: 8px 0px 0 15px;
            }

            .chat-action-btns {
                -webkit-order: 2;
                order: 2;
                display: -webkit-flex;
                display: flex;
                -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
                -webkit-justify-content: center;
                justify-content: center;

                .chat-user-time {
                    .chats-dot {
                        a {
                            color: variables.$green;
                        }
                    }
                }
            }
        }

        .chat-avatar {
            padding: 8px 15px 0 8px;
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: flex-start;
            align-items: flex-start;
            flex-shrink: 0;

            .dreams_chat {
                width: 40px;
                height: 40px;
                object-fit: cover;
            }
        }

        .message-content {
            background-color: variables.$gray-300;
            border-radius: 8px;
            padding: 10px 20px;
            font-weight: variables.$font-weight-medium;
            margin-bottom: 5px;
            position: relative;

            h4 {
                font-size: variables.$font-size-13;
                color: variables.$secondary;
                margin-bottom: 0px;
                line-height: 1.4;
            }

            .chat-time {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                align-items: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                -webkit-justify-content: center;
                justify-content: center;
                -ms-flex-pack: center;
                font-size: variables.$font-size-10;
                font-weight: variables.$font-weight-medium;
                color: variables.$green;
                margin-bottom: 0;

                svg {
                    width: 10px;
                }
            }

            &::before {
                border-bottom: 6px solid transparent;
                border-right: 6px solid variables.$gray-300;
                border-top: 6px solid transparent;
                content: "";
                height: 0;
                width: 0;
                position: absolute;
                top: 8px;
                left: -4px;
                right: auto;
            }
        }

        .chat-profile-name {
            h6 {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                align-items: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                -webkit-justify-content: left;
                justify-content: left;
                -ms-flex-pack: left;
                font-size: 8px;
                font-weight: variables.$font-weight-medium;
                color: variables.$text-color;

                i {
                    font-size: 12px;
                    color: #008024;
                }
            }
        }
    }

    .chat-line {
        margin: 0px 0 20px;
        position: relative;
        text-align: center;
        width: 100%;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            background-color: variables.$green;
            width: 100%;
            height: 1px;
        }

        .chat-date {
            background-color: variables.$green;
            color: variables.$green;
            font-size: variables.$font-size-13;
            padding: 7px 14px;
            border-radius: 3px;
            display: inline-block;
            font-weight: variables.$font-weight-semibold;
            position: relative;
        }
    }
}

.right-user-side {
    &.call-user-side {
        .slime-grp {
            .right-sidebar-profile {
                -webkit-justify-content: space-between;
                -ms-flex-pack: space-between;
                justify-content: space-between;
                border-bottom: 0;
                padding-bottom: 10px;

                .profile-call-blk {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    align-items: center;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    -webkit-justify-content: center;
                    justify-content: center;
                    -ms-flex-pack: center;
                }

                .edit-group {
                    svg {
                        width: 16px;
                        color: variables.$green;
                    }
                }
            }

            .chat-options {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                align-items: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                -webkit-justify-content: center;
                justify-content: center;
                -ms-flex-pack: center;
                border-bottom: 1px solid variables.$green;
                padding-bottom: 20px;

                ul {
                    li {
                        a {
                            color: variables.$green;
                            width: 30px;
                            height: 30px;
                            background-color: gray;
                            padding: 0;
                            border-radius: 50px;

                            &:hover {
                                background: variables.$primary;
                                border-color: variables.$primary;

                                svg {
                                    color: variables.$white;
                                }
                            }

                            svg {
                                width: 15px;
                            }
                        }
                    }
                }
            }
        }
    }

    &.right-side-party {
        .slime-grp {
            padding: 0px;

            .left-chat-title {
                padding: 20px;
                margin-bottom: 0;
                background-color: variables.$body-bg;
                border-radius: 10px 10px 0 0;

                h4 {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    align-items: center;
                    -webkit-box-align: center;
                    -ms-flex-align: center;

                    span {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        align-items: center;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        -webkit-justify-content: center;
                        justify-content: center;
                        -ms-flex-pack: center;
                        width: 25px;
                        height: 25px;
                        background-color: variables.$warning;
                        border-radius: 50px;
                        font-size: variables.$font-size-14;
                        color: variables.$white;
                        margin-left: 10px;
                    }
                }
            }

            .card-body-blk {
                padding: 20px;

                .user-list {
                    border-radius: .25rem;
                    display: -webkit-flex;
                    display: flex;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    padding-left: 0;
                    margin-bottom: 0;

                    .user-list-item {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        align-items: center;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        padding: 5px;
                        display: -webkit-flex;
                        display: flex;
                        border-radius: 6px;
                        background-color: #F6F7F8;
                        margin-bottom: 10px;
                        border: 0;

                        &:hover {
                            background-color: #F6F7F8;
                            -webkit-transition: ease all 0.5s;
                            -ms-transition: ease all 0.5s;
                            transition: ease all 0.5s;
                        }

                        .avatar {
                            margin-right: 0.8rem;
                            display: inline-block;
                            margin-bottom: 0;
                            height: 40px;
                            width: 40px;
                            position: relative;
                            flex-shrink: 0;

                            &::before {
                                top: 0;
                                bottom: inherit;
                            }

                            img {
                                border-radius: 0px;
                            }
                        }

                        .users-list-body {
                            display: -webkit-box;
                            display: -ms-flexbox;
                            display: flex;
                            align-items: center;
                            -webkit-box-align: center;
                            -ms-flex-align: center;
                            -webkit-justify-content: space-between;
                            -ms-flex-pack: space-between;
                            justify-content: space-between;
                            flex-grow: 1;

                            .name-list-user {
                                display: grid;
                                display: -webkit-box;
                                display: -ms-flexbox;
                                display: flex;
                                align-items: center;
                                -webkit-box-align: center;
                                -ms-flex-align: center;

                                h5 {
                                    font-size: variables.$font-size-15;
                                    font-weight: variables.$font-weight-normal;
                                    margin-bottom: 2px;
                                    color: variables.$text-color;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }

                                p {
                                    font-size: variables.$font-size-12;
                                    color: variables.$danger;
                                    display: -webkit-box;
                                    display: -ms-flexbox;
                                    display: flex;
                                    align-items: center;
                                    -webkit-box-align: center;
                                    -ms-flex-align: center;

                                    svg {
                                        width: 14px;
                                        height: 14px;
                                    }
                                }
                            }

                            .chat-user-time {
                                .chats-dot {
                                    a {
                                        color: variables.$danger;
                                    }
                                }
                            }

                            .last-chat-time {
                                .text-muted {
                                    font-size: variables.$font-size-10;
                                    color: variables.$secondary;
                                    font-weight: variables.$font-weight-medium;
                                }

                                .new-message-count {
                                    width: 20px;
                                    height: 20px;
                                    display: -webkit-flex;
                                    display: flex;
                                    -webkit-align-items: center;
                                    align-items: center;
                                    -webkit-justify-content: center;
                                    justify-content: center;
                                    line-height: 0;
                                    font-size: 11px;
                                    background-color: variables.$primary;
                                    color: variables.$white;
                                    border-radius: 50%;
                                    margin-left: auto;
                                    margin-top: 5px;
                                }
                            }

                            .last-call-time {
                                display: -webkit-box;
                                display: -ms-flexbox;
                                display: flex;
                                align-items: center;
                                -webkit-box-align: center;
                                -ms-flex-align: center;

                                .call-recent {
                                    a {
                                        display: -webkit-box;
                                        display: -ms-flexbox;
                                        display: flex;
                                        align-items: center;
                                        -webkit-box-align: center;
                                        -ms-flex-align: center;
                                        -webkit-justify-content: center;
                                        justify-content: center;
                                        -ms-flex-pack: center;
                                        width: 30px;
                                        height: 30px;
                                        background: #B8BCC9;
                                        color: variables.$secondary;
                                        border-radius: 50px;

                                        svg {
                                            color: variables.$secondary;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .slimScrollDiv {
        height: calc(100vh - 110px) !important;

        .slimscroll {
            height: calc(100vh - 110px) !important;
        }
    }

    .slime-grp {
        background: variables.$white;
        box-shadow: 0px 4px 60px 0px #E7E7E778;
        border: 1px solid #E1E8ED;
        border-radius: 10px;
        padding: 15px;

        .left-chat-title {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-justify-content: space-between;
            -ms-flex-pack: space-between;
            justify-content: space-between;
            margin-bottom: 20px;
            background-color: variables.$body-bg;

            .chat-title {
                h4 {
                    font-size: variables.$font-size-18;
                    font-weight: variables.$font-weight-semibold;
                    color: variables.$secondary;
                    margin-bottom: 0;
                }
            }

            .contact-close_call {
                .close_profile {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    align-items: center;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    -webkit-justify-content: center;
                    justify-content: center;
                    -ms-flex-pack: center;
                    width: 26px;
                    height: 26px;
                    background: transparent;
                    border-radius: 50px;

                    &:hover {
                        background: variables.$primary;

                        svg {
                            color: variables.$white;
                        }
                    }

                    svg {
                        width: 16px;
                        color: variables.$danger;
                    }
                }
            }
        }

        .chat-footer {
            position: sticky;
            left: 0px;
            right: 0px;
            bottom: 0px;
            padding: 15px;
            background: variables.$white;

            form {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                align-items: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                position: relative;

                .comman-icon {
                    position: absolute;
                    top: 50%;
                    -webkit-transform: translateY(-50%);
                    -ms-transform: translateY(-50%);
                    transform: translateY(-50%);

                    a {
                        color: variables.$text-color;
                    }
                }

                .smile-col {
                    right: inherit;
                    left: 15px;
                }

                .attach-col {
                    right: inherit;
                    left: 45px;
                }

                .micro-col {
                    right: 50px;
                    left: inherit;

                    a {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        font-size: variables.$font-size-16;
                    }
                }

                .send-chat {
                    right: 5px;

                    a {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        align-items: center;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        -webkit-justify-content: center;
                        justify-content: center;
                        -ms-flex-pack: center;
                        width: 34px;
                        height: 34px;
                        background: variables.$primary;
                        border-radius: 50px;

                        &:hover {
                            background: #1b2950;

                            svg {
                                color: variables.$white;
                            }
                        }

                        svg {
                            width: 14px;
                            color: variables.$white;
                        }
                    }
                }

                .chat_form {
                    border-radius: 20px;
                    margin-right: 0px;
                    background: variables.$white;
                    border: 1px solid rgba(145, 158, 171, 0.3);
                    border-radius: 6px;
                    height: 48px;
                    padding: 10px 70px;

                    &::placeholder {
                        font-size: variables.$font-size-base;
                        color: rgba(0, 0, 0, 0.2);
                    }
                }
            }
        }
    }
}

.owl-carousel.video-slide {
    .owl-nav.disabled {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-justify-content: space-between;
        -ms-flex-pack: space-between;
        justify-content: space-between;
        width: 100%;
    }

    .owl-nav {
        top: 50%;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);

        @include mixins.respond-below(custom1280) {
            width: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            justify-content: space-between;
            -webkit-justify-content: space-between;
            -ms-flex-pack: space-between;
        }

        button.owl-next,
        button.owl-prev {
            background-color: #D9D9D9;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-justify-content: center;
            justify-content: center;
            -ms-flex-pack: center;

            &:hover {
                background-color: variables.$primary;

                i {
                    color: variables.$white;
                }
            }

            i {
                color: variables.$text-color;
            }
        }
    }
}

.audio-call-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    height: 100%;

    ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-justify-content: space-evenly;
        -ms-flex-pack: space-evenly;
        justify-content: space-evenly;
        width: 100%;

        li {
            position: relative;

            &.active {
                .avatar {
                    &::before {
                        content: "";
                        width: 250px;
                        height: 250px;
                        position: absolute;
                        border: 1px solid variables.$primary;
                        border-radius: 50%;
                        left: -25px;
                        top: -25px;

                        @include mixins.respond-below(custom991) {
                            width: 150px;
                            height: 150px;
                            left: -10px;
                            top: -10px;
                        }

                        @include mixins.respond-below(custom767) {
                            width: 100px;
                            height: 100px;
                            left: -5px;
                            top: -5px;
                        }
                    }
                }
            }

            .avatar {
                width: 200px;
                height: 200px;
                margin: 0;
                position: relative;

                @include mixins.respond-below(custom991) {
                    width: 130px;
                    height: 130px;
                }

                @include mixins.respond-below(custom767) {
                    width: 90px;
                    height: 90px;
                }

                img {
                    width: 100%;
                    position: relative;
                }
            }

            .user-audio-call {
                h5 {
                    font-size: variables.$font-size-14;
                    color: variables.$white;
                    font-weight: variables.$font-weight-normal;
                    text-transform: capitalize;
                    text-align: center;
                    margin-top: 35px;

                    @include mixins.respond-below(custom767) {
                        margin-top: 15px;
                    }
                }
            }

            .more-icon {
                position: absolute;
                bottom: 10px;
                right: inherit;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                z-index: 9;

                @include mixins.respond-below(custom767) {
                    bottom: 0px;
                }

                a {
                    background-color: variables.$primary;
                    color: variables.$white;
                }
            }
        }
    }
}

.audio-volume {
    position: absolute;
    width: fit-content;
    height: 134px;
    bottom: 20px;
    left: 20px;
    background: variables.$gray-300;
    border-radius: 50px;
    padding: 10px 14px;
    width: 30px;
    padding-bottom: 30px;

    .volume-icons {
        a {
            position: absolute;
            bottom: 0;
            width: 30px;
            height: 30px;
            left: 0;
            border-radius: 50px;
            background: variables.$white;
            border: 1px solid variables.$warning;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-justify-content: center;
            justify-content: center;
            -ms-flex-pack: center;
        }
    }
}

.custom-input {
    width: 100%;
    height: 4px;
    width: 3px;
    height: 100%;
    background: variables.$white;
    border-radius: 5px;
    writing-mode: vertical-lr;
}

.custom-input::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 12px;
    height: 12px;
    background: variables.$primary !important;
    border-radius: 50%;
    cursor: pointer;
}

.custom-input::-moz-range-thumb {
    width: 12px;
    height: 12px;
    background: variables.$primary !important;
    border-radius: 50%;
    cursor: pointer;
}