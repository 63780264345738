@use "../utils/mixins";
@use "../utils/variables";

.topnav-dropdown-header {
    border-bottom: 1px solid variables.$gray-300;
    text-align: center;
    font-size: variables.$font-size-12;
    height: 32px;
    line-height: 32px;
    @include mixins.margin-padding(null, 0 12px);

    .notification-title {
        color: variables.$gray-800;
        display: block;
        float: left;
        font-size: variables.$font-size-base;
    }

    .clear-noti {
        color: variables.$danger-700;
        float: right;
        font-size: variables.$font-size-11;
        text-transform: uppercase;
    }
}

.notifications {
    .noti-content {
        height: 290px;
        width: 350px;
        overflow-y: auto;
        position: relative;
    }

    .notification-list {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            margin-top: 0;
            border-bottom: 1px solid variables.$gray-100;
            font-size: variables.$font-size-base;

            a {
                display: block;
                @include mixins.margin-padding(null, 12px);
                @include mixins.rounded(2px);
            }

            .avatar {
                width: 34px;
                height: 34px;
                margin: 0 10px 0 0;
                border-radius: 50%;

                img {
                    border-radius: 50%;
                }
            }

            .noti-title {
                color: variables.$gray-800;
                font-weight: variables.$font-weight-medium;
            }

            .noti-details {
                color: variables.$gray-500;
                @include mixins.margin-padding(0, null);
            }

            p.noti-time {
                @include mixins.margin-padding(0, null);
            }

            .notification-time {
                font-size: variables.$font-size-12;
                line-height: 1.35;
                color: variables.$gray-600;
            }

            .list-item {
                border: 0;
                padding: 0;
                position: relative;

                .list-left {
                    height: 48px;
                    position: absolute;
                    width: 48px;
                }

                .list-body {
                    @include mixins.margin-padding(null, 0 0 0 50px);

                    .message-author {
                        color: variables.$gray-800;
                        float: left;
                        font-weight: variables.$font-weight-medium;
                        width: 175px;
                        display: block;
                        max-width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    .message-time {
                        color: variables.$gray-600;
                        float: right;
                        font-size: variables.$font-size-11;
                    }

                    .message-content {
                        color: variables.$gray-800;
                        font-size: variables.$font-size-13;
                        display: block;
                        max-width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}

.topnav-dropdown-footer {
    border-top: 1px solid variables.$gray-300;
    font-size: variables.$font-size-12;
    height: 32px;
    line-height: 32px;
    @include mixins.margin-padding(null, 0 12px);

    a {
        display: block;
        text-align: center;
        color: variables.$gray-800;
    }
}

.list-left {
    height: 48px;
    position: absolute;
    width: 48px;
}

.list-body {
    @include mixins.margin-padding(null, 0 0 0 50px);

    .message-author {
        color: variables.$gray-800;
        float: left;
        font-weight: variables.$font-weight-medium;
        width: 175px;
        display: block;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .message-time {
        color: variables.$gray-600;
        float: right;
        font-size: variables.$font-size-11;
    }

    .message-content {
        color: variables.$gray-800;
        font-size: variables.$font-size-13;
        display: block;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.activity {
    width: 100%;

    .activity-box {
        position: relative;
    }

    .activity-list {
        list-style: none;
        padding: 0;
        margin: 0;
        position: relative;

        li {
            background-color: variables.$white;
            position: relative;
            border: 1px solid variables.$gray-400;
            @include mixins.margin-padding(0 0 10px, 20px 10px 10px);
            font-size: variables.$font-size-15;

            .activity-user {
                height: 50px;
                margin: 0;
                padding: 0;
                width: 50px;
                @include mixins.position(absolute, 12px, null, null, 8px);
            }

            .activity-content {
                background-color: variables.$white;
                position: relative;
                @include mixins.margin-padding(0 0 0 60px, 0);
            }

            .time {
                color: variables.$gray-500;
                display: block;
                font-size: variables.$font-size-12;
                line-height: 1.35;
            }

            a.name {
                color: variables.$black;
                ;
            }

            a {
                color: variables.$secondary;
                font-weight: variables.$font-weight-semibold;
                font-size: variables.$font-size-15;

                img {
                    width: 50px;
                    height: 50px;
                    border-radius: 5px;
                }
            }
        }
    }

}