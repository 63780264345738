@use "../utils/variables";

.bg-primary {
    background-color: variables.$primary !important;
}

.bg-secondary {
    background-color: variables.$secondary !important;
}

.bg-info {
    background-color: variables.$info !important;
}

.bg-success {
    background-color: variables.$success !important;
}

.bg-warning {
    background-color: variables.$warning !important;
}

.bg-danger {
    background-color: variables.$danger !important;
}

.bg-dark {
    background-color: variables.$dark !important;
}

.bg-light {
    background-color: variables.$light !important;
}

.bg-white {
    background-color: variables.$white !important;
}

.bg-pink {
    background-color: variables.$pink !important;
}

.bg-purple {
    background-color: variables.$purple !important;
}

.bg-teal {
    background-color: variables.$teal !important;
}

.bg-blue {
    background-color: variables.$blue !important;
}

.bg-primary {
    background-color: variables.$primary !important;

    &.bg-opacity-10 {
        background-color: rgba(variables.$primary, 0.1) !important;
    }

    &.bg-opacity-25 {
        background-color: rgba(variables.$primary, 0.25) !important;
    }

    &.bg-opacity-50 {
        background-color: rgba(variables.$primary, 0.50) !important;
    }

    &.bg-opacity-75 {
        background-color: rgba(variables.$primary, 0.75) !important;
    }

    &.bg-opacity-100 {
        background-color: rgba(variables.$primary, 1) !important;
    }
}

.bg-secondary {
    background-color: variables.$secondary !important;

    &.bg-opacity-10 {
        background-color: rgba(variables.$secondary, 0.1) !important;
    }

    &.bg-opacity-25 {
        background-color: rgba(variables.$secondary, 0.25) !important;
    }

    &.bg-opacity-50 {
        background-color: rgba(variables.$secondary, 0.50) !important;
    }

    &.bg-opacity-75 {
        background-color: rgba(variables.$secondary, 0.75) !important;
    }

    &.bg-opacity-100 {
        background-color: rgba(variables.$secondary, 1) !important;
    }
}

.bg-info {
    background-color: variables.$info !important;

    &.bg-opacity-10 {
        background-color: rgba(variables.$info, 0.1) !important;
    }

    &.bg-opacity-25 {
        background-color: rgba(variables.$info, 0.25) !important;
    }

    &.bg-opacity-50 {
        background-color: rgba(variables.$info, 0.50) !important;
    }

    &.bg-opacity-75 {
        background-color: rgba(variables.$info, 0.75) !important;
    }

    &.bg-opacity-100 {
        background-color: rgba(variables.$info, 1) !important;
    }
}

.bg-success {
    background-color: variables.$success !important;

    &.bg-opacity-10 {
        background-color: rgba(variables.$success, 0.1) !important;
    }

    &.bg-opacity-25 {
        background-color: rgba(variables.$success, 0.25) !important;
    }

    &.bg-opacity-50 {
        background-color: rgba(variables.$success, 0.50) !important;
    }

    &.bg-opacity-75 {
        background-color: rgba(variables.$success, 0.75) !important;
    }

    &.bg-opacity-100 {
        background-color: rgba(variables.$success, 1) !important;
    }
}

.bg-warning {
    background-color: variables.$warning !important;

    &.bg-opacity-10 {
        background-color: rgba(variables.$warning, 0.1) !important;
    }

    &.bg-opacity-25 {
        background-color: rgba(variables.$warning, 0.25) !important;
    }

    &.bg-opacity-50 {
        background-color: rgba(variables.$warning, 0.50) !important;
    }

    &.bg-opacity-75 {
        background-color: rgba(variables.$warning, 0.75) !important;
    }

    &.bg-opacity-100 {
        background-color: rgba(variables.$warning, 1) !important;
    }
}

.bg-danger {
    background-color: variables.$danger !important;

    &.bg-opacity-10 {
        background-color: rgba(variables.$danger, 0.1) !important;
    }

    &.bg-opacity-25 {
        background-color: rgba(variables.$danger, 0.25) !important;
    }

    &.bg-opacity-50 {
        background-color: rgba(variables.$danger, 0.50) !important;
    }

    &.bg-opacity-75 {
        background-color: rgba(variables.$danger, 0.75) !important;
    }

    &.bg-opacity-100 {
        background-color: rgba(variables.$danger, 1) !important;
    }
}

.bg-dark {
    background-color: variables.$dark !important;

    &.bg-opacity-10 {
        background-color: rgba(variables.$dark, 0.1) !important;
    }

    &.bg-opacity-25 {
        background-color: rgba(variables.$dark, 0.25) !important;
    }

    &.bg-opacity-50 {
        background-color: rgba(variables.$dark, 0.50) !important;
    }

    &.bg-opacity-75 {
        background-color: rgba(variables.$dark, 0.75) !important;
    }

    &.bg-opacity-100 {
        background-color: rgba(variables.$dark, 1) !important;
    }
}

.bg-light {
    background-color: variables.$light !important;

    &.bg-opacity-10 {
        background-color: rgba(variables.$light, 0.1) !important;
    }

    &.bg-opacity-25 {
        background-color: rgba(variables.$light, 0.25) !important;
    }

    &.bg-opacity-50 {
        background-color: rgba(variables.$light, 0.50) !important;
    }

    &.bg-opacity-75 {
        background-color: rgba(variables.$light, 0.75) !important;
    }

    &.bg-opacity-100 {
        background-color: rgba(variables.$light, 1) !important;
    }
}

@each $color,
$value in variables.$theme-colors {
    .bg-#{$color} {
        background-color: #{$value} !important;
        border: 1px solid #{$value} !important;
        color: variables.$white;
    }
}

.bg-soft-primary {
    background-color: variables.$primary-300;
    color: variables.$primary;
}

.bg-soft-secondary {
    background-color: variables.$secondary-300;
    color: variables.$secondary;
}

.bg-soft-success {
    background-color: variables.$success-300;
    color: variables.$success;
}

.bg-soft-danger {
    background-color: variables.$danger-300;
    color: variables.$danger;
}

.bg-soft-warning {
    background-color: variables.$warning-300;
    color: variables.$warning;
}

.bg-soft-info {
    background-color: rgba(variables.$info, 0.3);
    color: variables.$info;
}

.bg-soft-light {
    background-color: rgba(variables.$light, 0.9);
    color: variables.$text-color;
}

.bg-soft-dark {
    background-color: rgba(variables.$dark, 0.3);
    color: variables.$white;
}

bg-light {
    color: variables.$text-color;
}

.bg-primary-gradient {
    background: variables.$primary-gradient !important;
    color: variables.$white;
}

.bg-secondary-gradient {
    background: variables.$secondary-gradient !important;
    color: variables.$white;
}

.bg-warning-gradient {
    background: variables.$warning-gradient !important;
    color: variables.$white;
}

.bg-info-gradient {
    background: variables.$info-gradient !important;
    color: variables.$white;
}

.bg-success-gradient {
    background: variables.$success-gradient !important;
    color: variables.$white;
}

.bg-danger-gradient {
    background: variables.$danger-gradient !important;
    color: variables.$white;
}

.bg-orange-gradient {
    background: variables.$orange-gradient !important;
    color: variables.$white;
}

.bg-purple-gradient {
    background: variables.$purple-gradient !important;
    color: variables.$white;
}

.bg-teal-gradient {
    background: variables.$teal-gradient !important;
    color: variables.$white;
}

.bg-light-gradient {
    background: variables.$light-gradient !important;
    color: variables.$black;
}

.bg-dark-gradient {
    background: variables.$dark-gradient !important;
    color: variables.$white;
}

.bg-outline-primary {
    background-color: variables.$white;
    border: 1px solid variables.$primary;
    color: variables.$primary;
}

.bg-outline-secondary {
    background-color: variables.$white;
    border: 1px solid variables.$secondary;
    color: variables.$secondary;
}

.bg-outline-warning {
    background-color: variables.$white;
    border: 1px solid variables.$warning;
    color: variables.$warning;
}

.bg-outline-info {
    background-color: variables.$white;
    border: 1px solid variables.$info;
    color: variables.$info;
}

.bg-outline-success {
    background-color: variables.$white;
    border: 1px solid variables.$success;
    color: variables.$success;
}

.bg-outline-danger {
    background-color: variables.$white;
    border: 1px solid variables.$danger;
    color: variables.$danger;
}

.bg-outline-dark {
    background-color: variables.$white;
    border: 1px solid variables.$dark;
    color: variables.$dark;
}

.bg-outline-light {
    background-color: variables.$white;
    border: 1px solid variables.$light !important;
    color: variables.$black !important;
}

.bg-primary-transparent {
    background-color: rgba(variables.$primary, 0.1) !important;
    color: variables.$primary !important;

    &:hover {
        background-color: rgba(variables.$primary, 0.1) !important;
        color: variables.$primary !important;
    }
}

.bg-secondary-transparent {
    background-color: rgba(variables.$secondary, 0.1) !important;
    color: variables.$secondary !important;

    &:hover {
        background-color: rgba(variables.$secondary, 0.1) !important;
        color: variables.$secondary !important;
    }
}

.bg-info-transparent {
    background-color: rgba(variables.$info, 0.1) !important;
    color: variables.$info !important;

    &:hover {
        background-color: rgba(variables.$info, 0.1) !important;
        color: variables.$info !important;
    }
}

.bg-success-transparent {
    background-color: rgba(variables.$success, 0.1) !important;
    color: variables.$success !important;

    &:hover {
        background-color: rgba(variables.$success, 0.1) !important;
        color: variables.$success !important;
    }
}

.bg-warning-transparent {
    background-color: rgba(variables.$warning, 0.1) !important;
    color: variables.$warning !important;

    &:hover {
        background-color: rgba(variables.$warning, 0.1) !important;
        color: variables.$warning !important;
    }
}

.bg-danger-transparent {
    background-color: rgba(variables.$danger, 0.1) !important;
    color: variables.$danger !important;

    &:hover {
        background-color: rgba(variables.$danger, 0.1) !important;
        color: variables.$danger !important;
    }
}

.bg-light-transparent {
    background-color: rgba(variables.$light, 0.5) !important;
    color: variables.$light !important;

    &:hover {
        background-color: rgba(variables.$light, 0.5) !important;
        color: variables.$text-color !important;
    }
}

.bg-gray-100 {
    background-color: variables.$gray-100;
}

.bg-gray-200 {
    background-color: variables.$gray-200;
}

.bg-gray-300 {
    background-color: variables.$gray-300;
}

.bg-gray-400 {
    background-color: variables.$gray-400;
}

.bg-gray-500 {
    background-color: variables.$gray-500;
}

.bg-gray-600 {
    background-color: variables.$gray-600;
}

.bg-gray-700 {
    background-color: variables.$gray-700;
}

.bg-gray-800 {
    background-color: variables.$gray-800;
}

.bg-gray-900 {
    background-color: variables.$gray-900;
}

.svg-primary {
    fill: variables.$primary;
}

.svg-secondary {
    fill: variables.$secondary;
}

.svg-success {
    fill: variables.$success;
}

.svg-danger {
    fill: variables.$danger;
}

.svg-warning {
    fill: variables.$warning;
}

.svg-white {
    fill: variables.$white;
}

.svg-black {
    fill: variables.$black;
}

.svg-pink {
    fill: variables.$pink;
}

.svg-orange {
    fill: variables.$orange;
}

.svg-purple {
    fill: variables.$purple;
}

.svg-indigo {
    fill: variables.$indigo;
}

.svg-info {
    fill: variables.$info;
}

.svg-yellow {
    fill: variables.$yellow;
}

.svg-light {
    fill: variables.$light;
}

.svg-dark {
    fill: variables.$dark;
}

.svg-teal {
    fill: variables.$teal;
}

.svg-default {
    fill: variables.$text-color;
}

.color-container {
    width: 5rem;
    height: 5rem;
    border-radius: variables.$border-radius;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 3rem;
}

.text-container {
    padding: 0.25rem 0.5rem;
    border-radius: variables.$border-radius;
    box-shadow: variables.$box-shadow;
}

.color-box {
    width: 80px;
    height: 80px;
    border-radius: variables.$border-radius;
    margin-right: 0.75rem;
    margin-bottom: 0.75rem;
}