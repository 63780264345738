@use "../utils/mixins";
@use "../utils/variables";

.calendar-events {
	border: 1px solid transparent;
	cursor: move;
	@include mixins.margin-padding(null, 10px 15px);
}

.calendar-events:hover {
	border-color: variables.$gray-300;
	background-color: variables.$white;
}

.calendar-events i {
	margin-right: 8px;
}

.calendar {
	float: left;
	margin-bottom: 0;
}

.fc-toolbar.fc-header-toolbar {
	margin-bottom: 1.5rem;
}

.none-border .modal-footer {
	border-top: none;
}

.fc-toolbar-title {
	color: variables.$secondary;
}

.fc .fc-button-primary {
	background-color: variables.$primary;
	border-color: variables.$primary;
}

.fc .fc-button-primary:disabled {
	background-color: variables.$primary;
	border-color: variables.$primary;
}

.fc-toolbar h2 {
	font-size: variables.$font-size-18;
	font-weight: variables.$font-weight-semibold;
	font-family: 'Nunito', sans-serif;
	line-height: 30px;
	text-transform: uppercase;
}

.fc-day-grid-event .fc-time {
	font-family: 'Nunito', sans-serif;
}

.fc-day {
	background: variables.$white;
	text-transform: uppercase;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
	z-index: 0;
}

.fc th.fc-widget-header {
	background: variables.$gray-300;
	font-size: variables.$font-size-14;
	line-height: 20px;
	padding: 10px 0;
	text-transform: uppercase;
}

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
	border-color: #f3f3f3;
}

.fc-basic-view .fc-day-number,
.fc-basic-view .fc-week-number {
	padding: 2px 5px;
}

.fc-button {
	background: #f1f1f1;
	border: none;
	color: #797979;
	text-transform: capitalize;
	box-shadow: none !important;
	border-radius: 3px !important;
	margin: 0 3px !important;
	padding: 6px 12px !important;
	height: auto !important;
}

.fc-text-arrow {
	font-family: inherit;
	font-size: variables.$font-size-16;
}

.fc-state-hover {
	background: #f3f3f3;
}

.fc-state-highlight {
	background: #f0f0f0;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
	background-color: variables.$primary !important;
	color: variables.$white !important;
	text-shadow: none !important;
}

.fc-cell-overlay {
	background: #f0f0f0;
}

.fc-unthemed .fc-today {
	background: variables.$white;
}

.fc-event {
	border-radius: 2px;
	border: none;
	color: variables.$text-color !important;
	cursor: move;
	font-size: 13px;
	margin: 1px 7px;
	padding: 5px 5px;
	text-align: center;
}

.fc-basic-view td.fc-week-number span {
	padding-right: 8px;
	font-weight: variables.$font-weight-bold;
	font-family: 'Nunito', sans-serif;
}

.fc-basic-view td.fc-day-number {
	padding-right: 8px;
	font-weight: variables.$font-weight-bold;
	font-family: 'Nunito', sans-serif;
}

.event-form .input-group .form-control {
	height: 40px;
}

.submit-section {
	text-align: center;
	margin-top: 40px;
}

.submit-btn {
	border-radius: 50px;
	font-size: variables.$font-size-18;
	font-weight: variables.$font-weight-semibold;
	min-width: 200px;
	padding: 10px 20px;
}